import { size } from "lodash";
import { useContext } from "react";

import NoDataComponent from "components/tableComponent/NoDataComponent";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import TableShimmer from "../component/TableShimmer";
import { SUBSCRIBE_TAB } from "../core/const";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";
import DataTable from "./DataTable";
import ReferralDataTable from "./ReferralDataTable";

const TableWrapper = ({
  loading,
  setLazyLoadLoading,
  lazyLoadLoading,
  listRefetch,
  loaderForActionUpdate,
  setLoaderForActionUpdate,
  viewForm,
  setViewForm,
  listings,
  loadingText,
  handleOnApplyButtonClick = () => {},
}: any) => {
  const { activeTab, commonFilter } = useContext(PersonalDetailsContext);

  if (loading) {
    return (
      <>
        <TableShimmer loading={loading} />
        <ProgressBardLoader LoadingText={loadingText} secondCounter={2} />
      </>
    );
  }

  if (activeTab === SUBSCRIBE_TAB?.subscribeDetails) {
    return size(listings) > 0 ? (
      <DataTable
        setLazyLoadLoading={setLazyLoadLoading}
        lazyLoadLoading={lazyLoadLoading}
        loading={loading}
        listRefetch={listRefetch}
        loaderForActionUpdate={loaderForActionUpdate}
        setLoaderForActionUpdate={setLoaderForActionUpdate}
        viewForm={viewForm}
        setViewForm={setViewForm}
        copyFilterData={commonFilter?.subscriberFilter}
        handleOnApplyButtonClick={handleOnApplyButtonClick}
      />
    ) : (
      <NoDataComponent />
    );
  }

  if (activeTab === SUBSCRIBE_TAB?.referral) {
    return size(listings) > 0 ? (
      <ReferralDataTable
        setLazyLoadLoading={setLazyLoadLoading}
        lazyLoadLoading={lazyLoadLoading}
        loading={loading}
        listRefetch={listRefetch}
        loaderForActionUpdate={loaderForActionUpdate}
        setLoaderForActionUpdate={setLoaderForActionUpdate}
        viewForm={viewForm}
        setViewForm={setViewForm}
        copyFilterData={commonFilter?.referralFilter}
        handleOnApplyButtonClick={handleOnApplyButtonClick}
      />
    ) : (
      <NoDataComponent />
    );
  }

  return null;
};

export default TableWrapper;
