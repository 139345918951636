const NoData = ({ border }: any) => {
  return (
    <div
      className={`text-sm13 font-medium text-center p-2.5 bg-white rounded w-full ${border}`}
    >
      No data available
    </div>
  );
};

export default NoData;
