import EDITSVG from "assets/media/icons/other_icons/edit-square.svg";
import { LayoutContext } from "components/core/LayoutProvider";
import NoDataComponent from "components/tableComponent/NoDataComponent";
import { IKTSVG } from "components/ui/IKTSVG";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { isMobile, QUERIES, SYMBOLS } from "helpers/const";
import { addCommaInNumber, errorReflect } from "helpers/Functions";
import { showAlert } from "helpers/ShowAlert";
import { size } from "lodash";
import moment from "moment";
import { SUBSCRIBE_ROUTE } from "pages/subscribe/personal_details/core/const";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import LeftSVG from "../../../../assets/media/icons/standard_icons/angle_left.svg";
import RightSVG from "../../../../assets/media/icons/standard_icons/angle_right.svg";
import {
  sellerLevelMovementAction,
  sellerLevelMovementList,
  sellerLevelOptionsWithConfig,
} from "../core/request";
import { SellerLevelsProviderContex } from "../core/SellerLevelsProvider";
import MovementRowAction from "../table/component/MovementRowAction";
import DynamicDataTable from "../table/DynamicDataTable";
import MovementConfirmationPopup from "../widgets/MovementConfirmationPopup";
import FilterChips from "./FilterChips";
import HeaderFilter from "./HeaderFilter";
import MovementFilters from "./MovementFilters";
import SellerLevelHeader from "./SellerLevelHeader";

const getColumns: any = ({
  handOnDeleteLevel,
  handleAction,
  handleEditClick,
}: any) => [
  {
    title: "Team",
    width: "w-[11.5rem] min-w-[11.5rem] max-w-[11.5rem]",
    isArrow: true,
    postKey: "name",
    padding: "pl-5  p-1.5",
    classname: "w-[9.875rem] ellips-text",
    getValue: ({ list }: any) => list?.name,
  },
  {
    title: "GTV",
    width: "w-[6.25rem] min-w-[6.25rem] max-w-[6.25rem]",
    isArrow: false,
    postKey: "gtv",
    padding: "p-1.5",
    getValue: ({ list }: any) =>
      `${SYMBOLS[list.currency]}${addCommaInNumber(list?.gtv)}`,
  },
  {
    title: "Current Level",
    width: "w-[6.875rem] min-w-[6.875rem] max-w-[6.875rem]",
    isArrow: true,
    postKey: "title",
    padding: " p-1.5",
    getValue: ({ list }: any) => list?.seller_level?.title,
  },
  {
    title: "Proposed Level",
    width: "w-[6.25rem] min-w-[6.25rem]  w-auto",
    isArrow: false,
    postKey: "seller_level",
    padding: " p-1.5",
    getValue: ({ list }: any) => list?.proposed_level?.title,
  },
  {
    getTitle: ({ scroll }: any) => {
      if (isMobile === true) {
        return <></>;
      } else {
        return (
          <div
            className={`py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white`}
            id="left-shad-list"
          >
            <div className=" ">
              <button
                className="group rounded-l  hover: transition px-[.5625rem] py-2"
                id="leftArrow"
                type="button"
                onClick={() => scroll(-120)}
              >
                <IKTSVG
                  className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                  path={LeftSVG}
                  svgClassName="w-[.375rem] h-[.5625rem]"
                />
              </button>
              <button
                className="group rounded-r  transition px-[.5625rem] py-2"
                id="rightArrow"
                type="button"
                onClick={() => scroll(120)}
              >
                <IKTSVG
                  className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                  path={RightSVG}
                  svgClassName="w-[.375rem] h-[.5625rem]"
                />
              </button>
            </div>
          </div>
        );
      }
    },
    width:
      "md:sticky relative right-0 ml-auto  !p-0 md:w-[7.5rem] min-h-[2.8125rem] h-[2.8125rem] md:w-[10.5625rem] md:max-w-[10.5625rem] md:min-w-[10.5625rem] w-[6.5625rem] max-w-[6.5625rem] min-w-[6.5625rem] icon_td",
    isArrow: false,
    postKey: "actions",
    renderDirect: true,
    getValue: ({ list, sIndex, matchedView }: any) => (
      <MovementRowAction
        list={list}
        sIndex={sIndex}
        matchedView={matchedView}
        handOnDeleteLevel={handOnDeleteLevel}
        handleAction={handleAction}
        handleEditClick={handleEditClick}
      />
    ),
  },
  // isMobile && {
  //   title: (
  //     <>
  //       {/* {globalLayout?.isMobile && (
  //           <th className="sticky right-0 ml-auto  p-0 w-10 min-w-[2.5rem]">
  //             <div
  //               className="py-[.4375rem] px-4 flex justify-end h-[2.5rem]  bg-white max-md:pointer-events-none"
  //               id=""
  //             ></div>
  //           </th>
  //         )} */}
  //     </>
  //   ),
  //   width:
  //     "sticky relative right-0 ml-auto z-[999999999]  !p-0 md:w-[7.5rem] md:max-w-[7.5rem] md:min-w-[7.5rem] w-[9.375rem] min-w-[9.375rem] icon_td",
  //   isArrow: false,
  //   postKey: "actions",
  //   renderDirect: true,
  //   getValue: ({ list, sIndex, matchedView }: any) => <>Hello</>,
  // },
  isMobile && {
    getTitle: ({ scroll }: any) => {
      return (
        <div
          className={`py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white max-md:hidden`}
          id="left-shad-list"
        >
          <div className="arrow_buttons rounded ">
            <button
              className="group rounded-l  hover: transition px-[.5625rem] py-2"
              id="leftArrow"
              type="button"
              onClick={() => scroll(-120)}
            >
              <IKTSVG
                className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                path={LeftSVG}
                svgClassName="w-[.375rem] h-[.5625rem]"
              />
            </button>
            <button
              className="group rounded-r  transition px-[.5625rem] py-2"
              id="rightArrow"
              type="button"
              onClick={() => scroll(120)}
            >
              <IKTSVG
                className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                path={RightSVG}
                svgClassName="w-[.375rem] h-[.5625rem]"
              />
            </button>
          </div>
        </div>
      );
    },
    width:
      "sticky right-0 ml-auto p-0 icon_td max-md:min-w-[6.25rem] md:hidden ",
    isArrow: false,
    postKey: "actions",
    renderDirect: true,
    getValue: ({ list, sIndex, matchedView }: any) => (
      <>
        <div className="-ml-px border-l w-full">
          <button
            className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
            type="button"
            id={`record-view-tooltip${sIndex}`}
            data-bs-toggle="offcanvas"
            data-bs-target="#level_confi_popup"
            aria-controls="level_confi_popup_label"
            onClick={() => {
              handleEditClick(list);
            }}
          >
            <Tooltip
              anchorId={`record-view-tooltip${sIndex}`}
              content={`${"Edit"}`}
              place={`${sIndex === 0 ? "left" : "top"}`}
              variant="light"
              className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                sIndex === 0 ? "mt-0" : "mt-1.5"
              }`}
            />
            <IKTSVG
              className={`flex items-center justify-center fill-white group-hover:fill-white transition`}
              svgClassName="w-4 h-[.6875rem]"
              path={EDITSVG}
            />
          </button>
        </div>
      </>
    ),
  },
];

const MovementTab = () => {
  const layout = useContext(SellerLevelsProviderContex);
  const filters = layout.sellerLevelMovementFilters;
  const copyFilters = layout.commonFilter;
  const tableData: any = layout.sellerLevelMovementData?.tableData || [];
  const [deleteConfirmation, setDeleteConfirmation] = useState<any>(false);
  const [customLoading, setCustomLoading] = useState(true);
  const [filterchipData, setFilterchipData] = useState<Array<any>>([{}]);
  const [actionLoader, setActionLoader] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState<{
    state: boolean;
    data: any;
  }>({
    state: false,
    data: {},
  });
  const globalLayout = useContext(LayoutContext);
  const navigate = useNavigate();

  const MovementTableData = layout.sellerLevelMovementData?.tableData;

  // GET LISTINGS
  const { isLoading: loading, refetch: refetchLevelAPi } = useQuery(
    [QUERIES.SELLER_LEVEL_MOVEMENT_LIST, copyFilters],
    () => sellerLevelMovementList({ filters: copyFilters }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      // enabled: true,
      onSettled(data, error) {
        if (copyFilters?.page === 1) {
          const newData = {
            tableData: data?.data || [],
            metaData: data?.meta,
          };

          layout.setSellerLevelMovementData(newData);
        } else {
          const newData = {
            tableData: [...tableData, ...data?.data],
            metaData: data?.meta,
          };
          layout.setSellerLevelMovementData(newData);
        }
        setCustomLoading(false);
      },
    }
  );

  // GET options
  const { data: levelConfigData } = useQuery(
    [QUERIES.SELLER_LEVEL_OPTION],
    () => sellerLevelOptionsWithConfig(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
    }
  );

  const handOnDeleteLevel = async (data: any) => {
    setDeleteConfirmation({
      status: true,
      id: data?.id,
    });
  };

  useEffect(() => {
    if (loading) {
      setCustomLoading(true);
    }
    setConfirmationPopup({
      data: {},
      state: false,
    });
    setFilterchipData(() => {
      return [
        copyFilters.dateRange && {
          filterName: "dateRange",
          filterValue: `${moment(copyFilters?.dateRange?.startDate).format(
            "DD/MM/yy"
          )} - ${moment(copyFilters?.dateRange?.endDate).format("DD/MM/yy")}`,
          onRemove: (data: any) => {
            layout?.setSellerLevelMovementFilters((prev: any) => {
              delete prev?.dateRange;
              handleOnApplyButtonClick("remove", prev);
              return {
                ...prev,
              };
            });
          },
        },
        ...(size(copyFilters?.team_id) > 0
          ? copyFilters?.team_id?.map((team: any) => ({
              filterName: team?.id,
              filterValue: team?.name,
              id: team?.id,
              onRemove: (data: any) => {
                layout?.setSellerLevelMovementFilters((prev: any) => {
                  const updateData = {
                    ...prev,
                    team_id: prev?.team_id?.filter((team: any) => {
                      return team?.id != data?.id;
                    }),
                  };

                  handleOnApplyButtonClick("remove", updateData);

                  return updateData;
                });
              },
            }))
          : []),
        copyFilters?.is_upgrade && {
          filterName: copyFilters?.is_upgrade?.name,
          filterValue: copyFilters?.is_upgrade?.name,
          onRemove: (data: any) => {
            layout.setSellerLevelMovementFilters((prev: any) => {
              delete prev?.is_upgrade;
              handleOnApplyButtonClick("remove", prev);
              return {
                ...prev,
              };
            });
          },
        },
      ].filter(Boolean);
    });
  }, [
    copyFilters.order_by,
    copyFilters.sort_order,
    copyFilters.dateRange,
    copyFilters?.team_id,
    copyFilters?.is_upgrade,
  ]);
  const actionApi = (list: any, persist_custom_seller_level_config: any) => {
    setActionLoader(true);
    sellerLevelMovementAction({
      team_id: list?.id,
      seller_level_id: list?.proposed_level?.id,
      persist_custom_seller_level_config: persist_custom_seller_level_config,
    })
      .then((res) => {
        if (res?.data?.status) {
          layout?.setSellerLevelMovementData((pre: any) => {
            return {
              ...pre,
              tableData: pre?.tableData?.map((item: any) => {
                if (item?.id === list?.id) {
                  return {
                    ...item,
                    seller_level: item?.proposed_level,
                  };
                }
                return item;
              }),
            };
          });
          showAlert(res?.message, false);
          setConfirmationPopup({ state: false, data: {} });
        } else {
          showAlert(errorReflect(res), true);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setActionLoader(false);
      });
  };

  const handleAction = ({ list }: any) => {
    // if (size(list?.team_config) > 0) {
    //   setConfirmationPopup({
    //     state: true,
    //     data: list,
    //   });
    // } else {
    //   actionApi(list, 0);
    // }
    setConfirmationPopup({
      state: true,
      data: list,
    });
  };

  const newdata1 = layout?.sellerLevelMovementData?.tableData?.filter(
    (item: any) => item?.team_config?.length > 0
  );

  const handleEditClick = (list: any) => {
    globalLayout?.setSellerLevelConfigEdit({
      teams: [list],
    });
    navigate(`/subscribe${SUBSCRIBE_ROUTE.subscribeDetails}`);
  };

  const handleOnApplyButtonClick = (action: any, filterData: any) => {
    if (action !== "lazy") {
      setCustomLoading(true);
    }

    layout.setCommonFilter((pre: any) => {
      return filterData;
    });
  };

  return (
    <div>
      <SellerLevelHeader
        headerFilters={HeaderFilter}
        filters={
          <MovementFilters
            loading={customLoading}
            handleOnApplyButtonClick={handleOnApplyButtonClick}
            filterchipData={filterchipData}
          />
        }
        filterChips={
          <FilterChips
            loading={customLoading}
            filterchipData={filterchipData}
            filtersData={layout?.sellerLevelMovementFilters}
            setFiltersData={layout?.setSellerLevelMovementFilters}
            paginateData={layout.sellerLevelMovementData?.metaData}
            totalName={"teams"}
            totalRecords={MovementTableData?.length}
            handleOnApplyButtonClick={handleOnApplyButtonClick}
          />
        }
      />

      <div>
        {customLoading ? (
          Array.from({ length: 3 }, (v, i) => (
            <div
              className={`accordion font-medium  mb-2.5 mx-5 ${
                i === 0 && "mt-0"
              } `}
            >
              <div
                key={i}
                className={`accordion-item bg-white !rounded overflow-hidden ${
                  loading && "shimmer-effect"
                } `}
                style={{ height: "40px" }}
              ></div>
            </div>
          ))
        ) : (
          <DynamicDataTable
            refetch={refetchLevelAPi}
            columns={getColumns({
              handOnDeleteLevel,
              handleAction,
              handleEditClick,
            }).filter(Boolean)}
            data={tableData}
            paginateData={layout.sellerLevelMovementData?.metaData}
            filters={layout?.sellerLevelMovementFilters}
            setFilters={layout?.setSellerLevelMovementFilters}
            activeId={confirmationPopup?.data?.id}
            refreshArrow={confirmationPopup?.state}
            copyFilters={layout.commonFilter}
            handleOnApplyButtonClick={handleOnApplyButtonClick}
            Popup={
              confirmationPopup?.state && (
                <MovementConfirmationPopup
                  setConfirmationPopup={setConfirmationPopup}
                  confirmationPopup={confirmationPopup}
                  actionApi={actionApi}
                  levelConfigData={levelConfigData}
                  setActionLoader={setActionLoader}
                />
              )
            }
          />
        )}

        {actionLoader && <ProgressBardLoader secondCounter={2} />}

        {!loading && tableData?.length === 0 ? <NoDataComponent /> : null}
      </div>
    </div>
  );
};

export default MovementTab;
