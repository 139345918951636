import { EditSquareIcon } from "assets/media/icons/other_icons/EditSquareIcon";
import { DataGrid } from "components/DataGrid/DataGrid";
import { size } from "lodash";
import { getMenuRecords } from "pages/customize/core/_.request";
import { useCustomize } from "pages/customize/hooks/useCustomize";
import { Fragment, useEffect } from "react";
import { useQuery } from "react-query";
import { Tooltip } from "react-tooltip";

const getColumns: any = (setIsRecordEdit: any) => [
  {
    title: "Title",
    width: "11.25rem",
    getValue: (list: any) => list?.title,
    arrow: true,
  },
  {
    title: "Position",
    width: "7.5rem",
    getValue: (list: any) => list?.position,
  },
  {
    title: "Status",
    width: "auto",
    getValue: (list: any) => (list?.status ? "active" : "disable"),
  },

  {
    title: "Action",
    width: "5rem",
    isTootTip: false,
    isSticky: true,
    getValue: (list: any, eIndex: any) => (
      <div>
        <button
          className="inline-flex group items-center text-violet-800"
          onClick={() => {
            setIsRecordEdit(list);
            let addEditRecord = document.getElementById("addEditRecord");
            addEditRecord && addEditRecord.click();
          }}
          id={`view-tx-pay-${eIndex}`}
        >
          <div className="min-w-4 max-w-4 flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500  transition">
            <EditSquareIcon width="14" height="14" />
            <Tooltip
              anchorId={`view-tx-pay-${eIndex}`}
              content={`Edit`}
              place="top"
              variant="light"
              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
            />
          </div>
        </button>
      </div>
    ),
  },
];

export const DesignMenu = () => {
  const { setIsRecordEdit, records, setRecords, recordFilter, setIsLoading } =
    useCustomize();

  const { isLoading } = useQuery(
    [`tx-trade-menu-customize`, recordFilter],
    getMenuRecords(recordFilter),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,

      onSettled: (res: any) => {
        if (res?.data?.menu && size(res?.data?.menu) > 0) {
          setRecords(res.data.menu);
        } else {
          setRecords([]);
        }
      },
    }
  );

  useEffect(() => {
    setIsLoading(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <Fragment>
      {isLoading ? (
        <>
          {Array.from({ length: 3 }, (v, i) => (
            <div
              className={`accordion font-medium  mb-2.5 mx-5 ${
                i === 0 && "mt-5"
              } `}
              key={i}
            >
              <div
                key={i}
                className={`accordion-item bg-white !rounded overflow-hidden ${
                  isLoading && "shimmer-effect"
                } `}
                style={{ height: "40px" }}
              ></div>
            </div>
          ))}
        </>
      ) : !isLoading && size(records) > 0 ? (
        <DataGrid data={records ?? []} columns={getColumns(setIsRecordEdit)} />
      ) : (
        <div className="text-sm13 font-medium text-center p-2.5 bg-white rounded ">
          No data available
        </div>
      )}
    </Fragment>
  );
};
