import { VENUE_DETAILS_INITIAL_FILTER } from "helpers/const";
import { createContext, PropsWithChildren, useState } from "react";

export interface LayoutContextModel {
  viewForm: any;
  setViewForm: (data: any) => void;

  venueFilter: any;
  setVenueFilter: (data: any) => void;

  venueListing: any;
  setVenueListing: (data: any) => void;
}

const VenueDetailsContext = createContext<LayoutContextModel>({
  viewForm: {},
  setViewForm: (data: any) => {},

  venueFilter: {},
  setVenueFilter: (data: any) => {},

  venueListing: [],
  setVenueListing: (data: any) => {},
});

const VenueDetailsProvider = ({ children }: PropsWithChildren) => {
  const [viewForm, setViewForm] = useState<any>({
    data: undefined,
    status: false,
  });
  const [venueFilter, setVenueFilter] = useState<any>(
    VENUE_DETAILS_INITIAL_FILTER
  );
  const [venueListing, setVenueListing] = useState<any>([]);

  const value: LayoutContextModel = {
    viewForm,
    setViewForm,
    venueFilter,
    setVenueFilter,
    venueListing,
    setVenueListing,
  };

  return (
    <VenueDetailsContext.Provider value={value}>
      {children}
    </VenueDetailsContext.Provider>
  );
};

export { VenueDetailsContext, VenueDetailsProvider };
