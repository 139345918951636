import { LayoutContext } from "components/core/LayoutProvider";
import ListingActionButton from "components/tableComponent/ListingActionButton";
import ListingShimmer from "components/tableComponent/ListingShimmer";
import {
  Table,
  TableFoot,
  TableHead,
  TableRow,
} from "components/tableComponent/virtuosoComponent/TableComponents";
import { endReachedVirtuoso, handleScroll } from "helpers/Functions";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { TableVirtuoso } from "react-virtuoso";
import ViewForm from "../components/ViewForm";
import { VenueDetailsContext } from "../core/VenueDetailsProvider";
import TableHeaderRow from "./components/TableHeaderRow";
import VenueItemContent from "./components/VenueItemContent";

const DataTable = ({ list, loading, paginateData }: any) => {
  const data = list;
  const stickyTableRef: any = useRef();
  const virtuosoRef: any = useRef();
  const tableRef: any = useRef();
  const [height, setHeight] = useState<any | undefined>(undefined);
  const [virtHeight, setVirtHeight] = useState<any>();
  const globalLayout = useContext(LayoutContext);
  const { venueFilter, viewForm, setViewForm, setVenueFilter } =
    useContext(VenueDetailsContext);

  /*On scroll sticky head list scroll virtuoso*/
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };

  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };

  let timeout: any;
  const nomoreListing = document.getElementById("NoMoreListing");

  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#sales-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight + 1;
          if (nomoreListingHeight) {
            elemHeight =
              divElement.offsetHeight + theadHeight + nomoreListingHeight + 1;
          }

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 500);
  };

  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      const divElement: any = document.querySelector("#sales-lazyload");
      const elemHeight = divElement?.offsetHeight;
      setVirtHeight(elemHeight);
    }, 300);
  }, [data, loading, venueFilter]);

  // RE-RENDER HEIGHT ON RESIZING RESOLUTION
  useEffect(() => {
    renderCall();
  }, [
    globalLayout?.htmlFontSize,
    data?.length === paginateData?.total && paginateData?.current_page > 1,
    nomoreListing,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (globalLayout?.htmlFontSize) {
        renderCall();
      }
    }, 1000);
  }, [globalLayout?.htmlFontSize]);

  useEffect(() => {
    const handleScrollTable = () => {
      const EventAccordion = document.getElementById(
        "EventAccordion"
      ) as HTMLElement;

      const topfilterTop: any = document.getElementById("topFilters");
      const HeaderHeight: any = document.querySelector("header")?.clientHeight;
      const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
      // if (topValue.includes("-")) {
      // Check if the value contains a negative sign
      const currentValue = parseInt(topValue); // Extract the numeric value
      const newValue = Math.abs(currentValue); // Make it positive

      if (topfilterTop?.style) {
        topfilterTop.style.top = `${newValue}px`; // Apply the new positive value back as a string
      }
      // }
      const windScroll: number = window.scrollY;

      // const headingOne: any =
      //   document.getElementById("headingOne")?.clientHeight;
      // const inventoryaccordion: any =
      //   document.getElementById("inventoryaccordion")?.clientHeight;

      const salesFilter =
        document.getElementById("report_filter")?.offsetHeight || 0;
      const topfilterTopHeight: any =
        document.getElementById("topFilters")?.offsetHeight || 0;
      // const totleScroll = headingOne + inventoryaccordion + 16;
      // const currentTop = parseInt(topfilterTop.style.top || "0");
      let totleScroll = 0;
      if (window.matchMedia("(max-width: 767px)").matches) {
        totleScroll = HeaderHeight + salesFilter;
      } else {
        totleScroll = salesFilter + 20;
      }

      const stickyHead: any = document.getElementById("sticky-head");
      //code to adjust sticky header based on filter chip Area scrollbar
      const filterChipArea = document.querySelector(
        ".filterChipArea"
      ) as HTMLElement;
      let filterChipAreaWidth = 0;
      let width = 0;

      function getBodyScrollbarWidth() {
        const filterChipArea = document.querySelector(
          ".filterChipArea"
        ) as HTMLElement;
        // Creating a div element
        const scrollDiv = document.createElement("div");
        scrollDiv.style.width = "100px";
        scrollDiv.style.height = "100px";
        scrollDiv.style.overflow = "scroll";
        scrollDiv.style.position = "absolute";
        scrollDiv.style.top = "-9999px"; // Hide the div off-screen

        // Append the div to the document body
        filterChipArea?.appendChild(scrollDiv);
        // Calculate the body scrollbar width
        const scrollbarWidth =
          filterChipArea?.offsetHeight - filterChipArea?.clientHeight;
        // Remove the div from the document
        filterChipArea?.removeChild(scrollDiv);
        return scrollbarWidth;
      }
      const bodyScrollbarWidth = getBodyScrollbarWidth();

      if (
        filterChipArea &&
        filterChipArea.children.length > 0 &&
        bodyScrollbarWidth !== 0
      ) {
        width = filterChipArea.scrollWidth;
        filterChipAreaWidth = filterChipArea.offsetWidth;
      }

      if (width > filterChipAreaWidth && width != 0) {
        // stickyHead?.classList.remove("top-[14.625rem]");
        stickyHead?.classList.add("sticky-header-scroll");
        // stickyHead?.classList.remove("xl:top-[12.375rem]");
        // stickyHead?.classList.remove("xl:top-[12.625rem]");
      }

      const filters_block =
        document.getElementById("filters_block")?.offsetHeight || 0;
      // const salesFilter =
      //   document.getElementById("salesFilter")?.offsetHeight || 0;

      const topPosition = filters_block + salesFilter + HeaderHeight;
      const stickyShadow: any = document.getElementById("sticky-shadow");

      if (windScroll > totleScroll) {
        EventAccordion?.classList?.add("-mt-10");
        stickyHead?.classList.remove("opacity-0");
        stickyHead?.classList.remove("h-0");
        stickyHead?.classList.add("h-[2.5625rem]");
        stickyShadow.classList.add("!block");
      } else if (windScroll < totleScroll) {
        EventAccordion?.classList?.remove("-mt-10");
        stickyHead?.classList.add("opacity-0");
        stickyHead?.classList.add("h-0");
        stickyHead?.classList.remove("h-[2.5625rem]");
        stickyShadow.classList.remove("!block");
      }
    };
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const divElement: any = document.querySelector("#tracklisting-lazyload");
      const tHeadHeight: any = document.querySelector(
        "#tracklisting-lazyload thead"
      )?.clientHeight;
      if (divElement) {
        const elemHeight = divElement.offsetHeight + tHeadHeight + 1;
        setHeight(`${elemHeight}`);
      }
    }, 500);
  }, [data]);

  // auto disabled left right table button
  useEffect(() => {
    const element = document.getElementById("tableId1");
    const stickyHead = document.getElementById("sticky-head");

    const scrollHandler = () => handleScroll(element, stickyHead);
    if (element) {
      element.addEventListener("scroll", scrollHandler);
      scrollHandler(); // Initial check
      return () => {
        element.removeEventListener("scroll", scrollHandler);
      };
    }
  }, []);

  return (
    <div
      className="accordion mb-4 font-medium transition-all relative md:px-5 max-lg:!mr-0 referral-data-table"
      id={`EventAccordion`}
    >
      <div
        id="sticky-head"
        className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none top-[3.75rem] md:top-[12.3438rem]`}
        ref={stickyTableRef}
        onScroll={handleScrollHead}
      >
        <table className="w-full text-xs text-left  min-w-[87.5rem] absolute left-0 top-0 last-auto">
          <thead className="text-xs text-gray-400 ">
            <TableHeaderRow />
          </thead>
        </table>
      </div>
      <span
        id="sticky-shadow"
        className="top-[6.25rem] md:top-[14.875rem] z-10 hidden"
      ></span>
      <div className="accordion-item bg-white  !rounded overflow-hidden">
        <div
          id="EventCollapseBody"
          className="accordion-collapse collapse show"
          aria-labelledby="EventCollapseBody"
          data-te-collapse-show
        >
          <div className="accordion-body relative">
            <div
              className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2"
              ref={tableRef}
              id={`tableId1`}
              onScroll={handleScrollVirtuoso}
              style={{ height: height > 41 ? `${height}px` : "auto" }}
            >
              <TableVirtuoso
                data={data}
                useWindowScroll
                overscan={200}
                totalCount={data?.length}
                atBottomThreshold={100}
                id={"sales-lazyload"}
                endReached={() =>
                  endReachedVirtuoso(paginateData, setVenueFilter)
                }
                components={{
                  Table,
                  TableHead,
                  TableRow,
                  TableFoot: (props) => (
                    <TableFoot
                      {...props}
                      data={data}
                      paginateData={paginateData}
                    />
                  ),
                }}
                fixedFooterContent={() => {
                  return (
                    <tr className="border-b bg-white">
                      <td colSpan={0}>Hi</td>
                    </tr>
                  );
                }}
                fixedHeaderContent={TableHeaderRow}
                itemContent={(sIndex: any, list: any) => {
                  return (
                    <React.Fragment key={sIndex}>
                      {/* LISTINGS */}
                      <VenueItemContent list={list} />

                      {/* ACTION BUTTON */}
                      <ListingActionButton
                        matchedView={false}
                        list={list}
                        sIndex={sIndex}
                        disabled={false}
                        buttonType="edit"
                        handleViewForm={(formList: any) => {
                          setViewForm({
                            data: formList,
                            status: true,
                          });
                          document.body.style.overflow = "hidden";
                        }}
                      />
                    </React.Fragment>
                  );
                }}
                ref={virtuosoRef}
              />
            </div>
          </div>

          <ListingShimmer paginateData={paginateData} />
        </div>
      </div>

      {/* VIEW FORM */}
      {viewForm?.status && <ViewForm />}
    </div>
  );
};

export default DataTable;
