import { generatePreassignedURL } from "components/core/requests";
import Cookies from "js-cookie";
import _ from "lodash";
import { algoliaSearchForsWeightage } from "pages/inventory/core/_request";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { S3_CONFIG } from "./const";

export const LoadImages = (pathname: string) => {
  const [images, setImages] = useState<string>("");
  useEffect(() => {
    import(`../assets/media/icons/${pathname}`).then((image: any) => {
      setImages(image.default);
    });
  }, [pathname]);

  if (images && images !== undefined) {
    return images;
  }
};

export const base64TOURL = (file: any, fileType = "application/pdf") => {
  const bytes = new Uint8Array(
    atob(file)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
  const blob = new Blob([bytes], { type: fileType });
  return window.URL.createObjectURL(blob);
};

export const base64ToPDF = (file: any, fileName: string) => {
  const bytes = new Uint8Array(
    atob(file)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
  const blob = new Blob([bytes], { type: "application/pdf" });
  const a = document.createElement("a");
  a.download = fileName; // Set the file name here
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const getCookies = (data: any) => {
  let cookieData = Cookies.get(data) as any;
  if (cookieData) {
    cookieData = JSON.parse(cookieData);
  }
  return cookieData;
};

export const percentage = (partialValue: any, totalValue: any) => {
  return ((100 * partialValue) / totalValue).toFixed();
};

export const fnBrowserDetect = () => {
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  return browserName;
};
export function createObjectArrayWithKeys(values: any, skipKeys: any) {
  function formatEventDate(inputString: any) {
    let stringWithSpaces = inputString.replace(/_/g, " ");
    if (
      inputString.toLowerCase().includes("swift/bic") ||
      inputString.toLowerCase().includes("iban")
    ) {
      return stringWithSpaces.toUpperCase();
    }

    return stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
  }

  const result = Object.entries(values)
    ?.map((item) => {
      const key = item[0];
      if (skipKeys.includes(key)) {
        return null; // Skip the object with the specified key
      }

      // let value: any = item[1];
      // if (value instanceof Date) {
      //   value = moment(value).format("DD/MM/YYYY");
      // } else {
      //   value = item[1]; // Convert non-date values to strings
      // }

      return {
        key: item[0],
        label: formatEventDate(item[0]),
        value: item[1],
      };
    })
    .filter((item) => item !== null);

  return result;
}

// SCROLL TO TOP IF ERROR OCCURs
export const scrollToError = (formik: any) => {
  const inputFields: any = document.querySelectorAll(".form");
  const firstErrorField = [...inputFields].find((inputItem) => {
    return formik.errors.hasOwnProperty(
      inputItem.name ? inputItem.name : inputItem?.getAttribute("data-name")
    );
  });
  if (firstErrorField) {
    firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
  }
};

export const splitTypeOptions = (data: any) => {
  return data?.map((item: any) => ({
    ...item,
    name:
      item?.id === "ANY"
        ? "None"
        : item?.id === "NOSINGLES"
        ? "Avoid Leaving One"
        : item.name.charAt(0).toUpperCase() + item.name.slice(1).toLowerCase(),
  }));
};

// Memoization helper function
// export const memoize = (fn: any) => {
//   const cache = new Map();
//   return (...args: any) => {
//     const key = JSON.stringify(args);
//     if (cache?.has(key)) {
//       return cache?.get(key);
//     }
//     const result = fn(...args);
//     cache.set(key, result);
//     return result;
//   };
// };

// Function to get the ticket type based on eventRecord and layout
export const getTicketType = (eventRecord: any, layout: any) => {
  const { ticket_type, sub_ticket_type } = eventRecord || {};
  const { ticketType } = layout;

  if (typeof ticket_type === "object") {
    if (ticket_type === "eticket") {
      if (sub_ticket_type === "pdf") {
        return { id: "pdf", name: "PDF E-Ticket" };
      } else if (sub_ticket_type === "mobile-qr") {
        return { id: "mobile-qr", name: "Mobile QR" };
      }
    } else {
      return ticket_type;
    }
    return null;
  } else if (Array.isArray(ticketType)) {
    let subTicket = !sub_ticket_type?.trim() ? "pdf" : sub_ticket_type;

    let value: any = ticketType.find((ticket) => {
      return ticket_type === "eticket"
        ? ticket.id === subTicket
        : ticket.id === ticket_type;
    });
    return value;
  }

  return null;
};

// Function to get the category value based on eventRecord and event
export const getCategoryValue = (eventRecord: any, event: any) => {
  const categoryId = eventRecord?.category?.id;
  const recordCategoryId = eventRecord?.category;

  if (categoryId) {
    return eventRecord?.category;
  } else if (event?.categories) {
    const foundCategory = event.categories.find(
      (category: any) => Number(category.id) === Number(recordCategoryId)
    );
    return foundCategory || null;
  }

  return null;
};

// Function to get the section value based on eventRecord and event
export const getSectionValue = (eventRecord: any, event: any) => {
  const sectionId = eventRecord?.section?.id;
  const recordSectionId = eventRecord?.section;
  const recordCategoryId = eventRecord?.category;

  if (sectionId) {
    return eventRecord?.section;
  } else if (event?.categories && Array.isArray(event.categories)) {
    const foundCategory = event.categories.find(
      (category: any) => Number(category.id) === Number(recordCategoryId)
    );

    if (foundCategory && foundCategory.sections) {
      const foundSection = foundCategory.sections.find(
        (section: any) => Number(section?.id) === Number(recordSectionId)
      );
      return foundSection || null;
    }
  }

  return null;
};

// Function to get benefits value based on eventRecord and layout
export const getBenefitsValue = (eventRecord: any, layout: any) => {
  const benefitsId = eventRecord?.benefits?.[0]?.id;

  if (benefitsId) {
    return eventRecord?.benefits;
  } else if (layout?.benefits) {
    return layout.benefits.filter((benefit: any) =>
      eventRecord?.benefits?.includes(benefit.id)
    );
  }

  return null;
};

// Function to get restriction value based on eventRecord and layout
export const getRestrictionValue = (eventRecord: any, layout: any) => {
  const restrictionsId = eventRecord?.restrictions?.[0]?.id;

  if (restrictionsId) {
    return eventRecord?.restrictions;
  } else if (layout?.restrictions) {
    return layout.restrictions.filter((restriction: any) =>
      eventRecord?.restrictions?.includes(restriction.id)
    );
  }

  return null;
};

export const extractOptions = (inputArray: any) =>
  inputArray &&
  inputArray
    .map((obj: any) => obj.options)
    .filter((options: any) => options)
    .flat();

// export const outSideClick = (e: any, bulkActionRef: any) => {
//   if (!bulkActionRef?.current?.contains(e.target)) {
//     let bulkAction: any = document.querySelectorAll(
//       'span[data-te-target="#bulkaction"]:not([data-te-collapse-collapsed])'
//     )?.[0];
//     if (bulkAction) {
//       bulkAction.click();
//     }
//   }
// };

// export const columnsOutSideClick = (e: any, bulkAction1Ref: any) => {
//   if (!bulkAction1Ref?.current?.contains(e.target)) {
//     let bulkAction: any = document.querySelectorAll(
//       'span[data-te-target="#bulkaction1"]:not([data-te-collapse-collapsed])'
//     )?.[0];
//     if (bulkAction) {
//       bulkAction.click();
//     }
//   }
// };
export const outSideClick = (e: any, dropdownId: any) => {
  let filterDiv = document.getElementById(dropdownId);
  if (!filterDiv?.contains(e.target)) {
    let bulkAction: any = document.querySelectorAll(
      `span[data-te-target="#${dropdownId}"]:not([data-te-collapse-collapsed])`
    )?.[0];
    if (bulkAction) {
      bulkAction.click();
    }
  }
};

export const columnsOutSideClick = (e: any, dropdownId: any) => {
  let columnDiv = document.getElementById(dropdownId);
  if (!columnDiv?.contains(e.target)) {
    let bulkAction: any = document.querySelectorAll(
      `span[data-te-target="#${dropdownId}"]:not([data-te-collapse-collapsed])`
    )?.[0];
    if (bulkAction) {
      bulkAction.click();
    }
  }
};
// export const
export const quantityOptions = () => {
  let quantityOptions = Array.from({ length: 5 }, (_, index) => ({
    id: (index + 1).toString(),
    name: (index + 1).toString(),
    postData: (index + 1).toString(),
  }));
  quantityOptions.unshift({ id: "6", name: "Any", postData: "1" });
  return quantityOptions;
};

export const getOptionForDropdown = (value: any, name: any, icon: any) => {
  if (value && name) {
    return {
      id: value,
      name: name,
      lable: name,
      icon: icon,
      value: name,
    };
  }
};

export const getHeaderFilter = (
  globalStateKey: string,
  globalState: any,
  constArray: any
) => {
  let isFilterExistInDB = globalState;
  const saved: any = localStorage.getItem(globalStateKey);
  return isFilterExistInDB || JSON.parse(saved) || constArray;
};

export function colorVirtualCard(color: any) {
  if (color === "Purple") {
    return "#5020A6";
  }
  if (color === "Green") {
    return "#269926";
  }
  if (color === "Blue") {
    return "#4F6DF0";
  }
  if (color === "Teal") {
    return "#1FA683";
  }
  if (color === "Violet") {
    return "#8F60E6";
  }
}

export function HexColorCodeForVirtualCard(color: any) {
  if (color === "#5020A6") {
    return "Purple";
  }
  if (color === "#269926") {
    return "Green";
  }
  if (color === "#4F6DF0") {
    return "Blue";
  }
  if (color === "#1FA683") {
    return "Teal";
  }
  if (color === "#8F60E6") {
    return "Violet";
  }
  return "Purple";
}

export function colorVirtualCardForDropDown(color: any) {
  if (color === "Purple") {
    return "card-100";
  }
  if (color === "Green") {
    return "card-200";
  }
  if (color === "Blue") {
    return "card-300";
  }
  if (color === "Teal") {
    return "card-400";
  }
  if (color === "Violet") {
    return "card-500";
  }
}

export function isValidUrl(url: any) {
  const urlRegex: any = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return urlRegex.test(url);
}

export function getExtension(filename: any) {
  return filename?.split(".").pop();
}

export function capitalizeFirstLetter(string: any) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
}

export const allFieldsFilled = (
  arr: any,
  values: any,
  errors: any = {},
  limit: number = 0
) => {
  for (const key of arr) {
    if (errors[key]) {
      return false;
    }
    if (
      values[key] === undefined ||
      values[key] === null ||
      values[key] === "" ||
      values[key]?.length < limit
    ) {
      return false;
    }
  }
  return true;
};

// HANDLE FILE CONVERSION
export const FileToObjectConversion = (data: any) => {
  const objects = [];

  for (let i = 0; i < data.length; i++) {
    const file = data[i];
    const object = {
      name: file.name,
      lastModified: file.lastModified,
      size: file.size,
      type: file.type,
      base64: file?.base64,
    };
    objects.push(object);
  }
  return objects;
};
export function checkingValidUrlForBoth(data: any) {
  return isValidUrl(data?.android) && isValidUrl(data?.ios);
}

// HANDLE ON FOCUS
export const handleOnFocus = (
  data: any,
  isFocused: boolean | string,
  formik: any
) => {
  let name = data?.target?.name;
  if ((!isFocused || isFocused === "blur") && formik?.errors?.[name]) {
    formik.setFieldTouched(name, true);
  }
};

export const handleInputChange = (newValue: any, ID: any) => {
  let id: any = document.getElementById(ID);
  if (newValue && id) {
    id?.classList?.add("input-typing");
  } else {
    id?.classList?.remove("input-typing");
  }
  return newValue;
};

export const MobileInsturction = [
  "Mobile Link URLs can be entered directly into each ticket field on the right-hand side",
  "If your tickets have separate URLs for Android/iOS - select the switch to enable multiple fields for each ticket",
  "If you have .pkpass files to convert to links - use this window to upload them",
  "Select the ‘PKPASS’ switch on the listing, then drag and drop each .pkpass file - ensuring any designated seat matches the ticket - to generate a Mobile Link URL",
  "Confirm all tickets are uploaded by clicking the green ‘Confirm’ button",
];

export function checkPPEExist(list: any, isGodAdmin: any) {
  const selectedEventRecords: any = list
    ?.flatMap((item: any) =>
      item?.eventRecords?.filter((record: any) => record?.selected)
    )
    .filter(Boolean);

  let godAdminNotSameTeamError = false;
  if (isGodAdmin) {
    const allSameTeamName = selectedEventRecords?.map(
      (record: any) => record?.team_name
    );
    godAdminNotSameTeamError = allSameTeamName.every(
      (name: any) => name === allSameTeamName?.[0]
    );
  }

  const hasPricePerExchangeOne = selectedEventRecords?.some(
    (obj: any) =>
      obj?.price_per_exchange === 1 ||
      obj?.price_per_exchange === true ||
      obj?.ppe === true
  );

  const findAllexhange = selectedEventRecords?.every(
    (obj: any) =>
      obj?.price_per_exchange === 1 ||
      obj?.price_per_exchange === true ||
      obj?.ppe === true
  );

  let isError = false;

  if (isGodAdmin) {
    if (godAdminNotSameTeamError) {
      isError = hasPricePerExchangeOne || false;
    } else {
      isError = true;
    }
  } else {
    isError = hasPricePerExchangeOne || false;
  }

  return { isError: isError, teamNameError: !godAdminNotSameTeamError };
}

export function checkPPEExistForTableLevelNEw(list: any, isGodAdmin: any) {
  let allPPE: any = false;
  let somePPE: any = false;
  let godAdminSameTeam: any = false;

  const filteredEvents = list?.eventRecords?.filter(
    (eventGroup: any) => eventGroup?.selected
  );

  allPPE =
    filteredEvents?.length > 0 &&
    filteredEvents?.every(
      (obj: any) =>
        obj?.price_per_exchange === 1 ||
        // (obj.price_per_exchange === false &&
        //   (obj.replacible.price_per_exchange === 1 ||
        //     obj.replacible.price_per_exchange === true) &&
        //   obj?.isInputChange === true) ||
        obj.ppe === true
    );

  somePPE =
    filteredEvents?.length === 1
      ? false
      : filteredEvents?.length > 0 &&
        filteredEvents?.some(
          (obj: any) =>
            obj?.price_per_exchange === 1 ||
            // (obj.price_per_exchange === false &&
            //   (obj.replacible.price_per_exchange === 1 ||
            //     obj.replacible.price_per_exchange === true) &&
            //   obj?.isInputChange === true) ||
            obj.ppe === true
        );

  if (isGodAdmin) {
    const allSameTeamName = filteredEvents?.map(
      (record: any) => record?.team_name
    );
    godAdminSameTeam = allSameTeamName.every(
      (name: any) => name === allSameTeamName?.[0]
    );
  }

  return {
    allPPE,
    somePPE,
    allNonPPE: !allPPE && !somePPE,
    godAdminError: isGodAdmin ? !godAdminSameTeam : false,
  };
}

export function getSellerLevelConfiguration(levelString: any, conf: any) {
  let data: any = {
    levelName: "", // USED IN SIDE MENU -> LOGIN USER NAME -> RIGHT TOP ROUND ICON
    sellerLevelInterCom: "", // USED IN INTERCOM FOR SPECIFY SELLER LEVEL OF LOGGED USER
  };
  levelString = levelString?.toLowerCase();
  switch (levelString) {
    case "level 1":
      data.levelName = 1;
      data.sellerLevelInterCom = 1;
      break;
    case "level 2":
      data.levelName = 2;
      data.sellerLevelInterCom = 2;
      break;
    case "level 3":
      data.levelName = 3;
      data.sellerLevelInterCom = 3;
      break;
    case "super level":
      data.levelName = "S";
      data.sellerLevelInterCom = 0;
      break;
    case "super seller":
      data.levelName = "S";
      data.sellerLevelInterCom = 0;
      break;
    default:
  }

  return data[conf];
}

export function getSellerLevelNumberWithColor(data: any) {
  const levelString = data?.title;
  return getSellerLevelConfiguration(levelString, "levelName");
}

export function compareWeightageWithEvents(
  data: any,
  sellerData: any,
  mainData: any
) {
  let message: any;
  let flag = false;

  // const preSale = mainData?.pre_sale_date
  //   ? moment.unix(mainData?.pre_sale_date).utc().isAfter(moment().utc())
  //   : false;

  if (
    data > sellerData?.weightage
    //|| preSale
  ) {
    flag = true;
    const level = getSellerLevelNumberWithColor(sellerData);

    message =
      sellerData?.title?.toLowerCase() === "super seller"
        ? `Super seller not eligible to create listings for this event`
        : `Seller Level [${level}]  not eligible to create listings for this event`;
  }
  return { flag, message };
}

export async function uploadAdditionalFileAWS(file: any) {
  try {
    // Set up S3 configuration
    const additionalConfig = {
      ...S3_CONFIG,
      dirName: "additional-file",
    };

    // const additionalFileRes: any = await additionalFileS3.uploadFile(file);
    const additionalFileRes: any = await uploadFileWithPreAssignedURL(
      file,
      "additional-file"
    );

    return additionalFileRes?.key;
  } catch (error) {
    console.error("Error uploading additional file:", error);
    throw error;
  }
}

export async function checkWeightageOfEvent(
  selectedEventsIDS: any,
  coreLayout: any,
  sellerData: any
) {
  const response = await algoliaSearchForsWeightage({
    selectedEventsIDS,
    coreLayout,
  });
  const data = response?.hits;
  const level = getSellerLevelNumberWithColor(sellerData);

  // const preSale = data?.some((innerObj: any) =>
  //   moment.unix(innerObj?.pre_sale_date).utc().isAfter(moment().utc())
  // );
  const checkWeightage = data?.some(
    (innerObj: any) => innerObj?.seller_level_weightage > sellerData?.weightage
  );
  //|| preSale;

  // const preSale = mainData?.pre_sale_date
  //   ? moment.unix(mainData?.pre_sale_date).utc().isAfter(moment().utc())
  //   : false;

  const filterEvenets = data?.filter(
    (innerObj: any) => innerObj?.seller_level_weightage > sellerData?.weightage
  );

  return { checkWeightage, level, filterEvenets };
}
export const dynamicAWSUpload = (
  preAssignedURLData: any,
  selectedFile: any
) => {
  return new Promise((resolve, reject) => {
    if (preAssignedURLData) {
      const reader = new FileReader();
      const presignedUrl = preAssignedURLData;

      reader.onload = () => {
        const binaryData = reader.result;

        fetch(presignedUrl, {
          method: "PUT",
          body: binaryData,
          headers: {
            "Content-Type": selectedFile.type,
          },
        })
          .then((response) => {
            if (response.ok) {
              // resolve(response);
              resolve(response);
            } else {
              console.error("Upload failed:", response.statusText);
              reject(new Error(`Upload failed: ${response.statusText}`));
            }
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            reject(error);
          });
      };

      reader.readAsArrayBuffer(selectedFile);
    } else {
      const errorMsg = "No presigned URL available";
      console.error(errorMsg);
      reject(new Error(errorMsg));
    }
  });
};

export const uploadFileWithPreAssignedURL = async (
  file: any,
  fileNameWithPath: string
) => {
  const preAssignedURL = await generatePreassignedURL(
    `${fileNameWithPath}/${uuidv4()}.${getExtension(file?.name)}`
  );

  if (preAssignedURL?.data?.presigned_url) {
    const presignedUrl = preAssignedURL.data.presigned_url;
    const response: any = await dynamicAWSUpload(presignedUrl, file);
    if (response?.status === 200) {
      let fileURL = response?.url?.split("?")[0];
      return {
        location: fileURL,
        key: fileURL,
      };
    } else {
      return null;
    }
  } else {
    throw new Error("Failed to generate preassigned URL");
  }
};

export const referralLinkGenerator = (referralCode: string) => {
  return window.location.hostname === "localhost"
    ? `http://localhost:3000/sign-up/${referralCode ?? ""}`
    : `${window.location.hostname}/sign-up/${referralCode ?? ""}`;
};

export function getAuthDataFromStorage() {
  let getAuthData: any =
    localStorage.getItem("authData") || Cookies.get("authData");
  return getAuthData as String;
}

export function authVariable(infoData: any) {
  let authTokens: any = Cookies.get("authTokens");
  const data = {
    id: infoData?.id,
    team_id: infoData?.team_data?.id,
    broadcast_id: infoData?.broadcast_id,
    name: `${infoData?.account_info?.firstname} ${infoData?.account_info?.surname}`,
    firstname: infoData?.account_info?.firstname,
    lastname: infoData?.account_info?.surname,
    email: infoData?.account_info?.email,
    username: infoData?.username,
    base_currency: infoData?.account_info?.base_currency,
    token: authTokens ? JSON.parse(authTokens) : "",
    status: infoData?.status,
    is_god_admin: infoData?.account_info?.is_god_admin,
    tx_pay_info_status: infoData?.tx_pay_info_status,
    allowed_access: infoData?.allowed_access,
    tx_trade_user: infoData?.tx_trade_user,
    available_amount: infoData?.account_info?.available_amount,
    intercom_hash: infoData?.intercom_hash,
    team_data: infoData?.team_data,
  };

  return data;
}

export const getTotalActiveFilters = (filters: any) => {
  let count = 0;

  const {
    orderFilters = {},
    invoiceFilters = {},
    payOutFilters = {},
    sellerWalletsFilter = {},
    bankAccountFilter = {},
    withdrawRequestFilter = {},
    tab,
  } = filters;

  if (orderFilters?.seller_levels?.length > 0) count++;
  if (orderFilters?.teams?.length > 0) count++;
  if (orderFilters?.order_ids?.length > 0) count++;
  if (_.size(orderFilters?.marketplace) > 0) count++;
  if (
    orderFilters?.transcation_date?.startDate !== undefined &&
    orderFilters?.transcation_date?.endDate !== undefined
  )
    count++;
  if (
    orderFilters?.event_date?.startDate !== undefined &&
    orderFilters?.event_date?.endDate !== undefined
  )
    count++;
  if (orderFilters?.order_status?.length > 0) count++;
  if (orderFilters?.internal_order_status?.length > 0) count++;
  if (orderFilters?.payment_status?.length > 0) count++;
  if (orderFilters?.store_id) count++;
  if (_.size(invoiceFilters?.marketplace) > 0) count++;
  if (
    invoiceFilters?.invoices_date?.startDate !== undefined &&
    invoiceFilters?.invoices_date?.endDate !== undefined
  )
    count++;
  if (
    invoiceFilters?.payment_date?.startDate !== undefined &&
    invoiceFilters?.payment_date?.endDate !== undefined
  )
    count++;
  if (invoiceFilters?.invoice_status?.length > 0) count++;
  if (payOutFilters?.teams?.length > 0) count++;
  if (
    payOutFilters?.pay_out_date?.startDate !== undefined &&
    payOutFilters?.pay_out_date?.endDate !== undefined
  )
    count++;
  if (_.size(orderFilters?.marketplaces_payment_status) > 0) count++;
  if (_.size(orderFilters?.event_id) > 0) count++;
  if (orderFilters?.marketplace_currency) count++;
  if (orderFilters?.query) count++;
  if (invoiceFilters?.query) count++;
  if (payOutFilters?.payout_reference) count++;
  if (_.size(sellerWalletsFilter?.teams) > 0) count++;
  if (_.size(sellerWalletsFilter?.statuses) > 0) count++;
  if (_.size(bankAccountFilter?.teams) > 0) count++;
  if (_.size(withdrawRequestFilter?.teams) > 0) count++;
  if (
    tab === "Withdraw requests" &&
    _.size(withdrawRequestFilter?.statuses) > 0
  )
    count++;
  if (
    withdrawRequestFilter?.requestedDate?.startDate !== undefined &&
    withdrawRequestFilter?.requestedDate?.endDate !== undefined
  )
    count++;

  return count;
};
