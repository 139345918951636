import { LISTINGS_UTILS, QUERIES } from "helpers/const";
import TableWrapper from "pages/settings/component/my-referral/table/component/TableWrapper";
import { useContext } from "react";
import { useQuery } from "react-query";
import FilterWrapper from "./components/FilterWrapper";
import { getVenueDetails } from "./core/requests";
import { VenueDetailsContext } from "./core/VenueDetailsProvider";
import DataTable from "./table/DataTable";
// import TableWrapper from "./components/TableWrapper";

const VenueDetails = () => {
  const { venueFilter, venueListing, setVenueListing } =
    useContext(VenueDetailsContext);

  // GET LISTINGS
  const { data, isFetching } = useQuery(
    [QUERIES.VENUE_DETAILS_VARIATIONS, venueFilter],
    () => getVenueDetails(venueFilter),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      keepPreviousData: true,
      onSettled(data, error) {
        if (data) {
          setVenueListing((pre: any) => {
            if (data?.meta?.current_page > 1) {
              return [...pre, ...data?.data];
            }
            return data?.data;
          });
        }
      },
    }
  );

  return (
    <div className="main-content-wrap">
      <div
        className={`topFilters sticky-element top-[3.9375rem] z-[11] pb-5 bg-gray-200 max-md:z-[21] max-md:!static sticky`}
        id="topFilters"
      >
        <div className="accordion font-medium shadow-op2">
          {/* <HeaderFilter /> */}
          <FilterWrapper paginateData={data?.meta} loading={isFetching} />
        </div>
      </div>

      <TableWrapper
        loading={venueFilter?.page === 1 && isFetching}
        listings={venueListing}
        loadingText={`Loading your ${LISTINGS_UTILS.venueDetails.name}`}
      >
        <DataTable list={venueListing} paginateData={data?.meta} />
      </TableWrapper>
    </div>
  );
};

export default VenueDetails;
