/* eslint-disable jsx-a11y/anchor-is-valid */
import PriceFieldWithComma from "components/formComponent/PriceFieldWithComma";
import { useFormik } from "formik";
import { useContext, useRef, useState } from "react";
import * as Yup from "yup";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { KTSVG } from "../../../components/ui/KTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { addCommaInNumber } from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import { withdrawFunds } from "../core/requests";

const WithdrawFundsOffcanvas = ({ txPayData, refetch }: any) => {
  const globalLayout: any = useContext(LayoutContext);
  const [loader, setLoader] = useState(false);
  const ref = useRef<any>("");
  // INITIAL VALUES
  let initialValues = {
    amount: "",
  };

  // VALIDATION SCHEMA
  const withdrawFundsSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Amount is required")
      .min(1)
      .max(
        txPayData?.available_amount,
        "Amount should not be greater than available funds"
      )
      .label("Amount"),
  });

  // FORMIk
  const formik = useFormik({
    initialValues,
    validationSchema: withdrawFundsSchema,
    onSubmit: (values: any) => {
      setLoader(true);
      withdrawFunds(values)
        .then((response: any) => {
          if (response?.data?.status) {
            refetch &&
              refetch().then(() => {
                setLoader(false);
                showAlert(response?.message, false);
                ref.current.click();
              });
          } else {
            setLoader(false);
            showAlert(response?.message, true);
          }
        })
        .catch((error: any) => {
          setLoader(false);
        });
    },
  });

  return (
    <>
      <div
        data-te-modal-init
        className={`offcanvas currency offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium md:max-w-[45.25rem] max-w-full w-full upload-ticket-popup z-40`}
        tabIndex={-1}
        id="withdrowFunds"
        aria-labelledby="withdrowFundsLabel"
      >
        <div className="  bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              Withdraw funds
            </h5>
            <div className="buttons flex flex-wrap">
              <button
                type="button"
                className="flex items-center justify-center w-9 border-l box-content group"
                data-bs-dismiss="offcanvas"
              >
                <KTSVG
                  className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                  path="standard_icons/cross.svg"
                  svgClassName="w-2.5 h-2.5"
                />
              </button>
            </div>
          </div>
          <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full px-5 py-5 mb-1">
            <form onSubmit={formik.handleSubmit}>
              <div className="inner-body rounded border">
                <div className="from-row bg-white p-[15px]">
                  <div className="heading text-sm15 font-semibold leading-4 mb-4">
                    <h3>From</h3>
                  </div>
                  <h4 className="sub_heading text-sm14 font-medium leading-4 mb-4">
                    TX Pay
                  </h4>
                  <div className="paras text-sm12">
                    <p>
                      Available funds: {globalLayout?.currencySymbol}
                      {typeof txPayData?.available_amount === "number"
                        ? addCommaInNumber(txPayData?.available_amount)
                        : txPayData?.available_amount}
                    </p>
                    <p className="mt-0.5 text-gray-500">
                      {txPayData?.wallet_details?.tx_pay_id}
                    </p>
                  </div>
                </div>
                <div className="to-row p-[15px] border-t">
                  <div className="heading text-sm15 font-semibold leading-4 mb-4">
                    <h3>To</h3>
                  </div>
                  <h4 className="sub_heading text-sm14 font-medium leading-4 mb-4">
                    {txPayData?.withdrawal_account?.bank_name}
                  </h4>
                  <div className="paras text-sm12">
                    <p>{txPayData?.withdrawal_account?.account_holder_name}</p>
                    <p className="mt-0.5 text-gray-500">
                      {txPayData?.withdrawal_account?.iban}
                    </p>
                    {/* <a
                      href="#"
                      className="text-indigo-500 hover:text-violet-500 mt-0.5 inline-block"
                    >
                      Change account
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="bottom_fields mt-7">
                <h3 className="text-sm13 font-semibold mb-4">
                  Withdrawal details
                </h3>
                <div className="fields_group flex flex-wrap -mx-2.5">
                  <div className={`w-full sm:w-1/2  px-2.5 mb-3.5 relative`}>
                    <PriceFieldWithComma
                      currenncySymbol={globalLayout?.currencyLabel}
                      //isDisabled={true}
                      name="amount"
                      type="text"
                      value={formik.values.amount}
                      required={false}
                      errorClass={
                        formik.touched.amount &&
                        formik.errors.amount &&
                        "border-rose-500"
                      }
                      labelErrorClass={
                        formik.touched.amount &&
                        formik.errors.amount &&
                        "text-rose-500 "
                      }
                      label="Amount"
                      id="amount"
                      handleOnChange={(data: any) =>
                        formik.setFieldValue(
                          data?.target?.name,
                          data?.target?.value?.value
                        )
                      }
                      handleClearValue={() =>
                        formik.setFieldValue("amount", "")
                      }
                    />
                    {formik.touched.amount && formik.errors.amount && (
                      <div className="text-xxs text-rose-500 italic error-msg left-12">
                        {formik.errors.amount as string}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className=" offcanvas-footer bottom-0 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
            <div className="l_btn gap-2.5 flex flex-wrap items-center">
              <button
                className="btn"
                type="button"
                data-bs-dismiss="offcanvas"
                ref={ref}
                onClick={() => formik.resetForm()}
              >
                Cancel
              </button>
              <button
                className="btn green-btn hover:!bg-indigo-500 hover:!border-indigo-500 hover:!text-white disabled:pointer-events-none disabled:!bg-gray-100 disabled:!text-gray-400 disabled:!border-gray-100"
                type="button"
                onClick={(e: any) => formik.handleSubmit()}
                disabled={
                  loader || Number(formik.values.amount) <= 0 ? true : false
                }
              >
                Confirm
              </button>
            </div>
          </div>
          {/* {loader && <Loader />} */}
          {loader && <ProgressBardLoader secondCounter={3} />}
        </div>
      </div>
    </>
  );
};

export default WithdrawFundsOffcanvas;
