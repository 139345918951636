import HeaderCell from "pages/subscribe/personal_details/table/component/HeaderCell";

const TableHeaderRow = () => {
  return (
    <tr className="border-b bg-white">
      <HeaderCell
        title={"Reseller"}
        width="w-[11.25rem] min-w-[11.25rem]"
        arrow={false}
        postKey="reseller"
        padding="pl-5"
      />
      <th className="sticky right-0 ml-auto  p-0 min-w-[2.5rem] md:w-[5.0625rem] md:min-w-[5rem]">
        <div className="flex justify-end h-[2.5rem]  bg-white w-[2.5625rem]"></div>
      </th>
    </tr>
  );
};

export default TableHeaderRow;
