import { DeleteIcon } from "assets/media/icons/other_icons/DeleteIcon";
import clsx from "clsx";
import { LayoutContext } from "components/core/LayoutProvider";
import TextInput from "components/formComponent/TextInput";
import HeaderDetail from "components/sidePopup/HeaderDetail";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { useFormik } from "formik";
import { QUERIES, VENUE_NEW_VARIATION_STATE } from "helpers/const";
import { showAlert } from "helpers/ShowAlert";
import _, { size } from "lodash";
import AlgoliaAll from "pages/customize/component/UI/AlgoliaAll";
import { venueDetailsButtonTypes } from "pages/customize/core/_.model";
import React, { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Tooltip } from "react-tooltip";
import { Virtuoso } from "react-virtuoso";
import { v4 as uuidv4 } from "uuid"; // Generate unique IDs for new items
import * as Yup from "yup";
import {
  deleteVariation,
  getVariationDetails,
  updateVariation,
} from "../core/requests";
import { VenueDetailsContext } from "../core/VenueDetailsProvider";
import AddNewVariation from "./AddNewVariation";
import PopupFooter from "./PopupFooter";
import SectionSearch from "./SectionSearch";
import ViewpopupFooter from "./ViewpopupFooter";

const ErrorMessage = (
  formik: any,
  index: number,
  name: string,
  className: string
) => {
  if (
    (formik.touched.variationDetails?.[index] as any)?.[name] &&
    (formik.errors.variationDetails?.[index] as any)?.[name]
  ) {
    return className
      ? "border-rose-500"
      : (formik.errors.variationDetails[index] as any)?.[name];
  }
  return null;
};

interface viewForm {
  section: any;
  venue: any;
  sectionType: string;
  variation: string;
  loader: boolean;
}

interface FormValues {
  variationDetails: viewForm[];
  isDelete: number | undefined;
  updatedRecords: Set<number>;
  loader: false;
}

// Define validation schema with Yup
const validationSchema = Yup.object({
  variationDetails: Yup.array().of(
    Yup.object({
      section: Yup.object()
        .shape({
          name: Yup.string().required("Category/section is required"),
        })
        .required("Category/section is required"),
      variation: Yup.string()
        .matches(/^[^\s].*$/, "Variation cannot start with a space")
        .required("Variation is required"),
    })
  ),
});

const ViewForm = () => {
  const { viewForm, setViewForm } = useContext(VenueDetailsContext);
  const [initialVariations, setInitialVariations] = useState<any>();
  const [paginationState, setPaginationState] = useState<any>(
    VENUE_NEW_VARIATION_STATE
  );
  const [newVariationForm, setNewVariationForm] = useState<boolean>(false);
  const coreLayout = useContext(LayoutContext);

  // GET LISTINGS
  const { data, isFetching, refetch } = useQuery(
    [QUERIES.VARIATION_DETAILS, viewForm, paginationState],
    () => getVariationDetails({ ...viewForm, ...paginationState }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onSettled(data) {
        if (data && data?.data?.variations) {
          setInitialVariations((prev: any) => {
            const newVariations =
              data?.data?.variations?.length > 0
                ? data?.data?.variations.map((item: any) => ({
                    id: uuidv4(),
                    section: {
                      id: item?.id,
                      name: item?.name,
                      reseller_id: item?.reseller_id,
                    },
                    venue: { id: item?.venue_id, name: item?.venue_name },
                    sectionType: item?.type === 1 ? "Category" : "Section",
                    variation: item?.variation,
                  }))
                : [];
            const combinedVariations =
              data?.meta?.current_page > 1
                ? [...prev, ...newVariations]
                : newVariations;

            // Ensure uniqueness based on 'id'
            return _.uniqBy(combinedVariations, "id");
          });
        }
      },
    }
  );

  // FORMIK HANDLE
  const formik = useFormik<FormValues>({
    initialValues: {
      variationDetails: initialVariations,
      isDelete: undefined,
      updatedRecords: new Set(),
      loader: false,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      const localPayload = values?.variationDetails?.filter(
        (_: any, idx: number) => values?.updatedRecords.has(idx)
      );

      // UPDATE VARIATION
      const response = await updateVariation(localPayload);

      // ON SUCCESS
      if (
        response?.data &&
        size(response?.data) > 0 &&
        response?.data?.status
      ) {
        showAlert(response?.message, false);
        formik.setFieldValue("updatedRecords", new Set());
        refetch();
        setPaginationState(VENUE_NEW_VARIATION_STATE);
      } else {
        // ON FAILED
        showAlert(
          response?.errors?.title
            ? response?.errors?.title
            : Array.isArray(response?.errors)
            ? response?.errors?.join("<br/>")
            : response?.message ?? "Something went wrong!",
          true
        );
      }
    },
  });

  // HANDLE CLOSE POPUP
  const handleClosePopup = () => {
    if (formik.values.isDelete !== undefined) {
      formik.setFieldValue("isDelete", undefined);
    } else {
      formik.resetForm();
    }
  };

  // REMOVE ROW
  const handleRemoveHeader = async (index: number) => {
    const updatedVariationDetails = formik.values.variationDetails.filter(
      (_: any, idx: number) => idx !== index
    );
    const deletedRecord: any = formik.values.variationDetails?.find(
      (_: any, variationIndex: number) => variationIndex === index
    );

    // IF IT IS A NEW RECORD
    if (!deletedRecord?.section?.id) {
      formik.setFieldValue("loader", false);

      formik.setFieldValue("variationDetails", updatedVariationDetails);
    } else {
      // IF THIS RECORD IS FROM API
      const response: any = await deleteVariation(deletedRecord);
      formik.setFieldValue("loader", false);

      // ON SUCCESS
      if (response?.data?.status) {
        const timer: number = 2000;
        showAlert(response?.message, false, timer);
        setTimeout(() => {
          formik.setFieldValue("variationDetails", updatedVariationDetails);
          setPaginationState(VENUE_NEW_VARIATION_STATE);
          refetch();
        }, timer);
      } else {
        showAlert(
          response?.errors?.title
            ? response?.errors?.title
            : Array.isArray(response?.errors)
            ? response?.errors?.join("<br/>")
            : response?.message ?? "Something went wrong!",
          true
        );
      }
    }
  };

  // HANDLE CLEAR VALUE
  const handleClearValue = (name: string, index: number) => {
    const updatedVariation = formik.values.variationDetails?.map(
      (item: any, sIndex: number) =>
        sIndex === index ? { ...item, [name]: "" } : item
    );
    formik.setFieldValue("variationDetails", updatedVariation);
    formik.setFieldValue(
      "updatedRecords",
      new Set(formik.values.updatedRecords).add(index)
    );
  };

  // HANDLE SUBMIT AND DELETE
  const handleSubmit = () => {
    if (formik.values.isDelete !== undefined) {
      formik.setFieldValue("loader", true);
      handleRemoveHeader(formik.values.isDelete);
      formik.setFieldValue("isDelete", undefined);
    } else {
      formik.handleSubmit();
    }
  };

  // HANDLE ONCHANGE
  const handleOnChange = (event: any, index: any) => {
    // ONLY UPDATED RECORDS
    formik.setFieldValue(
      "updatedRecords",
      new Set(formik.values.updatedRecords).add(index)
    );

    formik.handleChange(event);
  };

  // HANDLE SECTION HIT SELECT
  const handleSectionHit = (data: any, index: number) => {
    formik.setFieldValue(
      "updatedRecords",
      new Set(formik.values.updatedRecords).add(index)
    );
    formik.setFieldValue(
      `variationDetails.${index}.sectionType`,
      data?.componentType?.name
    );
    formik.setFieldValue(`variationDetails.${index}.section`, {
      ...formik.values.variationDetails[index].section,
      name: data?.name,
    });
  };

  // HANDLE SECTION HIT SELECT
  const handleHitVenue = (data: any, index: number, currentRecord: any) => {
    formik.setFieldValue(
      "updatedRecords",
      new Set(formik.values.updatedRecords).add(index)
    );

    formik.setFieldValue(`variationDetails.${index}.venue`, {
      id: data?.id,
      name: data?.name,
    });

    const params = {
      name: "",
      componentType: {
        name: currentRecord?.sectionType,
      },
    };
    handleSectionHit(params, index);
  };

  const paginateData = useMemo(() => data?.meta, [data?.meta]);

  const handleLazyLoad = () => {
    if (paginateData?.current_page < paginateData?.last_page) {
      setPaginationState((current: any) => {
        let nextPage: number = paginateData?.current_page + 1;
        return {
          ...current,
          page: nextPage,
          per_page: 20,
        };
      });
    }
  };

  // HANDLE DROPDOWN RESET
  const handleResetValueOfDropdown = (fieldName: string, index: number) => {
    formik.setFieldValue(
      "updatedRecords",
      new Set(formik.values.updatedRecords).add(index)
    );
    formik.setFieldValue(`variationDetails.${index}.${fieldName}`, {
      ...(fieldName === "section"
        ? formik.values.variationDetails[index].section
        : {}),
      ...(fieldName === "section" ? {} : { id: "" }),
      name: "",
    });
  };

  return (
    <div
      className={clsx(
        "offcanvas buy_canvas s_ticket popupinput-style2 offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition-all duration-200 ease-in-out font-medium max-w-full w-full md:max-w-[calc(100%_-_3.75rem)]",
        viewForm?.status && "show",
        coreLayout.asideMenuOpen && "lg2:max-w-[calc(100%_-_13.875rem)]"
      )}
      tabIndex={-1}
      id="singleTicket"
      aria-labelledby="singleTicketLabel"
    >
      <div className="bg-white border-t w-full h-full ml-auto flex flex-col">
        <HeaderDetail
          label={`Reseller: ${viewForm?.data?.name}`}
          tooltipId="exist-variation-close"
          handleClosePopup={() => {
            setViewForm({ data: undefined, status: false });
            document.body.style.overflow = "";
          }}
        />

        <div
          className={clsx(
            " h-full",
            formik.values.isDelete !== undefined && "pointer-events-none",
            newVariationForm
              ? "overflow-hidden [&_[data-virtuoso-scroller='true']]:!overflow-hidden pointer-events-none"
              : "overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
          )}
        >
          {/* REFERRAL DETAILS */}
          <div className="inventory-list w-full h-full invoice-table variation-virtuoso">
            {!isFetching && size(formik.values.variationDetails) === 0 ? (
              <div className="w-full px-5 flex items-center justify-center mb-5 min-h-[2.5rem] mt-5">
                <div className="border rounded text-center text-sm13 font-medium p-[.5625rem] w-full">
                  No data available
                </div>
              </div>
            ) : (
              <Virtuoso
                data={
                  isFetching
                    ? Array.from({ length: 10 })
                    : formik.values.variationDetails
                }
                totalCount={formik.values.variationDetails?.length || 0}
                endReached={handleLazyLoad}
                components={{
                  Footer: () => (
                    <PopupFooter loader={isFetching} paginate={paginateData} />
                  ),
                }}
                itemContent={(index: any, list: any) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="pb-5">
                        <div
                          className={clsx(
                            isFetching &&
                              paginationState?.page === 1 &&
                              "shimmer-effect",
                            "w-full flex flex-wrap md:gap-y-4 gap-y-2.5 p-2.5 md:p-5 border rounded transition",
                            formik.values.isDelete === index &&
                              "bg-rose-500 bg-opacity-[7%]",
                            formik.values.updatedRecords?.has(index) &&
                              "bg-indigo-500 bg-opacity-[7%]"
                          )}
                        >
                          {/* DELETE   */}
                          <div className="delButon pr-2.5">
                            <button
                              type="button"
                              data-tooltip-id={`delete-${index}`}
                              className={clsx(
                                `border group inline-flex group items-center font-medium rounded text-sm13 px-2.5 md:py-0.5 py-2.5 max-h-[1.875rem] h-full`,
                                formik.values.updatedRecords?.size > 0 &&
                                  "pointer-events-none"
                              )}
                              disabled={
                                formik.values.updatedRecords?.size > 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                formik.setFieldValue("isDelete", index)
                              }
                            >
                              <div
                                className={clsx(
                                  "w-3 h-[.9375rem] flex items-center justify-center  rounded-full cursor-pointer fill-violet-500 hover:fill-indigo-500",
                                  formik.values.updatedRecords?.size > 0
                                    ? "!fill-gray-500"
                                    : "fill-violet-500 hover:fill-indigo-500"
                                )}
                              >
                                <Tooltip
                                  id={`delete-${index}`}
                                  content="Delete"
                                  place="top"
                                  variant="light"
                                  className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-[9999] `}
                                />
                                <DeleteIcon className="w-3 h-[.9375rem]" />
                              </div>
                            </button>
                          </div>
                          <div className="flex flex-wrap md:flex-1 md:gap-y-4 gap-y-2.5">
                            <div
                              id={index}
                              className={`w-full lg:w-1/2  lg2:w-[40%] md:pl-2.5 lg2:pr-2.5`}
                            >
                              <div
                                className={`relative rounded flex-1 max-w-full md:mb-0 border-rose-500 `}
                              >
                                <div className="w-full">
                                  <AlgoliaAll
                                    buttonTypes={["Venue"]}
                                    width={"full"}
                                    id={`venue-${index}`}
                                    index={`venue-${index}`}
                                    inputValue={list?.venue?.name}
                                    value={list?.venue}
                                    isVenueFullNameRequired={true}
                                    handleHitSelect={(data: any) =>
                                      handleHitVenue(data, index, list)
                                    }
                                    handlerResetValue={() =>
                                      handleResetValueOfDropdown("venue", index)
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            {/* SECTION SEARCH  */}
                            <SectionSearch
                              buttonTypes={venueDetailsButtonTypes}
                              index={index}
                              inputValue={list?.section?.name}
                              value={list?.section}
                              handleHitSelect={(data: any) =>
                                handleSectionHit(data, index)
                              }
                              handlerResetValue={() =>
                                handleResetValueOfDropdown("section", index)
                              }
                              isUpdatedRecord={formik.values.updatedRecords.has(
                                index
                              )}
                              error={
                                (formik.errors.variationDetails?.[index] as any)
                                  ?.section &&
                                (
                                  formik.touched.variationDetails?.[
                                    index
                                  ] as any
                                )?.section
                              }
                              errorLabel={
                                (formik.errors.variationDetails?.[index] as any)
                                  ?.section?.name
                              }
                              type={
                                formik?.values?.variationDetails?.[index]
                                  ?.sectionType
                              }
                              exchangeID={
                                data?.data?.reseller_details?.exchange_id
                              }
                              venueId={
                                formik?.values?.variationDetails?.[index]?.venue
                                  ?.id
                              }
                            />
                            {/* VARIATION */}
                            <div className="relative w-full md:w-1/2 rounded lg2:w-[20%] md:pl-2.5">
                              <div className={`rounded`}>
                                <TextInput
                                  name={`variationDetails.${index}.variation`}
                                  type="text"
                                  value={list?.variation}
                                  required={false}
                                  placeholder={"Enter variation"}
                                  inputClassName="appearance-none border border-gray-300 rounded w-full h-[1.875rem] px-2.5 pt-[0.4375rem] pb-2 focus:ring-0 focus:border-indigo-500 focus:bg-indigo-500 focus:bg-opacity-[7%] placeholder:truncate placeholder-shown:text-gray-400 block font-medium text-xs transition bg-white truncate"
                                  handleOnChange={(e: any) =>
                                    handleOnChange(e, index)
                                  }
                                  handleClearValue={() =>
                                    handleClearValue("variation", index)
                                  }
                                  errorClass={ErrorMessage(
                                    formik,
                                    index,
                                    "variation",
                                    "className"
                                  )}
                                  labelErrorClass={ErrorMessage(
                                    formik,
                                    index,
                                    "variation",
                                    "className"
                                  )}
                                />
                                {ErrorMessage(
                                  formik,
                                  index,
                                  "variation",
                                  ""
                                ) && (
                                  <div className="text-xs text-rose-500 error-msg left-5">
                                    {ErrorMessage(
                                      formik,
                                      index,
                                      "variation",
                                      ""
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }}
              />
            )}
          </div>
        </div>
        {/* FOOTER */}
        <ViewpopupFooter
          formik={formik}
          isShowForm={newVariationForm}
          handleClosePopup={handleClosePopup}
          handleAddReferral={() => setNewVariationForm(true)}
          handleSubmit={handleSubmit}
        />
      </div>

      {/* ADD NEW VARIATION */}
      <AddNewVariation
        referralID={data?.data?.reseller_details?.id}
        isShowForm={newVariationForm}
        setState={setNewVariationForm}
        listRefetch={refetch}
      />

      {formik.values.loader ? <ProgressBardLoader secondCounter={1} /> : null}
    </div>
  );
};

export default ViewForm;
