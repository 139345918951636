import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { isMobile, REACT_APP_URL } from "../../../helpers/const";
import { handleLogout } from "../../../helpers/Functions";
import { LayoutContext } from "../../core/LayoutProvider";
import AsideItemList from "./AsideItemList";

type Props = {
  state: any;
  title: string;
  svgName: string;
  path: any;
  width: string;
  height: string;
  showSubmenu?: any;
  setUserInfo?: any;
  separator?: boolean;
  setOpen?: any;
  setAsideMenuOpen?: any;
  sellerInfoOpen?: any;
};

const AsideMenuItem: FC<Props> = ({
  state,
  title,
  svgName,
  path,
  width,
  height,
  showSubmenu,
  separator,
  setUserInfo,
  setOpen,
  setAsideMenuOpen,
  sellerInfoOpen,
}: any) => {
  const layout = useContext(LayoutContext);
  const { pathname } = useLocation();

  const [subMenuActive, setsubMenuActive] = useState(false);
  const trimmedTitle = title.toLowerCase().replace(/\s+/g, "_");
  useEffect(() => {
    const Parents = document.querySelectorAll(".parent");

    const MenuHover = () => {
      Parents.forEach((Parent: any) => {
        const submenuWrapper = Parent.querySelector(".wrapper") as HTMLElement;
        const menu_wrap: any = document.querySelector(".menu-wrap ");
        const menu_wrapWidth = menu_wrap.offsetWidth;

        if (submenuWrapper) {
          const submenuWrapperStyles: any =
            window.getComputedStyle(submenuWrapper);

          const submenuWrapperPaddingLeft = parseFloat(
            submenuWrapperStyles.paddingLeft
          );

          const topPosition = Parent.getBoundingClientRect().top;
          const leftDiv = menu_wrapWidth - submenuWrapperPaddingLeft;
          submenuWrapper.style.top = `${topPosition}px`;
          submenuWrapper.style.left = `${leftDiv}px`;
        }
      });
    };

    Parents.forEach((Parent) => {
      Parent.addEventListener("mouseover", MenuHover);
      window.addEventListener("resize", MenuHover);
    });

    // Cleanup function to remove event listeners
    return () => {
      Parents.forEach((Parent) => {
        Parent.removeEventListener("mouseover", MenuHover);
        window.removeEventListener("resize", MenuHover);
      });
    };
  }, [showSubmenu]); // Empty dependency array ensures this runs only once

  function generateId(title: string) {
    return title.toLowerCase().replace(/\s+/g, "-");
  }

  if (title === "Logout") {
    return (
      <React.Fragment key={title}>
        <AsideItemList
          props={{
            state,
            title,
            svgName,
            path,
            width,
            height,
            pathname,
            sellerInfoOpen,
          }}
          handleOnClick={() => {
            handleLogout();
          }}
        />
      </React.Fragment>
    );
  } else {
    return (
      <Fragment key={title}>
        {title === "Notifications" ? (
          <>
            <li
              id={"Notifications"}
              onClick={(e: any) => {
                e.preventDefault();
                if (layout.notificationId === 1) {
                  layout.setNotificationId(undefined);
                  setUserInfo(false);
                  if (isMobile) {
                    setAsideMenuOpen(false);
                  }
                } else {
                  layout.setNotificationId(1);
                  setUserInfo(false);
                  if (isMobile) {
                    setAsideMenuOpen(false);
                  }
                }
              }}
            >
              <Link
                key={title}
                to={"#"}
                className={`${
                  !state ? "flex " : "flex"
                }  rounded p-2 min-h-[2.5rem] cursor-pointer hover:bg-light-white text-gray-300 transition text-sm13 items-center gap-x-3 hover:bg-violet-400/50 ${
                  pathname === "/notifications" &&
                  !sellerInfoOpen &&
                  "bg-violet-400 hover:!bg-violet-400/50 active"
                } ${layout?.notificationId && "!bg-violet-400"}`}
              >
                <div className="flex justify-center min-w-[1.5rem]">
                  <SVG src={svgName} className={`${width} ${height}`} />
                </div>
                <span
                  className={`${
                    !state && "hidden"
                  } origin-left duration-400 text-white font-medium leading-4 text-truncate`}
                >
                  {title}
                </span>
                {!state && (
                  <Tooltip
                    anchorId={"Notifications"}
                    content={title}
                    place="right"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-1000 -ml-1.5 no-angle z-[1] max-lg:hidden"
                    positionStrategy="fixed"
                  />
                )}
              </Link>
            </li>
            {separator && (
              <hr className="-mx-2.5 my-2.5 h-px bg-white opacity-[15%]"></hr>
            )}
          </>
        ) : title === "Dashboard" ? (
          <AsideItemList
            props={{
              state,
              title,
              svgName,
              path,
              width,
              height,
              pathname,
              sellerInfoOpen,
            }}
            handleOnClick={(e: any) => {
              e.preventDefault();
              let logo: any = document.querySelector(".logo");
              logo && logo.click();
              layout.setNotificationId(0);
            }}
          >
            <Link
              id={generateId(title)}
              key={title}
              to={path}
              className="w-full"
              reloadDocument
            ></Link>
          </AsideItemList>
        ) : (
          <AsideItemList
            props={{
              state,
              title,
              svgName,
              path,
              width,
              height,
              pathname,
              showSubmenu,
              subMenuActive,
              separator,
              setsubMenuActive,
              setOpen,
              sellerInfoOpen,
            }}
            handleOnClick={(e: any) => {
              // let logo: any = document.querySelector(".logo");
              // logo && logo.click();
              e.preventDefault();
              setsubMenuActive((submenu) => !submenu);
              layout.setNotificationId(0);
            }}
          >
            <a
              id={generateId(title)}
              key={title}
              href={
                Array.isArray(path)
                  ? REACT_APP_URL + path[0]
                  : REACT_APP_URL + path || "#"
              }
              className="parent w-full"
              data-menu-id={trimmedTitle}
            ></a>
          </AsideItemList>
        )}
      </Fragment>
    );
  }
};

export default AsideMenuItem;
