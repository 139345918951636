// import crossIcon from "../../../../assets/media/icons/other_icons/Delete.svg";
import { CrossIcon } from "assets/media/icons/other_icons/CrossIcon";
import { default as refreshSVG } from "assets/media/icons/other_icons/refresh.svg";
import { IKTSVG } from "components/ui/IKTSVG";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

const CommonFilterChips = ({
  filterValue,
  filterName,
  onRemove,
  loading,
}: any) => {
  return (
    <div
      className={`flex py-1 px-2.5 text-xs border rounded-full my-0.5 ${
        // isLoading && "shimmer-effect"
        ""
      }`}
    >
      {filterValue && (
        <span className={`${loading && "opacity-0"}`}>{filterValue}</span>
      )}
      <button type="button" className="ml-2 " onClick={onRemove}>
        <CrossIcon className="h-2 w-2  fill-violet-800 hover:fill-indigo-500" />
      </button>
    </div>
  );
};

const FilterChips = ({
  loading,
  filterchipData,
  filtersData,
  setFiltersData,
  paginateData,
  totalName,
  totalRecords = undefined,
  handleOnApplyButtonClick = () => {},
}: any) => {
  const [total, setTotal] = useState(paginateData?.total);

  useEffect(() => {
    setTotal(paginateData?.total);
  }, [paginateData]);

  const handleOnclear = () => {
    setFiltersData({
      page: 1,
      per_page: 20,
    });
    handleOnApplyButtonClick("remove", {
      page: 1,
      per_page: 20,
    });
  };

  return (
    <>
      <div
        className={`py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-9rem)] `}
      >
        <div className={`${loading && "shimmer-effect"}`}>
          <span className={`${loading && "opacity-0"} flex gap-2`}>
            {total || totalRecords} {totalName}
          </span>
        </div>
      </div>

      <div className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:min-h-[3.375rem] max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-start">
        {loading ? (
          <div className="flex gap-5">
            <div
              className={`flex !rounded overflow-hidden ${"shimmer-effect"}`}
              style={{ height: "1.625rem", width: "9.375rem" }}
            >
              &nbsp;
            </div>
          </div>
        ) : (
          <>
            {filterchipData?.length > 0 && (
              <button
                type="button"
                className={`group border text-center bg-white rounded w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition ${
                  loading && "shimmer-effect"
                }`}
                onClick={handleOnclear}
                title="Reset"
                id="reset"
              >
                <Tooltip
                  anchorId={`reset`}
                  content={`Reset`}
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                />
                <IKTSVG
                  path={refreshSVG}
                  className="fill-violet-500 group-hover:fill-white transition"
                  svgClassName="mx-[.313rem] w-3 h-3"
                />
              </button>
            )}

            {filterchipData?.map((item: any) => {
              return (
                <CommonFilterChips
                  filterValue={item?.filterValue}
                  filterName={item?.filterName}
                  onRemove={() => item?.onRemove(item)}
                  loading={loading}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default FilterChips;
