import React, { useContext, useState } from "react";
import { Tooltip } from "react-tooltip";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import DropDownField from "../../../../components/formComponent/DropDownField";
import { KTSVG } from "../../../../components/ui/KTSVG";
import {
  convertPriceWithBaseCurrency,
  priceFormat,
} from "../../../../helpers/Functions";
export const MarketInsight = ({ event, setList }: any) => {
  const globalLayout = useContext(LayoutContext);
  const conversation_rate = globalLayout?.conversationRate;
  const user_currency = globalLayout?.currencyLabel;
  const [quantity, setQuantity] = useState<any>();
  const [marketplace, setMarketplace] = useState<any>();
  const [quantityValue, setQuantityValue] = useState<any>();
  const [category, setCategory] = useState<any>();
  let marketOptions: any = [];
  let categoryOptions: any = [];
  let quantityOptions: any = [];
  let marketInsightData: any =
    event?.markeInsights?.data?.results || event?.markeInsightsMain?.results;
  let groups: any = marketInsightData?.groups;
  let labelsArray: any = [
    { value: "N/A", label: "Total tickets" },

    {
      value: "N/A",
      label: "Lowest price",
    },
    {
      value: "N/A",
      label: "Highest price",
    },
    {
      value: "N/A",
      label: "Average price",
    },
  ];

  if (groups) {
    //GET MARKEPLACES
    marketOptions = Object.keys(groups)
      .map((item) => {
        return {
          id: item,
          name: item,
        };
      })
      .filter((item) => item.name !== "total");
    //GET CATEGORY OPTIONS
    categoryOptions = Object.keys(marketInsightData?.setting_types).map(
      (item) => {
        return {
          id: item,
          name: item,
        };
      }
    );
    //GET QUANTITY OPTIONS
    quantityOptions = Object.keys(groups?.total || {})
      .map((item) => {
        return Number(item.replace("_price_counts", ""));
      })
      .filter((item) => item !== 0 && !isNaN(item));

    const setting_types: any = Object.fromEntries(
      Object.entries(marketInsightData.setting_types).map(([key, value]) => [
        key?.trim()?.toLowerCase()?.split(" ")?.join(""),
        value,
      ])
    );

    let categoryTrim: any = category
      ?.trim()
      ?.toLowerCase()
      ?.split(" ")
      ?.join("");

    groups = category ? setting_types[categoryTrim] : groups;
    let priceCount =
      groups?.[marketplace ? marketplace : "total"]?.[
        quantityValue ? quantityValue + `_price_counts` : `price_counts`
      ];
    let priceAvg = priceFormat(
      groups?.[marketplace ? marketplace : "total"]?.[
        quantityValue ? quantityValue + "_price_avg" : `price_avg`
      ]
    );
    let priceMin = priceFormat(
      groups?.[marketplace ? marketplace : "total"]?.[
        quantityValue ? quantityValue + "_price_min" : `price_min`
      ]
    );
    let priceMax = priceFormat(
      groups?.[marketplace ? marketplace : "total"]?.[
        quantityValue ? quantityValue + "_price_max" : `price_max`
      ]
    );

    let onlyForCategoryPriceCount = priceFormat(
      setting_types[categoryTrim]?.["Total"]?.[
        quantityValue ? quantityValue + `_price_counts` : `price_counts`
      ]
    );
    let onlyForCategoryAvg = priceFormat(
      setting_types[categoryTrim]?.["Total"]?.[
        quantityValue ? quantityValue + "_price_avg" : `price_avg`
      ]
    );
    let onlyForCategoryMin = priceFormat(
      setting_types[categoryTrim]?.["Total"]?.[
        quantityValue ? quantityValue + "_price_min" : `price_min`
      ]
    );
    let onlyForCategoryMax = priceFormat(
      setting_types[categoryTrim]?.["Total"]?.[
        quantityValue ? quantityValue + "_price_max" : `price_max`
      ]
    );

    labelsArray = [
      {
        value: priceCount
          ? priceCount
          : onlyForCategoryPriceCount && !marketplace
          ? onlyForCategoryPriceCount
          : "N/A",
        label: "Total tickets",
      },

      {
        value: priceMin
          ? convertPriceWithBaseCurrency(
              marketInsightData?.currency || user_currency,
              priceMin,
              marketInsightData?.conversion_rate || conversation_rate
            )
          : onlyForCategoryMin && !marketplace
          ? convertPriceWithBaseCurrency(
              marketInsightData?.currency || user_currency,
              onlyForCategoryMin,
              marketInsightData?.conversion_rate || conversation_rate
            )
          : "N/A",
        label: "Lowest price",
      },
      {
        value: priceMax
          ? convertPriceWithBaseCurrency(
              marketInsightData?.currency || user_currency,
              priceMax,
              marketInsightData?.conversion_rate || conversation_rate
            )
          : onlyForCategoryMax && !marketplace
          ? convertPriceWithBaseCurrency(
              marketInsightData?.currency || user_currency,
              onlyForCategoryMax,
              marketInsightData?.conversion_rate || conversation_rate
            )
          : "N/A",
        label: "Highest price",
      },
      {
        value: priceAvg
          ? convertPriceWithBaseCurrency(
              marketInsightData?.currency || user_currency,
              priceAvg,
              marketInsightData?.conversion_rate || conversation_rate
            )
          : onlyForCategoryAvg && !marketplace
          ? convertPriceWithBaseCurrency(
              marketInsightData?.currency || user_currency,
              onlyForCategoryAvg,
              marketInsightData?.conversion_rate || conversation_rate
            )
          : "N/A",
        label: "Average price",
      },
    ];
  }
  const refresh = () => {
    setMarketplace("");
    setQuantity("");
    setCategory("");
    setQuantityValue("");
  };
  const quantityDropDownOption = quantityOptions?.map((obj: any) => {
    return {
      id: obj,
      name: obj,
      label: obj,
    };
  });

  return (
    <div
      id={`EventInsightCollapseBody${event?.id}`}
      className="!visible hidden inv_acccc mktins pointer-events-auto"
      data-te-collapse-item
    >
      <div className="accordion-body ">
        {!event?.markeInsights && event?.marketInsightLoader ? (
          <section className="flex flex-wrap border-b">
            <div className="center m-auto p-2">
              <KTSVG
                className="flex items-center justify-center fill-violet-500 transition animate-spin"
                path="other_icons/process.svg"
              />
            </div>
          </section>
        ) : marketInsightData && Object.values(marketInsightData).length > 0 && Object.keys(event?.markeInsights?.data?.results?.groups || {}).length > 0  ? (
          <>
            <section className="flex flex-wrap border-b bg-gray-100/50">
              <div className="flex md:flex-wrap items-center justify-start w-full  lg:border-t-0 border-t gap-x-5 gap-y-2.5 p-3 lg:px-4 xl:px-5 xl:pr-[.9375rem] lg:py-3 dd-180 max-sm:pt-10 relative  overflow-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-violet-800">
                <div className="flex flex-wrap justify-center gap-2 max-md:w-1/2 max-sm:w-full">
                  <div className="w-full">
                    <DropDownField
                      options={quantityDropDownOption}
                      placeholder="Quantity"
                      name="marketplace"
                      value={
                        quantityDropDownOption
                          ? quantityDropDownOption?.find(
                              (item: any) => item?.id === quantityValue
                            )
                          : ""
                      }
                      handleOnChange={(data: any) => {
                        setQuantityValue(data?.target?.value?.id);
                      }}
                      isMenuPortalTarget={true}
                    />
                  </div>
                </div>
                <div className="max-md:w-1/2 max-sm:w-full">
                  <DropDownField
                    options={categoryOptions}
                    placeholder="Category"
                    name="category"
                    value={
                      category
                        ? categoryOptions?.find(
                            (item: any) => item?.id == category
                          )
                        : ""
                    }
                    handleOnChange={(data: any) => {
                      setCategory(data?.target?.value?.id);
                    }}
                    isMenuPortalTarget={true}
                  />
                </div>

                <div className="max-md:w-1/2 max-sm:w-full">
                  <DropDownField
                    options={marketOptions}
                    placeholder="Marketplace"
                    name="marketplace"
                    value={
                      marketplace
                        ? marketOptions?.find(
                            (item: any) => item?.id == marketplace
                          )
                        : ""
                    }
                    handleOnChange={(data: any) => {
                      setMarketplace(data?.target?.value?.id);
                    }}
                    isMenuPortalTarget={true}
                  />
                </div>

                <div className="flex gap-2.5">
                  <button
                    type="button"
                    className="group flex items-center justify-center h-6 w-6 border border-gray-300 hover:border-indigo-500 text-white bg-white hover:bg-indigo-500 transition rounded"
                    onClick={() => refresh()}
                    id={`reset-${event?.id}`}
                  >
                    <Tooltip
                      anchorId={`reset-${event?.id}`}
                      content={`Reset`}
                      place="left"
                      variant="light"
                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-99999"
                    />
                    <KTSVG
                      className="fill-violet-500 group-hover:fill-white transition"
                      path="other_icons/refresh.svg"
                      svgClassName="w-3 h-3"
                    />
                  </button>
                </div>
              </div>
              <div className="px-3 py-4 md:pt-0 mkt-ins_content flex items-center ml-auto flex-wrap text-sm12">
                <span>
                  Market Insights are for guidance only and include marketplace
                  fees at various rates
                </span>
                <button
                  type="button"
                  className="group flex items-center justify-center  text-white bg-white  transition rounded ml-3.5 max-sm:absolute max-sm:top-3.5 max-sm:right-3.5"
                  data-te-collapse-init
                  data-te-collapse-collapsed
                  data-te-target={`#EventInsightCollapseBody${event?.id}`}
                  aria-expanded="true"
                  aria-controls={`EventInsightCollapseBody${event?.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setList((current: any) => {
                      return current.map((innerEvent: any) => {
                        return event?.id === innerEvent.id
                          ? { ...innerEvent, isOpenMarket: false }
                          : innerEvent;
                      });
                    });
                  }}
                >
                  <KTSVG
                    className="fill-violet-500 group-hover:fill-indigo-500 transition"
                    path="standard_icons/cross.svg"
                    svgClassName="w-[.6875rem] h-[.6875rem]"
                  />
                </button>
              </div>
            </section>
            <section className="flex md:flex-wrap border-b bg-gray-100/50 px-2 py-3 lg:py-0 overflow-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-violet-800">
              {labelsArray?.map((item: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <div className="py-1.5 lg:py-5 px-2.5 max-md:min-w-max md:w-1/2 lg:w-1/4">
                      <div className="border rounded-lg bg-white px-3 py-2.5">
                        <div className="flex">
                          <div className="flex-auto pr-4">
                            <p className="text-sm15 font-semibold">
                              {item?.value}
                            </p>
                            <p className="text-xs text-gray-500">
                              {item?.label}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </section>
          </>
        ) : (
          <section className="flex flex-wrap border-b bg-gray-100/50">
            <div className="px-[.9375rem] py-3 mkt-ins_content flex items-center ml-auto flex-wrap text-sm12">
              <span>Market Insights are unavailable for this event</span>
              <button
                type="button"
                className="group flex items-center justify-center  text-white bg-white  transition rounded ml-3.5"
                data-te-collapse-init
                data-te-collapse-collapsed
                data-te-target={`#EventInsightCollapseBody${event?.id}`}
                aria-expanded="true"
                aria-controls={`EventInsightCollapseBody${event?.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setList((current: any) => {
                    return current.map((innerEvent: any) => {
                      return event?.id === innerEvent.id
                        ? { ...innerEvent, isOpenMarket: false }
                        : innerEvent;
                    });
                  });
                }}
              >
                <KTSVG
                  className="fill-violet-500 group-hover:fill-indigo-500 transition"
                  path="standard_icons/cross.svg"
                  svgClassName="w-[.6875rem] h-[.6875rem]"
                />
              </button>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};
