/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import Card from "../../../../components/ui/Card";
import { KTSVG } from "../../../../components/ui/KTSVG";
import {
  addCommaInNumber,
  decimalNumber,
  getCurrencySymbol,
} from "../../../../helpers/Functions";
import { SYMBOLS } from "../../../../helpers/const";
import { getReportsListing } from "../../../Reports/core/requests";
import ReportCardSub from "./ReportCardSub";

const ReportsCard = ({
  reportsCardData,
  reportsLoading,
  reportsMetaData,
  selectedTeams,
  reportsOptionsData,
}: any) => {
  const coreLayout = useContext(LayoutContext);
  const [metaData, setMetaData] = useState(reportsMetaData);
  const [items, setItems] = useState<any>([]);
  const [error, setError] = useState<any>();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;
  const authUser = coreLayout.authUser;
  const access = coreLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );
  const currencySymbol = coreLayout.currencySymbol
    ? coreLayout.currencySymbol
    : "";

  const handleClick = () => {
    navigate("../reports", { replace: true });
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let prevPage: number = page + 1;
      getReportsListing({
        // optionsData: layout.salesOptions,
        page: prevPage,
        team_id: selectedTeams,
        filters: {
          specific: !isGodAdmin && userSpecific && [authUser?.id],
        },
      }).then((response: any) => {
        if (response) {
          // setData(response.data)
          setItems((prevItems: any) => [
            ...prevItems,
            ...response?.data?.orders,
          ]);
          setPage(prevPage);
          setIsLoading(false);
          setMetaData(response?.meta);
        }
      });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setItems(reportsCardData?.orders);
  }, [reportsCardData]);

  useEffect(() => {
    setMetaData(reportsMetaData);
  }, [reportsMetaData]);

  function isGodadminBasedCurrency(Array: any) {
    const teamArr = Array;
    if (teamArr === undefined || teamArr?.length === 0) {
      return coreLayout.currencySymbol;
    } else if (teamArr?.length > 1) {
      return coreLayout.currencySymbol;
    } else {
      return getCurrencySymbol(teamArr?.[0]?.base_currency);
    }
  }

  return (
    <div className="lg2:w-1/2 w-full md:px-2 md:mb-4 mb-2">
      <Card
        title="Reports"
        threeDot={true}
        handleClick={handleClick}
        cardTitle="Reports"
      >
        {/* Card sub Heading */}
        <div className="divide-gray-200 divide-y-2 ">
          <div className="divide-gray-200  ">
            {/* Card Body */}
            <div className="flex flex-nowrap justify-between p-5 gap-5 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
              <ReportCardSub
                title={"Total revenue"}
                reportsCardData={
                  reportsOptionsData?.data?.total_revenue
                    ? `
                    ${
                      isGodAdmin
                        ? isGodadminBasedCurrency(selectedTeams) || ""
                        : currencySymbol
                        ? currencySymbol
                        : ""
                    }${reportsOptionsData?.data?.total_revenue}`
                    : ""
                }
              />
              <ReportCardSub
                title={"Total sales"}
                reportsCardData={
                  reportsOptionsData?.data?.total_orders
                    ? addCommaInNumber(reportsOptionsData?.data?.total_orders)
                    : 0
                }
              />
              <ReportCardSub
                title={"Tickets sold"}
                reportsCardData={
                  reportsOptionsData?.data?.total_tickets_sold
                    ? addCommaInNumber(
                        reportsOptionsData?.data?.total_tickets_sold
                      )
                    : 0
                }
              />
            </div>
          </div>
          {items?.length > 0 ? (
            <div
              className={`flow-root max-h-48 outline-black overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full 
          ${reportsLoading && `pointer-events-none opacity-30`}
           `}
              id="reportsCard"
            >
              <div className="px-5 sticky top-0 bg-white text-gray-400 z-10">
                <div className="flex items-center border-b border-gray-100 py-1.5 space-x-2.5">
                  <div className="flex-1">
                    <p className="text-xs font-medium">Event description</p>
                  </div>
                  <div className="inline-flex items-center text-xs font-medium">
                    Revenue
                  </div>
                </div>
              </div>
              <InfiniteScroll
                dataLength={items?.length ? items?.length : 0}
                next={() => {
                  fetchData();
                }}
                hasMore={metaData?.current_page < metaData?.last_page}
                loader={Array.from({ length: 3 }, (v, i) => (
                  <div
                    className={`accordion font-medium px-5 mb-2 ${
                      i === 0 && "mt-2"
                    } `}
                    key={i}
                  >
                    <div
                      className={`accordion-item bg-white !rounded overflow-hidden shimmer-effect`}
                      style={{ height: "30px" }}
                    ></div>
                  </div>
                ))}
                // scrollThreshold="20px"
                scrollThreshold="180px"
                scrollableTarget="reportsCard"
              >
                <ul className="max-h-50 overflow-y-auto">
                  {items?.map((order: any, index: any) => (
                    <li
                      className="hover:bg-indigo-500/5 px-5 transition cursor-pointer"
                      onClick={() => {
                        coreLayout.setGlobalReports(items[index].order_id);
                        handleClick();
                      }}
                      key={index}
                    >
                      <div className="flex items-center border-b border-gray-100 py-1.5 space-x-2.5">
                        <div className="flex-1">
                          <p
                            className="text-xs font-medium line-clamp-1"
                            title={`${order?.event_description}, ${order.venue}`}
                          >
                            {order?.event_description}, {order.venue}
                          </p>
                        </div>
                        <div
                          className="inline-flex items-center text-xs font-medium"
                          dangerouslySetInnerHTML={{
                            __html: `${SYMBOLS?.[order?.ticket_currency]}${
                              order?.transaction_value
                                ? decimalNumber(order?.transaction_value)
                                : 0
                            }`,
                          }}
                        ></div>
                      </div>
                    </li>
                  ))}
                </ul>
              </InfiniteScroll>
              {error && <p>Error: {error.message}</p>}
            </div>
          ) : reportsLoading ? (
            <div className="py-2 text-center">
              <KTSVG
                className="text-center inline-block fill-violet-500 transition animate-spin"
                path="other_icons/process.svg"
              />
            </div>
          ) : (
            // <KTSVG
            //   className="flex items-center justify-center fill-violet-500 transition animate-spin"
            //   path="other_icons/process.svg"
            // />
            <div className="text-center text-sm13 py-4">
              No records could be found at the moment.
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ReportsCard;
