import { IKTSVG } from "components/ui/IKTSVG";
import {
  decimalNumber,
  getCurrencySymbol,
  ticketTypeRenameForSalesReport,
} from "helpers/Functions";
import moment from "moment";
import { useState } from "react";
import CollapseSVG from "../../../../assets/media/icons/other_icons/Collapse.svg";

const LableInput = ({
  label,
  value,
  halfWidth = false,
  highlight = false,
}: any) => {
  return (
    <div
      className={`singleFOrmBLock ${
        halfWidth ? "md:w-1/4" : "md:w-1/2"
      } w-full px-[.4375rem] `}
    >
      <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-[.9375rem]">
        {label}
      </label>
      <div
        className={`!h-6 px-2.5 py-1 text-xs border-0 truncate ${
          highlight
            ? " outline-1 outline outline-indigo-500 bg-white"
            : "bg-gray-100/50"
        } rounded ${!value && "text-gray-400"}`}
        title={value ? value : "—"}
      >
        {value ? value : "—"}
      </div>
    </div>
  );
};

const CONFIRMATION_INITIAL_STATE = {
  status: false,
  action: false,
};

const TeamOfferDetails = ({
  teamOffer,
  index,
  team,
  orderDetails,
  handleAction,
  actionLoader,
}: {
  teamOffer: any;
  index: any;
  team: any;
  orderDetails: any;
  handleAction: any;
  actionLoader: any;
}) => {
  const [confirmationState, setConfirmationState] = useState(
    CONFIRMATION_INITIAL_STATE
  );
  return (
    <div className="accordionDetail last:mb-0 mb-5">
      <div
        id={`single_accordion${index}`}
        className="group rounded [&:not([data-te-collapse-collapsed])_svg]:fill-white bg-violet-700  [&:not([data-te-collapse-collapsed])]:bg-violet-600 text-white cursor-pointer flex [&:not([data-te-collapse-collapsed])]:rounded-b-none"
        data-te-collapse-init
        data-te-collapse-collapsed
        data-te-target={`#collapseItem${index}`}
        aria-expanded="false"
        aria-controls={`collapseItem${index}`}
      >
        <div className="flex justify-between items-center w-full text-sm13 px-4 capitalize max-md:flex-wrap max-md:py-1">
          <h4 className="max-md:mb-1 font-semibold">
            {team?.name} - {teamOffer?.user_name}
          </h4>
          <h5 className="ml-auto">
            {moment(teamOffer?.created_at).utc().format("DD/MM/YY HH:mm:ss")}
          </h5>
        </div>

        <div
          style={{ width: "2.5rem" }}
          className="ml-auto border-l border-white/20 font-medium group px-4 py-2.5 min-w-[2.5rem] min-h-[2.5rem] flex items-center justify-center"
        >
          {/* <KTSVG
        className="flex items-center justify-center  fill-white hover:fill-indigo-500 transition
shrink-0 rotate-[-180deg] duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 motion-reduce:transition-none pointer-events-none"
        path="standard_icons/arrowDown.svg"
      /> */}
          <IKTSVG
            className="flex items-center justify-center fill-white group-[[data-te-collapse-collapsed]]:rotate-180 !transition-all duration-300"
            path={CollapseSVG}
          />
        </div>
      </div>
      <div
        key={`dropdowns${index}`}
        id={`collapseItem${index}`}
        className="!visible hidden accordion-collapse border-t group "
        data-te-collapse-item
        data-te-collapse-collapsed
        aria-labelledby={`collapseItem${index}`}
      >
        <div className="singleDetail">
          <div className="firstRow p-3.5 border mb-0 rounded-b-none border-b-0 border-t-0 ">
            <div className="text-sm13 font-semibold leading-4 mb-[.9375rem]">
              Ticket details
            </div>
            <div className="formBlock flex flex-wrap -mx-[.4375rem] gap-y-[.9375rem]">
              <LableInput
                label={`Event description`}
                value={orderDetails?.event_description}
              />
              <LableInput label={`Venue`} value={orderDetails?.address} />
              <LableInput
                label={`Event date`}
                value={`${moment(orderDetails?.event_date).format(
                  "ddd, DD MMM YYYY, HH:mm"
                )}`}
              />
              <LableInput
                label={`Seat details`}
                value={`${teamOffer?.ticket_section}${
                  teamOffer?.ticket_section && teamOffer?.row && " - "
                }${teamOffer?.row}${
                  teamOffer?.row && teamOffer?.first_seat && " - "
                }${teamOffer?.first_seat}`}
                highlight={
                  `${orderDetails?.seating_location}${
                    orderDetails?.seating_location && orderDetails?.row
                      ? " - "
                      : ""
                  }${orderDetails?.row}${
                    orderDetails?.first_seat && orderDetails?.row ? " - " : ""
                  }${orderDetails?.first_seat}` !==
                  `${teamOffer?.ticket_section}${
                    teamOffer?.ticket_section && teamOffer?.row && " - "
                  }${teamOffer?.row}${
                    teamOffer?.row && teamOffer?.first_seat && " - "
                  }${teamOffer?.first_seat}`
                }
              />
              <LableInput
                label={`Ticket type`}
                halfWidth={true}
                value={ticketTypeRenameForSalesReport(
                  typeof teamOffer?.ticket_type === "object"
                    ? teamOffer?.ticket_type?.id
                    : teamOffer?.ticket_type,
                  teamOffer?.sub_ticket_type
                )}
                highlight={
                  ticketTypeRenameForSalesReport(
                    teamOffer?.ticket_type,
                    teamOffer?.sub_ticket_type
                  ) !==
                  ticketTypeRenameForSalesReport(
                    orderDetails?.ticket_type,
                    orderDetails?.sub_ticket_type
                  )
                }
              />
              <LableInput
                label={`Quantity`}
                halfWidth={true}
                value={orderDetails?.ticket_quantity}
              />
              <LableInput
                label={`Ticket price`}
                value={`${getCurrencySymbol(
                  teamOffer?.ticket_currency
                )}${decimalNumber(
                  typeof teamOffer?.ticket_price === "object"
                    ? teamOffer?.ticket_price?.value
                    : teamOffer?.ticket_price
                )}`}
                halfWidth={true}
                highlight={
                  teamOffer?.ticket_price !== orderDetails?.ticket_price
                }
              />
              <LableInput
                label={`Order total`}
                value={`${getCurrencySymbol(
                  teamOffer?.ticket_currency
                )}${decimalNumber(teamOffer?.order_total)}`}
                halfWidth={true}
                highlight={
                  Number(teamOffer?.order_total) !=
                  Number(orderDetails?.ticket_price) *
                    Number(orderDetails?.ticket_quantity)
                }
              />
            </div>
          </div>
          <div className="firstRow p-3.5 border  rounded-t-none">
            <div className="text-sm13 font-semibold leading-4 mb-[.9375rem]">
              Benefits & restrictions
            </div>
            {teamOffer?.restrictions?.length > 0 ? (
              <div className="benifitList text-sm flex flex-wrap">
                {teamOffer?.restrictions?.map(
                  (restriction: any, index: any) => {
                    return (
                      <p
                        className="flex w-1/2 text-sm12 text-gray-500 leading-[.9375rem]"
                        key={index}
                      >
                        -
                        {typeof restriction === "object"
                          ? restriction?.name
                          : restriction}
                      </p>
                    );
                  }
                )}
              </div>
            ) : (
              <div className="benifitList text-sm flex flex-wrap">
                <div className="text-sm12 text-gray-500 leading-[.9375rem]">
                  N/A
                </div>
              </div>
            )}
          </div>
          {confirmationState?.status === true ? (
            <div className="actionBtns px-3.5 py-3 border border-t-0 rounded-b flex items-center justify-end gap-2.5">
              <span className="text-sm13">
                Confirm you want to{" "}
                {confirmationState?.action === true ? "accept" : "reject"} this
                offer?
              </span>
              <button
                type="button"
                className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                onClick={() => {
                  setConfirmationState(CONFIRMATION_INITIAL_STATE);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className={` relative z-10   hover:text-white active: bg-green-600 text-white hover:bg-indigo-500 active:bg-indigo-500 transition  rounded text-sm13 px-2.5 py-0.5`}
                onClick={() => {
                  handleAction(
                    confirmationState.action,
                    teamOffer?.id,
                    orderDetails?.order_id
                  );
                }}
              >
                Confirm
              </button>
            </div>
          ) : (
            <div className="actionBtns px-3.5 py-3 border border-t-0 rounded-b flex items-center justify-end gap-2.5">
              <button
                type="button"
                className="border border-gray-300 text-rose-500 hover:border-rose-500  hover:text-white bg-white hover:bg-rose-500 transition font-medium rounded text-sm13 px-[.5625rem] py-[.1875rem] leading-4"
                onClick={() => {
                  setConfirmationState({
                    action: false,
                    status: true,
                  });
                }}
                disabled={actionLoader}
              >
                Reject offer
              </button>
              <button
                onClick={() => {
                  setConfirmationState({
                    action: true,
                    status: true,
                  });
                }}
                disabled={actionLoader}
                type="button"
                className="relative z-10 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-violet-500 transition font-medium rounded text-sm13 px-2.5 py-1 leading-4 

  "
              >
                Accept offer
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamOfferDetails;
