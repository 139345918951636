import { CrossIcon } from "assets/media/icons/other_icons/CrossIcon";
import { LayoutContext } from "components/core/LayoutProvider";
import Checkbox from "components/formComponent/Checkbox";
import TextInput from "components/formComponent/TextInput";
import { useFormik } from "formik";
import { showAlert } from "helpers/ShowAlert";
import { isObject, size } from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import {
  addMenuRecords,
  editMenuRecords,
  getMenuRecords,
} from "../../core/_.request";
import { useCustomize } from "../../hooks/useCustomize";
import { EditRecordForm } from "./EditRecordForm/EditRecordForm";

// VALIDATIONS
const addEditRecordSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  position: Yup.number()
    .typeError("Position must be number")
    .required("Position is required"),
});

const initialValues = { title: "", position: "", status: false };

const handleSUbmit = (mutation: any) => (data: any) => {
  mutation.mutate(data);
};

export const AddNewRecord = () => {
  const globalLayout = useContext(LayoutContext);

  const { isRecordEdit, recordFilter, setIsRecordEdit, setRecords } =
    useCustomize();
  const [categories, setCategories] = useState([
    { type: "", type_id: "", sub_menu: [] },
  ]);

  const closeButtonRef = useRef<any>();

  const mutation = useMutation({
    mutationFn: (data) =>
      isRecordEdit
        ? editMenuRecords({ data, categories })
        : addMenuRecords(data),
    onSuccess: async (res: any) => {
      if (res?.data) {
        if (res?.data?.status) {
          closeButtonRef.current.click();

          await getMenuRecords(recordFilter)().then((res) => {
            if (res?.data?.menu && size(res?.data?.menu) > 0)
              setRecords(res.data.menu);
          });
        }

        let message = res?.data?.message || res?.errors[0];
        showAlert(message, !res?.data?.status, 1500);
      }
    },
  });

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addEditRecordSchema,
    validateOnMount: true,
    onSubmit: handleSUbmit(mutation),
  });

  const allFieldsEmpty = useMemo(
    () => mutation?.isLoading ?? false,
    [mutation?.isLoading]
  );

  useEffect(() => {
    if (isRecordEdit) {
      const recordEdit = isRecordEdit as Record<string, any>;
      if (isObject(isRecordEdit))
        Object.keys(isRecordEdit).forEach((key) => {
          let value = recordEdit[key];
          formik.setFieldValue(key, value);
        });

      if (isRecordEdit?.sub_menu) {
        let subMenu = isRecordEdit?.sub_menu.map((menu: any) => ({
          name: menu?.title,
          menuId: menu?.id,
          type: menu?.type,
          id: menu?.type_id,
          isEditable: false,
          sub_menu: menu?.sub_menu
            ? menu?.sub_menu.map((subItem: any) => ({
                name: subItem?.title,
                id: subItem?.type_id,
                menuId: subItem?.id,
                type: subItem?.type,
                isEditable: false,
                isToggle: false,
                sub_menu: subItem?.sub_menu
                  ? subItem?.sub_menu.map((nestItem: any) => ({
                      name: nestItem?.title,
                      id: nestItem?.type_id,
                      menuId: nestItem?.id,
                      type: nestItem?.type,
                      isEditable: false,
                    }))
                  : [],
              }))
            : [],
        }));
        setCategories(subMenu);
      }
    }
    return () => {
      setCategories([{ type: "", type_id: "", sub_menu: [] }]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecordEdit]);

  return (
    <div
      data-te-modal-init
      className={`offcanvas add_teammember fill_canvas offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium  w-full upload-ticket-popup z-40 ${
        globalLayout?.asideMenuOpen && "lg2:max-w-[calc(100%_-_13.8125rem)]"
      } md:max-w-[calc(100%_-_3.75rem)]`}
      tabIndex={-1}
      id="add_new_category"
      aria-labelledby="AddCategoryLabel"
    >
      {/* FORMIk */}
      <form
        className={`h-full ${
          isRecordEdit
            ? "w-full md:w-[calc(100% - 3.75rem)] md:pl-[3.75rem]"
            : "md:max-w-[43.125rem] ml-auto"
        }  `}
      >
        <div className="  bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              {isRecordEdit ? "Edit" : "Add new"} record
            </h5>
            <div className="buttons flex flex-wrap">
              <button
                type="button"
                className="flex items-center justify-center w-9 border-l box-content group"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                ref={closeButtonRef}
                onClick={() => {
                  formik.resetForm();
                  setIsRecordEdit(null);
                }}
              >
                <div className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition">
                  <CrossIcon className="w-2.5 h-2.5" />
                </div>
              </button>
            </div>
          </div>

          {/* MAIN FORM */}
          <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full ">
            <div
              className={
                isRecordEdit
                  ? "inner_body flex flex-wrap max-lg2:flex-col  h-full"
                  : "p-5"
              }
            >
              <div
                className={
                  isRecordEdit
                    ? "left_body border-r lg2:w-1/2 w-full xl:h-full scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-auto p-5 flex flex-col"
                    : ""
                }
              >
                <div className="address_fields flex flex-wrap -mx-2.5 whiteFormFields ">
                  <div className="fieldset w-full flex flex-wrap new-inp">
                    {/* Name */}
                    <div className="w-full  mb-5 px-2.5 relative new-inp">
                      <TextInput
                        name="title"
                        type="text"
                        label="Title"
                        id="title"
                        required={false}
                        value={formik?.values?.title}
                        errorClass={
                          formik.touched.title &&
                          formik.errors.title &&
                          "border-rose-500"
                        }
                        handleOnChange={(data: any) => {
                          formik?.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          );
                        }}
                        handleClearValue={() =>
                          formik.setFieldValue("title", "")
                        }
                      />
                      {formik.touched?.title && formik.errors?.title && (
                        <div className="text-xs text-rose-500 error-msg">
                          {formik.errors.title as string}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="fieldset w-full flex flex-wrap new-inp ">
                    <div className="w-full  mb-5 px-2.5 relative">
                      <TextInput
                        name="position"
                        type="number"
                        label="Position"
                        id="position"
                        required={false}
                        value={formik?.values?.position}
                        errorClass={
                          formik.touched?.position &&
                          formik.errors?.position &&
                          "border-rose-500"
                        }
                        handleOnChange={(data: any) => {
                          formik?.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          );
                        }}
                        handleClearValue={() =>
                          formik.setFieldValue("position", "")
                        }
                      />
                      {formik.touched?.position && formik.errors?.position && (
                        <div className="text-xs text-rose-500 error-msg">
                          {formik.errors.position as string}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="fieldset w-full flex flex-wrap">
                    <div className="w-full sm:w-1/4 px-2.5 relative">
                      <Checkbox
                        label="Status"
                        id="status"
                        name="status"
                        labelclass="text-gray-500"
                        checked={formik?.values?.status}
                        tooltip={false}
                        handleOnChange={(data: any) =>
                          formik?.setFieldValue(
                            data?.target?.name,
                            data?.target?.checked
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {isRecordEdit && (
                <EditRecordForm
                  categories={categories}
                  setCategories={setCategories}
                />
              )}
            </div>
          </div>

          {/* BUTTONS */}
          <div className=" offcanvas-footer bottom-6 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
            <div className="l_btn gap-2.5 flex flex-wrap items-center">
              <button
                type="button"
                className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                data-bs-dismiss="offcanvas"
                onClick={() => {
                  formik.resetForm();
                  setIsRecordEdit(null);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={allFieldsEmpty}
                onClick={(e: any) => {
                  e.preventDefault();
                  formik.submitForm();
                }}
                className={`relative z-10 border border-green-600 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 ${
                  allFieldsEmpty &&
                  "pointer-events-none !bg-gray-300 !border-gray-300"
                }`}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
