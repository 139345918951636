import { AddIcon } from "assets/media/icons/other_icons/AddIcon";
import clsx from "clsx";

const ViewpopupFooter = ({
  formik,
  isShowForm,
  handleClosePopup,
  handleAddReferral,
  handleSubmit,
}: any) => {
  const { isDelete, updatedRecords } = formik.values;

  return (
    <div className="w-full flex items-center justify-between px-5 py-3 bg-white z-10 border-t min-h-[3.1875rem]">
      <div className="flex  gap-2">
        <button
          type="button"
          className={clsx(
            "text-white hover:text-white bg-violet-500 hover:bg-indigo-500 group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5",
            isDelete !== undefined && "hidden pointer-events-none",
            isShowForm && "pointer-events-none"
          )}
          onClick={handleAddReferral}
          disabled={isDelete !== undefined ? true : false}
        >
          <AddIcon
            className={clsx(
              "w-3.5 h-3 flex items-center justify-center  transition mr-2 fill-white"
            )}
          />
          Add
        </button>
      </div>

      <div className="btns flex flex-wrap items-center tx-pay-module gap-3.5">
        {isDelete !== undefined ? (
          <span className="text-sm13 font-medium mr-2 block">
            Delete selected row?
          </span>
        ) : updatedRecords?.size > 0 ? (
          <span className="text-sm13 font-medium mr-2 block">
            You have modified {updatedRecords?.size} {" "}
            {updatedRecords?.size > 1 ? "rows" : "row"}
          </span>
        ) : null}

        {/* Prioritize isDelete, otherwise check for updatedRecords */}
        {isDelete !== undefined || updatedRecords?.size > 0 ? (
          <>
            <button
              type="button"
              className="btn flex-1 text-sm13 leading-[1.375rem] font-medium px-2.5 !py-0"
              onClick={handleClosePopup}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={clsx(
                "text-sm13 font-medium leading-[1.5rem] px-2.5 !text-white rounded border-hidden hover:border-hidden flex",
                isDelete !== undefined
                  ? "bg-rose-500 hover:bg-indigo-500"
                  : "bg-green-600 hover:bg-indigo-500"
              )}
              onClick={() => handleSubmit()}
            >
              {isDelete !== undefined ? (
                <>
                  <i className="fas fa-solid fa-trash mr-1.5 pt-[.3125rem]"></i>
                  Delete
                </>
              ) : (
                "Update"
              )}
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ViewpopupFooter;
