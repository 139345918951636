import { LayoutContext } from "components/core/LayoutProvider";
import { getAuthDataFromStorage } from "helpers/AssetHelpers";
import _ from "lodash";
import { useContext } from "react";
import PageTitle from "../../components/core/PageTitle";
import { grettingMessage } from "../../helpers/Functions";
import Dashboard from "./DashBoard";
import { DashboardProvider } from "./core/DashboardProvider";

const DashboardWrapper = () => {
  var authDataString: any = getAuthDataFromStorage();
  // Cookies.get("authData") as any;
  authDataString = authDataString && JSON.parse(authDataString);
  //Gretting Message
  let greet = grettingMessage();

  const layout = useContext(LayoutContext);

  const userFirstName = authDataString?.name as string;
  // const firstName = authDataString?.firstname as string;

  // const userFirstName = layout.allowedAccess?.account_info?.firstname as string;
  const firstName = layout.allowedAccess?.account_info?.firstname as string;

  return (
    <>
      <DashboardProvider>
        <PageTitle
          title={`${greet}${firstName ? "," : ""} ${
            firstName ? _.capitalize(firstName) : ""
          }`}
        />
        <Dashboard />
      </DashboardProvider>
    </>
  );
};

export default DashboardWrapper;
