import axios from "axios";
import Cookies from "js-cookie";

const BASEURL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASEURL;
axios.defaults.headers.Accept = "application/json";
axios.defaults.headers.ContentType = "application/json";
axios.defaults.headers.withCredentials = true;


axios.interceptors.request.use(
  (req: any) => {
    let cookieTtoken = Cookies.get('authTokens') as string
    if (cookieTtoken) {
      cookieTtoken = JSON.parse(cookieTtoken)
      // @ts-ignore
      req.headers["Authorization"] = `Bearer ${cookieTtoken}`;
    }
    return req;
  },
  (err: any) => Promise.reject(err)
);

axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (err: any) => {
  
    if (err.response?.status === 401) {
        Cookies.remove("authTokens");
        Cookies.remove("authData");
      localStorage.clear();
        window.location.href = "/login";
    }
    return Promise.reject(err);
  }
);

export default axios;
