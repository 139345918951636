import { IKTSVG } from "components/ui/IKTSVG";
import { useState } from "react";
import fileSVG from "../../../../assets/media/icons/other_icons/file.svg";
import processSVG from "../../../../assets/media/icons/other_icons/pure-process.svg";

interface CSVExportProps {
  fetchData: () => Promise<any>; // Function to fetch data from any API
  fileName: string; // Optional file name (default: reports.csv)
}

const CSVExport: React.FC<CSVExportProps> = ({
  fetchData,
  fileName = "test.csv",
}) => {
  const [loader, setLoader] = useState<boolean>(false);

  const downloadFile = ({ data, fileName, fileType }: any) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    a.remove();
    setLoader(false);
  };

  const exportToCsv = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoader(true);

    try {
      const response = await fetchData();
      downloadFile({
        data: response?.data.replace(/\\n/g, "\n"),
        fileName,
        fileType: "text/csv",
      });
    } catch (error) {
      console.error("CSV Export Error:", error);
      setLoader(false);
    }
  };

  return (
    <div>
      <button
        type="button"
        className={`${
          loader && "pointer-events-none bg-gray-100 hover:bg-gray-100"
        } group inline-flex items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5`}
        onClick={exportToCsv}
        disabled={loader}
      >
        {loader ? (
          <IKTSVG
            className="flex items-center justify-center fill-violet-500 transition animate-spin group-hover:fill-white mr-1"
            path={processSVG}
          />
        ) : (
          <IKTSVG
            className="min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white"
            path={fileSVG}
          />
        )}
        Export to CSV
      </button>
    </div>
  );
};

export default CSVExport;
