/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import newUploadSVGNew from "../../../assets/media/icons/other_icons/Upload_new.svg";
import backToTOp from "../../../assets/media/icons/other_icons/backtotop.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import TablePopup from "../../../components/tableComponent/TablePopup";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { SalesContext } from "../core/SalesProvider";
import CSVExport from "../widget/CSVExport";
import { addCommaInNumber } from "helpers/Functions";

const Footer = () => {
  const layout = useContext(SalesContext);
  const btnRef: any = useRef(null);
  const [tableAlert, setTableAlert] = useState(false);
  const [tableAlertMsg, setTableAlertMsg] = useState("");
  const [tableAlertConfirm, setTableAlertConfirm] = useState(false);
  const [id, setId] = useState<any>();
  const coreLayout = useContext(LayoutContext);
  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;

  let tabValue = layout.tabValue;
  const isSelectAll = layout.isSelectAll;
  const [listingCount, setListingCount] = useState<any>();

  const selectedEvents = layout.orders.filter((event: any) => event.selected);
  const isAnyUnlock = layout.orders.some((event: any) => !event.isLock);

  // Step 2: Create an object to count the occurrences of each event_id
  let eventCount: any = {};
  selectedEvents.forEach((event: any) => {
    const eventId: any = event.event_id;
    eventCount[eventId] = (eventCount[eventId] || 0) + 1;
  });

  // Select All Events
  const handleSelectAll = (data: any, deselect: boolean) => {
    layout.setOrders((orders: any) => {
      return orders.map((order: any) => {
        if (order?.is_txtrade_pending_order) {
          return {
            ...order,
            selected: false,
          };
        }
        if (order?.is_legacy_order === "false" && isGodAdmin === false) {
          layout.setIsSelectAll(data.target.checked);
          return {
            ...order,
            selected:
              order?.is_legacy_order === "false" && deselect
                ? false
                : data.target.checked
                ? true
                : false,
          };
        } else if (order?.is_legacy_order === "false" && isGodAdmin) {
          layout.setIsSelectAll(data.target.checked);
          if (order?.isLock === false) {
            return {
              ...order,
              selected: data.target.checked,
            };
          } else {
            return {
              ...order,
              selected: false,
            };
          }
        } else {
          return { ...order };
        }
      });
    });
  };

  const handleFulfill = () => {
    let isChecked = layout.orders?.some((order: any) => order.selected);
    let selectedData = layout.orders?.filter((order: any) => order.selected);
    if (isChecked) {
      layout.setTicketUploadData(selectedData);
    }
    if (isChecked === false) {
      setId("");
      setTableAlert(true);
      setTableAlertMsg("Select at least one row");
      setTableAlertConfirm(false);
    }
  };

  useEffect(() => {
    if (layout.orders) {
      const orders: Array<Event> = layout.orders;

      let countSelected = 0;

      // eslint-disable-next-line array-callback-return
      orders.map((order: any) => {
        if (order?.selected === true) {
          countSelected++;
        }
      });

      setListingCount(countSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout.orders]);

  // useEffect(() => {
  //   let backtop = document.getElementById("backtotop");
  //   if (window.scrollY > 50) {
  //     backtop?.classList.remove("hidden");
  //   } else {
  //     backtop?.classList.add("hidden");
  //   }
  // });
  // function scrollToTop(): any {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth", // This smooths the scrolling behavior
  //   });
  // }

  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to show/hide the button based on scroll position
  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;

    // Show the button when the user scrolls down 400px
    if (scrollPosition > 250) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {tableAlert && (
        <TablePopup
          btnRef={btnRef}
          isConfirm={tableAlertConfirm}
          message={tableAlertMsg}
          cancelBtn={(data: any) => setTableAlert(data)}
          confirmBtn={() => {
            layout.setConfirmRowDelete(true);
            setTimeout(() => {
              layout.setConfirmRowDelete(false);
            }, 500);
          }}
        />
      )}
      <footer className="fixed max-md:w-full md:sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-1 bg-white shadow-3xl z-20 min-h-[3.125rem]">
        <button
          type="button"
          className={`bg-violet-550 hover:bg-indigo-500 rounded-l w-[1.875rem] h-[1.875rem] items-center justify-center flex fixed bottom-[2.75rem] md:bottom-[4.0625rem] right-0 transition duration-300 ${
            isVisible ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
          id="backtotop"
          onClick={scrollToTop}
        >
          <IKTSVG path={backToTOp} svgClassName="w-[.9375rem] h-[.9375rem]" />
        </button>

        {layout?.orders?.length > 0 && (
          <div className={`flex flex-wrap w-full`}>
            <div className="flex flex-nowrap items-center whitespace-nowrap overflow-auto gap-2.5 my-2">
              {tabValue === "to_be_fulfilled" && (
                <>
                  <div
                    className={`
                    ${
                      (isGodAdmin && isAnyUnlock) || !isGodAdmin
                        ? ""
                        : "pointer-events-none"
                    }
                    inline-flex items-center text-violet-800 transition font-medium rounded max-md:!pl-0 px-2.5 py-0.5`}
                  >
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      checked={isSelectAll}
                      // disabled={!isSelectAll ? false : true}
                      readOnly
                      className={`w-3 h-3 text-violet-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0   dark:bg-gray-700 dark:border-gray-600`}
                      onChange={(data) => handleSelectAll(data, false)}
                    />
                    <label
                      htmlFor="default-checkbox"
                      className={`ml-2 text-sm13 font-medium  dark:text-gray-300 text-gray-900`}
                    >
                      Select all
                    </label>
                  </div>

                  {/* <button
                    type="button"
                    className={`${
                      listingCount === 0
                        ? "pointer-events-none text-gray-400"
                        : "text-violet-800"
                    } inline-flex group items-center  hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5`}
                    onClick={(data) => handleSelectAll(data, true)}
                  >
                    <KTSVG
                      className={` min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center group-hover:fill-white transition ${
                        listingCount < 1 ? "fill-gray-400" : "fill-violet-500"
                      }`}
                      path="other_icons/close_square.svg"
                    />
                    Deselect
                  </button> */}
                </>
              )}

              {tabValue === "to_be_fulfilled" && (
                <button
                  type="button"
                  className={`${
                    listingCount < 1
                      ? "pointer-events-none text-gray-400"
                      : "text-violet-800"
                  } group hidden md:inline-flex group items-center  hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false`}
                  onClick={() => handleFulfill()}
                >
                  <IKTSVG
                    className={`${
                      listingCount < 1 && "!fill-gray-400"
                    } min-w-[.875rem] max-w-[.875rem] mr-1.5 flex items-center justify-center fill-violet-500 group-hover:fill-white`}
                    path={newUploadSVGNew}
                    svgClassName="w-3.5 h-3"
                  />
                  Deliver
                </button>
              )}

              {/* <button
            type="button"
            className="group inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
            data-bs-toggle="offcanvas"
            data-bs-target="#detailCanvas"
            aria-controls="detailCanvas"
          >
            <KTSVG
              className="min-w-[0.75rem] max-w-[0.75rem] mr-1 flex items-center justify-center fill-violet-500 group-hover:fill-white"
              path="other_icons/mail.svg"
            />
            Request details
          </button> */}

              {/* Static design for fulfill */}
              {/* <button
            type="button"
            className="group inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
            data-bs-toggle="offcanvas"
            data-bs-target="#detailCanvas123"
            aria-controls="detailCanvas123"
          >
            <KTSVG
              className="min-w-[0.75rem] max-w-[0.75rem] mr-1 flex items-center justify-center fill-violet-500 group-hover:fill-white"
              path="other_icons/mail.svg"
            />
            Request details
          </button> */}
              <CSVExport />
              {listingCount > 0 && (
                <div>
                  <p className="font-medium text-sm13 ml-2.5">
                    {addCommaInNumber(listingCount)} selected in{" "}
                    {_.size(eventCount) === 1
                      ? `${addCommaInNumber(_.size(eventCount))} event`
                      : `${addCommaInNumber(_.size(eventCount))} events`}
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </footer>
    </>
  );
};

export default Footer;
