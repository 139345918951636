import DatePickerField from "components/formComponent/DatePickerField";
import MultiDropDownField from "components/formComponent/MultiDropDownField";
import { handleSelectDeselectAll } from "helpers/Functions";
import { size } from "lodash";
import moment from "moment";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import { useEffect, useRef, useState } from "react";
// import "swiper/css";
// import "swiper/css/scrollbar";
import AngleRight from "../../../assets/media/icons/standard_icons/angle_right.svg";
import Card from "../../../components/ui/Card";
import DashBoardShimmerLoader from "./DashBoardShimmerLoader";
import OnsalesCard from "./OnsalesCard";

import useSwiperWheelNavigation from "hooks/useSwiperWheelNavigation";
import SVG from "react-inlinesvg";
import { Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperTypes } from "swiper/types";

const Onsales = ({
  onSalesFilters,
  setOnSalesFilters,
  onSalesLoading,
  onsalesData,
  onSalesCustomLoading,
  setOnSalesCustomLoading,
  onsalesListData,
  scrollToTop,
  categoryListData,
  setOnsalesListData,
  initialSlide,
}: any) => {
  const [performerInput, setPerformerInput] = useState("");
  const performerSelectedRef: any = useRef();
  const categorySelectRef: any = useRef();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [performersOptions, setPerformersOptions] = useState([]);

  const [total, setTotal] = useState(onsalesData?.data?.total || 0);
  const [startingSoonEvents, setStartingSoonEvents] = useState(
    onsalesData?.data?.starting_soon_event || 0
  );
  const swiperRef = useRef<any>(null);

  const swiperContainer = swiperRef.current?.swiper?.el;
  useSwiperWheelNavigation(
    swiperRef,
    swiperContainer,
    "swiper-button-next-on-sales-orders",
    "swiper-button-prev-on-sales-orders"
  );

  useEffect(() => {
    if (onsalesData?.data?.event_category) {
      setCategoryOptions(onsalesData?.data?.event_category);
    }
    if (onsalesData?.data?.event_performers) {
      setPerformersOptions(onsalesData?.data?.event_performers);
    }
  }, [onsalesData]);

  useEffect(() => {
    if (onsalesData?.meta) {
      setTotal(onsalesData?.meta?.total);
    }

    if (onsalesData?.data) {
      setStartingSoonEvents(onsalesData?.data?.starting_soon_event);
    }
  }, [onsalesData?.data?.total, onsalesData?.data?.starting_soon_event]);

  const handleOnChange = (data: any) => {
    // setCustomLoading(true);
    setOnSalesCustomLoading(true);
    setOnSalesFilters((currentValue: any) => {
      return {
        ...currentValue,
        [data?.target?.name]: data?.target?.value,
        page: 1,
      };
    });
    // layout.setIsSelectAll(false);
  };

  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any = null
  ) => {
    if (performerSelectedRef && performerSelectedRef?.current) {
      performerSelectedRef.current.blur();
    }
    if (categorySelectRef && categorySelectRef?.current) {
      categorySelectRef.current.blur();
    }
    setOnSalesCustomLoading(true);

    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      setOnSalesFilters
    );
  };

  const FiltersComponent = (
    <>
      <div className="h-TwentyFour min-w-[7rem]">
        <MultiDropDownField
          name="category_ids"
          classNamePrefix={"form_multiDropDown"}
          placeholder={
            size(onSalesFilters?.category_ids) > 0
              ? "Category"
              : "All categories"
          }
          value={onSalesFilters?.category_ids}
          handleOnChange={(data: any) => handleOnChange(data)}
          options={categoryOptions}
          isClearable={true}
          //isMenuPortalTarget={shimmerLoader ? false : true}
          selectedRef={categorySelectRef}
          isMultiSelection={true}
          handleOnSelection={(data: any, visibleOptions: any) => {
            handleOnSelection(
              data,
              "category_ids",
              categoryOptions,
              visibleOptions
            );
          }}
          isNone={false}
        />
      </div>
      <div className={`h-TwentyFour min-w-[7.25rem]`}>
        <div className={` rounded`}>
          <MultiDropDownField
            name="performer_ids"
            classNamePrefix={"form_multiDropDown"}
            placeholder={
              size(onSalesFilters?.performer_ids) > 0
                ? "Performer"
                : "All performers"
            }
            value={onSalesFilters?.performer_ids}
            handleOnChange={(data: any) => handleOnChange(data)}
            options={performersOptions}
            isClearable={true}
            //isMenuPortalTarget={shimmerLoader ? false : true}
            selectedRef={performerSelectedRef}
            isMultiSelection={true}
            handleOnSelection={(data: any, visibleOptions: any) => {
              handleOnSelection(
                data,
                "performer_ids",
                performersOptions,
                visibleOptions
              );
            }}
            isNone={false}
          />
        </div>
      </div>
      <div
        data-tooltip-id={"on-sales-event_date_range"}
        className="h-TwentyFour"
      >
        <DatePickerField
          // className="block overflow-ellipsis overflow-hidden w-auto py-1 text-xs font-medium text-gray-900 border-1.5 border-gray-300 rounded focus:ring-indigo-300 focus:border-indigo-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          height={`!h-6 !max-w-[6.4375rem]`}
          // height={"!max-w-[8.75rem] "}
          name={"event_date_range"}
          placeholder={"Event date"}
          value={onSalesFilters?.event_date_range}
          handleOnChange={(data: any) => handleOnChange(data)}
          isDateRange={true}
          format="dd/MM/yyyy"
        />
      </div>

      {onSalesFilters?.event_date_range && (
        <TooltipPortal
          id={"on-sales-event_date_range"}
          content={`${moment(
            onSalesFilters?.event_date_range?.startDate
          )?.format("DD/MM/YYYY")} - ${moment(
            onSalesFilters?.event_date_range?.endDate
          )?.format("DD/MM/YYYY")}`}
          className="text-center  !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-100 max-w-[9rem] cursor-default "
        />
      )}
    </>
  );

  return (
    <>
      <div className=" w-full md:px-2 md:mb-4 mb-2 orderOfferWidget">
        <Card
          title="On-Sales"
          isLoading={onSalesCustomLoading}
          Filters={FiltersComponent}
          threeDot={false}
        >
          {onSalesCustomLoading ? (
            <DashBoardShimmerLoader
              mainLoading={true}
              headerShimmer={false}
              isLoading={onSalesCustomLoading}
              width={true}
            />
          ) : (
            <>
              {onsalesListData?.length > 0 && (
                <div className="divide-gray-200 divide-y-2 ">
                  <div className="flex flex-nowrap p-5 -mx-2.5 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                    <div className="px-2.5 max-md:min-w-[50%] md:w-1/2 lg2:w-1/4 w-full">
                      <div className=" outline outline-1 outline-gray-200 bg-white rounded px-2.5 py-[.5625rem]">
                        <div className="flex flex-wrap sm:flex-nowrap justify-between">
                          <h3 className="text-sm18 leading-[1.3125rem] font-medium">
                            {total}
                          </h3>
                        </div>

                        <p className="flex items-center text-xs w-full sm:w-auto font-medium text-gray-500 pt-1.5 leading-[.9375rem] pr-2">
                          <span className="flex-none sm:flex-1 sm:w-full items-center mr-2">
                            On-sales
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="px-2.5 max-md:min-w-[50%] md:w-1/2 lg2:w-1/4 w-full">
                      <div className=" outline outline-1 outline-gray-200 bg-white rounded px-2.5 py-[.5625rem]">
                        <div className="flex flex-wrap sm:flex-nowrap justify-between">
                          <h3 className="text-sm18 leading-[1.3125rem] font-medium">
                            {startingSoonEvents}
                          </h3>
                        </div>

                        <p className="flex items-center text-xs w-full sm:w-auto font-medium text-gray-500 pt-1.5 leading-[.9375rem] pr-2">
                          <span className="flex-none sm:flex-1 sm:w-full items-center mr-2">
                            Starting soon
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <Swiper
                ref={swiperRef}
                key={initialSlide}
                className="px-2.5 mySwiper pb-5"
                modules={[Navigation, Scrollbar]}
                navigation={{
                  prevEl: ".swiper-button-prev-on-sales-orders",
                  nextEl: ".swiper-button-next-on-sales-orders",
                }}
                initialSlide={initialSlide}
                slidesPerView={1}
                autoHeight={false}
                // simulateTouch={false}
                // scrollbar={{ draggable: true }}
                scrollbar={{
                  hide: false,
                  draggable: true,
                }}
                onSlideChange={(swiper: SwiperTypes) => {
                  if (
                    //
                    swiper.activeIndex >= onsalesListData.length - 3 &&
                    onsalesData?.meta?.current_page <
                      onsalesData?.meta?.last_page
                  ) {
                    setOnSalesFilters((prev: any) => {
                      return {
                        ...prev,
                        page: prev?.page + 1,
                      };
                    });
                  }
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  1200: {
                    slidesPerView: 4,
                  },
                }}
              >
                {onsalesListData?.length > 0 ? (
                  onsalesListData?.map((item: any, index: any) => {
                    return (
                      <SwiperSlide key={index} className="h-auto">
                        <OnsalesCard
                          performer={item}
                          scrollToTop={scrollToTop}
                        />
                      </SwiperSlide>
                    );
                  })
                ) : (
                  <div className="w-full h-full px-2.5 flex items-center justify-center my-5 min-h-[2.5rem]">
                    <div className="border rounded text-center text-sm13 font-medium p-[.5625rem] w-full">
                      You currently have no on-sales available
                    </div>
                  </div>
                )}
                {(onSalesLoading ||
                  onsalesData?.meta?.current_page <
                    onsalesData?.meta?.last_page) && (
                  <>
                    <SwiperSlide
                      key={onsalesListData?.length + 1}
                      className="h-auto"
                    >
                      <div className="singleOrder  h-full px-2.5  ">
                        <div className="shimmer-effect h-full w-full"></div>
                      </div>
                    </SwiperSlide>
                    {/* <SwiperSlide
                      key={onsalesListData?.length + 2}
                      className="h-auto"
                    >
                      <div className="singleOrder  h-full px-2.5  ">
                        <div className="shimmer-effect h-full w-full"></div>
                      </div>
                    </SwiperSlide> */}
                  </>
                )}

                <div className=" flex justify-between absolute top-1/2 -translate-y-1/2 left-0 right-0 z-10 swiper-pagination pointer-events-none">
                  {Array.from({ length: 2 }, (_v, i) => (
                    <button
                      className={`flex items-center justify-center w-6 h-6 ${
                        i === 0
                          ? "rounded-r swiper-button-prev-on-sales-orders"
                          : "rounded-l swiper-button-next-on-sales-orders"
                      } bg-white shadow-op1 group hover:bg-indigo-500 disabled:opacity-0 pointer-events-auto`}
                      key={i}
                    >
                      <SVG
                        src={AngleRight}
                        className={`h-[0.5625rem] w-1.5 ${
                          i === 0 ? "rotate-180" : ""
                        } group-hover:fill-white group-disabled:fill-gray-400 fill-violet-500`}
                      />
                    </button>
                  ))}
                </div>
              </Swiper>
            </>
          )}
        </Card>
      </div>

      {/* {layout.selectedOrderOutOffer && <OnsalesDetailsModal />} */}
    </>
  );
};

export default Onsales;
