import clsx from "clsx";
import PriceFieldWithComma from "components/formComponent/PriceFieldWithComma";
import { checkPPEExistForTableLevelNEw } from "helpers/AssetHelpers";
import Messages from "helpers/Messages";
import _, { find, isArray, size } from "lodash";
import React, {
  Fragment,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";
import Warning1 from "../../../../assets/media/icons/standard_icons/Warning1";
import FormDatePickerField from "../../../../components/formComponent/FormDatePickerField";
import PriceField from "../../../../components/formComponent/PriceField";
import NoJSXComponent from "../../../../components/noJSX/NoJSXComponent";
import TableDropdown from "../../../../components/tableComponent/TableDropdown";
import TableMultiDropdown from "../../../../components/tableComponent/TableMultiDropdown";
import ComponentSVG from "../../../../components/ui/typography/ComponentSVG";
import {
  crc32,
  decimalNumber,
  firstLetterCapital,
  handleKeyDown,
  isValidPriceFieldNumber,
  priceCalculation,
  removeSelectAll,
  resetPPEFields,
  uniqueByNameWithPriority,
  updatePPEPartners,
} from "../../../../helpers/Functions";
import { deliveryTypeOrders } from "../../../../helpers/const";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import InventoryNoJSX from "../../core/InventoryNoJSX";
import { InventoryContext } from "../../core/InventoryProvider";
import {
  calculateAvgPriceForProceedValue,
  categoryExist,
  priceFieldInputKeyDown,
  secureStateWhileTabbing,
  visibilityMeterCalculationForInventory,
} from "../../core/_functions";
import TicketTypeExist from "../nonJSXComponent/TicketTypeExist";
import ActionButtonsWrapper from "./desktopViewComponent/ActionButtonsWrapper";
import EventRecordStatus from "./desktopViewComponent/EventRecordStatus";
import ListingCheckbox from "./desktopViewComponent/ListingCheckbox";
import LockComponent from "./desktopViewComponent/LockComponent";
import PPPWrapper from "./desktopViewComponent/PPPWrapper";

const EventField = memo(
  ({
    event,
    eIndex,
    tempEventRecord,
    list,
    setList,
    rIndex,
    firstSelectedIndex,
    matchIndexView,
    getColumnItems,
    subTicketType,
    handleEticket,
    setIsOpenTicketPopUp,
    tableRefSide,
    setEditData,
    handleListingAction,
    editData,
    updateVisibility,
    setIsOpenListingQualityPopup,
  }: any) => {
    let myIndex = firstSelectedIndex;
    const layout = useContext(InventoryContext);
    const [eventRecord, setEventRecord] = useState(tempEventRecord);
    const [updateChecker, setUpdateChecker] = useState<any>();
    const [priceFiledFocused, setPriceFieldFocused] = useState<any>();
    const { selectedDeliveryTypes, paperTypeExist } = TicketTypeExist(event);
    const { isGodAdmin, conversation_rate, price_suggestion_factor } =
      NoJSXComponent();
    const {
      isDisabled,
      isEventRecordError,
      isEventRecordBEError,
      isEventRecordHoldTickets,
      isALLTicketUploadedWithPublish,
      ticketTypeValue,
      categoryValue,
      sectionValue,
      splitTypeValue,
      benefitsValue,
      restrictionValue,
      splitCondition,
    } = InventoryNoJSX({
      event,
      eventRecord,
    });

    let allErrorsProcessingAndRestrictions =
      isEventRecordError ||
      isEventRecordHoldTickets ||
      isALLTicketUploadedWithPublish ||
      eventRecord?.processing ||
      isEventRecordBEError;

    let isLoaderActive = list?.reduce((count: any, event: any) => {
      const selectedCount = event?.eventRecords?.filter(
        (record: any) => record?.loader
      );
      return count + (selectedCount ? selectedCount.length : 0);
    }, 0);

    useEffect(() => {
      if (isLoaderActive === 0) {
        layout?.setUpdateAllLoader(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaderActive]);

    const tempEventRecordMemo = useMemo(() => {
      return tempEventRecord;
    }, [tempEventRecord]);

    useEffect(() => {
      if (layout.refreshList || tempEventRecordMemo) {
        setEventRecord(tempEventRecordMemo);
      }
    }, [tempEventRecordMemo, layout.refreshList]);

    const MemoizedHandleTableFieldOld = useMemo(() => {
      const MemoizedHandleTableField = (data: any) => {
        let tempEvents = list;
        // If table is not edit-able

        if (layout.isTableEditable.length === 0) {
          // REPLACIBLE RECORD
          if (
            !tempEvents[eIndex]["eventRecords"][rIndex]["replacible"] ||
            _.size(tempEvents[eIndex]["eventRecords"][rIndex]["replacible"]) ===
              0
          ) {
            tempEvents[eIndex]["eventRecords"][rIndex]["replacible"] = {
              ...tempEvents[eIndex]["eventRecords"][rIndex],
            };
          }

          if (data?.parentName === "ppePartners") {
            if (data.target.name === "sell_price") {
              tempEvents[eIndex]["eventRecords"][rIndex]["sell_price"] =
                data?.target?.value;
            }
            tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"] =
              updatePPEPartners(
                tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
                data?.id,
                data?.target?.value,
                "ppePartners",
                tempEvents[eIndex]["eventRecords"][rIndex]["sell_price"]
              );

            tempEvents[eIndex]["eventRecords"][rIndex][
              "ticket_exchange_prices"
            ] = updatePPEPartners(
              tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
              data?.id,
              data?.target?.value,
              "ticket_exchange_prices",
              tempEvents[eIndex]["eventRecords"][rIndex]["sell_price"]
            );
          } else {
            tempEvents[eIndex]["eventRecords"][rIndex][data.target.name] =
              data.target.value !== undefined &&
              Array.isArray(data.target.value)
                ? data.target.value
                : typeof data.target.value === "object"
                ? data.target.value
                : typeof data.target.value === "string" &&
                  data.target.type !== "checkbox"
                ? data.target.value
                : data.target.type === "checkbox"
                ? data.target.checked
                : data.target.value;

            if (data.target.name === "sell_price") {
              if (
                tempEvents[eIndex]["eventRecords"][rIndex][
                  "price_per_exchange"
                ] === 1 ||
                tempEvents[eIndex]["eventRecords"][rIndex][
                  "price_per_exchange"
                ] === true
              ) {
                tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"] =
                  updatePPEPartners(
                    tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
                    null,
                    null,
                    "ppePartners",
                    data.target.value
                  );

                tempEvents[eIndex]["eventRecords"][rIndex][
                  "ticket_exchange_prices"
                ] = updatePPEPartners(
                  tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
                  null,
                  null,
                  "ticket_exchange_prices",
                  data.target.value
                );
              } else {
                tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"] =
                  resetPPEFields(
                    tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
                    data.target.value,
                    "ppePartners"
                  );
                tempEvents[eIndex]["eventRecords"][rIndex][
                  "ticket_exchange_prices"
                ] = resetPPEFields(
                  tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
                  data.target.value,
                  "ticket_exchange_prices"
                );
              }
            }
            if (data?.target?.name === "price_per_exchange") {
              tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"] =
                resetPPEFields(
                  tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
                  tempEvents[eIndex]["eventRecords"][rIndex]["sell_price"],
                  "ppePartners"
                );
              tempEvents[eIndex]["eventRecords"][rIndex][
                "ticket_exchange_prices"
              ] = resetPPEFields(
                tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
                tempEvents[eIndex]["eventRecords"][rIndex]["sell_price"],
                "ticket_exchange_prices"
              );
            }
          }

          //for the category change
          if (data.target.name === "category") {
            tempEvents[eIndex]["eventRecords"][rIndex]["section"] = null;
          }
          if (data.target.name === "section") {
            const avg_price = calculateAvgPriceForProceedValue(
              data,
              tempEvents[eIndex]["eventRecords"][rIndex]["markinsight"],
              tempEvents[eIndex]["eventRecords"][rIndex],
              event,
              conversation_rate,
              isGodAdmin
            );

            tempEvents[eIndex]["eventRecords"][rIndex]["avg_price"] = avg_price;
          }

          if (
            data.target.type === "checkbox" &&
            data.target.name === "selected" &&
            _.size(tempEvents[eIndex]?.["requireSelectAll"]) > 0
          ) {
            delete tempEvents[eIndex]["requireSelectAll"];
          }

          data.target.name !== "selected" &&
            (tempEvents[eIndex]["eventRecords"][rIndex]["isInputChange"] =
              true);

          // WHEN TICKIT TYPE IS EMPTY THEN SLECTED SUBTICKIT TYPE FIRST BY DEFAULT
          data.target.name === "ticket_type" &&
            data.target?.value?.id === "eticket" &&
            tempEvents[eIndex]["eventRecords"][rIndex]["sub_ticket_type"] ===
              "" &&
            (tempEvents[eIndex]["eventRecords"][rIndex]["sub_ticket_type"] =
              subTicketType?.[0]);

          data.target.name === "split_type" &&
            data.target.value?.id !== "MULTIPLES" &&
            (tempEvents[eIndex]["eventRecords"][rIndex]["split_quantity"] = 0);

          //for the eticket change
          if (data.target.name === "ticket_type") {
            //for delivery type field
            if (data.target.value?.id !== "paper") {
              tempEvents[eIndex]["eventRecords"][rIndex]["delivery_options"] =
                [];
            }
          }
          if (data.target.name === "ticketsInHands" && data.target?.checked) {
            tempEvents[eIndex]["eventRecords"][rIndex]["date_to_ship"] =
              new Date();
          }
          let visibility: any = visibilityMeterCalculationForInventory(
            tempEvents[eIndex],
            tempEvents[eIndex]["eventRecords"][rIndex],
            layout.ticketType
          );

          const currentVisibility =
            tempEvents[eIndex]["eventRecords"][rIndex]["visibility"];
          const oldVisibility =
            currentVisibility === visibility
              ? tempEvents[eIndex]["eventRecords"][rIndex]["oldVisibility"]
              : currentVisibility;

          tempEvents[eIndex]["eventRecords"][rIndex]["oldVisibility"] =
            oldVisibility;
          tempEvents[eIndex]["eventRecords"][rIndex]["visibility"] = visibility;
          //setEventRecord(tempEvents[eIndex]["eventRecords"][rIndex]);
          setList(tempEvents);
          // If table is edit-able
        } else {
          setList((prev: any) => {
            return prev.map((innerEvent: any, eventIndex: number) => {
              if (event.id === innerEvent.id) {
                let eventRecords: any = Array.isArray(
                  innerEvent["eventRecords"]
                )
                  ? event["eventRecords"].map((eventRecord: any) => {
                      if (eventRecord?.selected === true) {
                        let newArrDelivery = layout.deliveryType?.reduce(
                          (count: any, item: any) => {
                            count[item.id] = item.type;
                            return count;
                          },
                          {}
                        );

                        layout.setTableDataEditChanging((pre: any[]) => {
                          const eventPre = pre[eIndex] || [];
                          const conditions: any = newArrDelivery;
                          const newName = conditions[data.target.name] || null;

                          let updatedEventPre = [...eventPre];

                          if (
                            newName &&
                            updatedEventPre.indexOf(newName) === -1 &&
                            data.target.name
                          ) {
                            updatedEventPre = [
                              ...updatedEventPre,
                              newName,
                              data.target.name,
                            ];
                          } else if (
                            !newName &&
                            updatedEventPre.indexOf(data.target.name) === -1 &&
                            data.target.name
                          ) {
                            updatedEventPre = _.uniq(
                              updatedEventPre.concat(data.target.name)
                            );
                          }

                          const newPre = [...pre];
                          newPre[eIndex] = updatedEventPre;

                          return newPre;
                        });

                        //for split type
                        let multipleData = eventRecord?.split_quantity;
                        if (
                          data.target.name === "split_type" &&
                          data.target.value?.id !== "MULTIPLES"
                        ) {
                          multipleData = "";
                        }

                        let final = {
                          ...eventRecord,
                          split_quantity: multipleData,
                          replacible:
                            _.size(eventRecord?.replacible) === 0
                              ? eventRecord
                              : eventRecord?.replacible,
                        };
                        const { parentName, target } = data || {};
                        const { name, value, type, checked } = target || {};

                        if (parentName === "ppePartners") {
                          if (data.target.name === "sell_price") {
                            final["sell_price"] = data?.target?.value;
                          }
                          final["ppePartners"] = updatePPEPartners(
                            final["ppePartners"],
                            data?.id,
                            data?.target?.value,
                            "ppePartners",
                            prev?.sell_price
                          );
                          final["ticket_exchange_prices"] = updatePPEPartners(
                            final["ppePartners"],
                            data?.id,
                            data?.target?.value,
                            "ticket_exchange_prices",
                            prev?.sell_price
                          );
                        } else {
                          const newValue =
                            value !== undefined && Array.isArray(value)
                              ? value
                              : typeof value === "object"
                              ? value
                              : typeof value === "string" && type !== "checkbox"
                              ? value
                              : type === "checkbox"
                              ? checked
                              : value;
                          final = {
                            ...final,
                            [name]: newValue,
                            ...(name === "sell_price" &&
                              (final["price_per_exchange"] === 1 ||
                                final["price_per_exchange"] === false) && {
                                ppePartners: updatePPEPartners(
                                  final["ppePartners"],
                                  null,
                                  null,
                                  "ppePartners",
                                  value
                                ),
                                ticket_exchange_prices: updatePPEPartners(
                                  final["ppePartners"],
                                  null,
                                  null,
                                  "ticket_exchange_prices",
                                  value
                                ),
                              }),

                            ...(name === "sell_price" &&
                              (final["price_per_exchange"] === 0 ||
                                final["price_per_exchange"] === false) && {
                                ppePartners: resetPPEFields(
                                  final?.["ppePartners"],
                                  value,
                                  "ppePartners"
                                ),
                                ticket_exchange_prices: resetPPEFields(
                                  final?.["ppePartners"],
                                  value,
                                  "ticket_exchange_prices"
                                ),
                              }),

                            ...(data?.target?.name === "price_per_exchange" && {
                              ppePartners: resetPPEFields(
                                final?.["ppePartners"],
                                final.sell_price,
                                "ppePartners"
                              ),
                              ticket_exchange_prices: resetPPEFields(
                                final?.["ppePartners"],
                                final.sell_price,
                                "ticket_exchange_prices"
                              ),
                            }),

                            ...(data?.target?.name === "price_per_exchange" &&
                              data.target.checked === true && {
                                ppeIsOpen: true,
                              }),

                            ...(data?.target?.name === "price_per_exchange" &&
                              data.target.checked === false &&
                              eventRecord.id !== firstSelectedIndex.id && {
                                ppeIsOpen: false,
                              }),
                          };
                        }

                        return {
                          ...final,
                          ...(final?.ticketsInHands
                            ? { date_to_ship: new Date() }
                            : { date_to_ship: final?.date_to_ship }),
                          ...(name !== "selected"
                            ? { isInputChange: true }
                            : {}),
                        };
                      } else {
                        return eventRecord;
                      }
                    })
                  : innerEvent["eventRecords"];

                const final = eventRecords?.map(
                  (record: any, index: number) => {
                    let newArrDelivery = eventRecord?.delivery_options?.reduce(
                      (count: any, item: any) => {
                        count[item.id] = item.type;
                        return count;
                      },
                      {}
                    );

                    if (record?.selected) {
                      if (record?.section?.id) {
                        let categoryArray: any = record.category;
                        if (typeof categoryArray === "string") {
                          categoryArray = innerEvent?.categories?.find(
                            (obj: any) =>
                              Number(obj?.id) === Number(categoryArray)
                          );
                        }
                        const matchingId = record?.section?.id;
                        const matchingSections =
                          categoryArray?.sections?.filter(
                            (innerObj: any) => innerObj?.id === matchingId
                          );

                        if (
                          !matchingSections ||
                          matchingSections?.length === 0
                        ) {
                          record.section = "";
                        }
                      }

                      if (firstSelectedIndex === -1) {
                        // This is the first selected record, store its index
                        firstSelectedIndex = index;
                        return {
                          ...record,
                        };
                      } else {
                        // for PPE
                        // if (record.price_per_exchange === 1) {
                        //   record.sell_price = record?.replacible?.sell_price;
                        //   record.isInputChange = false;
                        //   record.selected = false;
                        // }

                        // Check if ticketType is "paper"
                        if (
                          record.ticket_type?.id === "paper" ||
                          record.ticket_type === "paper"
                        ) {
                          return {
                            ...record,
                          };
                        } else {
                          const keysToDelete = Object.keys(newArrDelivery);
                          let rest = record;

                          keysToDelete.forEach((key: any) => {
                            if (rest.hasOwnProperty(key)) {
                              delete rest[key];
                            }
                          });
                          return {
                            ...rest,
                            delivery_options: [],
                          };
                        }
                      }
                    }

                    return record;
                  }
                );

                return {
                  ...innerEvent,
                  eventRecords: final?.map((obj: any) => {
                    // VISIBILITY LOGIC
                    let visibility: any =
                      visibilityMeterCalculationForInventory(
                        event,
                        obj,
                        layout?.ticketType
                      );
                    let avg_price: any;

                    avg_price = calculateAvgPriceForProceedValue(
                      obj?.section,
                      obj?.["markinsight"],
                      obj,
                      event,
                      conversation_rate,
                      isGodAdmin
                    );
                    return {
                      ...obj,
                      visibility: visibility,
                      oldVisibility:
                        obj?.visibility === visibility
                          ? obj?.oldVisibility
                          : obj?.visibility,
                      avg_price: avg_price,
                    };
                  }),
                };
              } else {
                return innerEvent;
              }
            });
          });
        }
      };

      return MemoizedHandleTableField;
    }, [eIndex, event, layout, list, rIndex, setList, subTicketType]);
    // const updatePPEPartners = (
    //   ppePartners: any[],
    //   id: any,
    //   value: any,
    //   type: any
    // ) => {
    //   let partnerExists = false;

    //   const updatedPartners: any = ppePartners
    //     ? ppePartners.map((partner) => {
    //         if (partner.id === id) {
    //           partnerExists = true;
    //           return { ...partner, value: value };
    //         }
    //         return partner;
    //       })
    //     : [];

    //   if (!partnerExists) {
    //     updatedPartners.push({ id: id, value: value });
    //   }

    //   let minValue = Number.MAX_VALUE;
    //   let minValueSymbol = "";

    //   updatedPartners.forEach((partner: any) => {
    //     const partnerValue = Number(partner?.value?.value);

    //     if (!partner.is_hidden && !isNaN(partnerValue)) {
    //       if (partnerValue < minValue) {
    //         minValue = partnerValue;
    //         minValueSymbol = partner?.value?.symbol;
    //       }
    //     }
    //   });

    //   const arrayWithTxtradePrice = updatedPartners.map((partner: any) => {
    //     if (partner.is_hidden === true) {
    //       return {
    //         ...partner,
    //         value: {
    //           ...partner.value,
    //           value: minValue,
    //           symbol: minValueSymbol,
    //         },
    //       };
    //     }
    //     return partner;
    //   });

    //   if (type === "ppePartners") {
    //     return arrayWithTxtradePrice;
    //   } else {
    //     return arrayWithTxtradePrice?.map((partner: any) => {
    //       return {
    //         exchange_id: partner.exchange_id,
    //         sell_price_currency: partner?.value?.symbol,
    //         sell_price: partner?.value?.value,
    //         reseller_id: partner?.id,
    //         reseller_name: partner?.name,
    //       };
    //     });
    //   }
    // };

    const MemoizedHandleTableField = useMemo(() => {
      return (data: any, eIndex: number, rIndex: number) => {
        setEventRecord((prev: any) => {
          if (data?.parentName === "ppePartners") {
            if (data.target.name === "sell_price") {
              return { ...prev, [data?.target?.name]: data.target.value };
            }
            return {
              ...prev,
              ppePartners: updatePPEPartners(
                prev?.["ppePartners"],
                data?.id,
                data?.target?.value,
                "ppePartners",
                prev.sell_price
              ),
              ticket_exchange_prices: updatePPEPartners(
                prev?.["ppePartners"],
                data?.id,
                data?.target?.value,
                "ticket_exchange_prices",
                prev.sell_price
              ),
            };
          } else {
            return {
              ...prev,
              [data?.target?.name]:
                data.target.value !== undefined &&
                Array.isArray(data.target.value)
                  ? data.target.value
                  : typeof data.target.value === "object"
                  ? data.target.value
                  : typeof data.target.value === "string" &&
                    data.target.type !== "checkbox"
                  ? data.target.value
                  : data.target.type === "checkbox"
                  ? data.target.checked
                  : data.target.value,

              ...(data?.target?.name === "sell_price" &&
                (prev?.["price_per_exchange"] === 1 ||
                  prev?.["price_per_exchange"] === true) && {
                  ppePartners: updatePPEPartners(
                    prev?.["ppePartners"],
                    null,
                    null,
                    "ppePartners",
                    data.target.value
                  ),
                  ticket_exchange_prices: updatePPEPartners(
                    prev?.["ppePartners"],
                    null,
                    null,
                    "ticket_exchange_prices",
                    data.target.value
                  ),
                }),

              ...(data?.target?.name === "sell_price" &&
                (prev?.["price_per_exchange"] === 0 ||
                  prev?.["price_per_exchange"] === false) && {
                  ppePartners: resetPPEFields(
                    prev?.["ppePartners"],
                    data.target.value,
                    "ppePartners"
                  ),
                  ticket_exchange_prices: resetPPEFields(
                    prev?.["ppePartners"],
                    data.target.value,
                    "ticket_exchange_prices"
                  ),
                }),

              ...(data?.target?.name === "price_per_exchange" && {
                ppePartners: resetPPEFields(
                  prev?.["ppePartners"],
                  prev?.sell_price,
                  "ppePartners"
                ),
                ticket_exchange_prices: resetPPEFields(
                  prev?.["ppePartners"],
                  prev?.sell_price,
                  "ticket_exchange_prices"
                ),
              }),
            };
          }
        });

        if (layout.isTableEditable.length === 0) {
          // SINGLE EDIT
          MemoizedHandleTableFieldOld(data);
          setUpdateChecker({
            eventId: eIndex,
            eventRecordId: rIndex,
          });
        } else {
          // MULTI EDIT
          setUpdateChecker(data);
        }
      };
    }, [MemoizedHandleTableFieldOld, layout.isTableEditable.length]);

    // SINGLE EDIT
    useEffect(() => {
      if (layout.isTableEditable.length === 0) {
        setTimeout(() => {
          if (updateChecker?.eventId !== undefined) {
            setEventRecord(
              list?.[updateChecker?.eventId]?.eventRecords?.[
                updateChecker?.eventRecordId
              ]
            );
          }
        }, 1000);
      }
    }, [layout.isTableEditable.length, list, updateChecker]);

    // // MULTI EDIT
    // let eventRecordMemo: any = useMemo(() => {
    //   return [updateChecker];
    // }, [updateChecker]);

    // const saveDataInStateTimer: number = 500;
    // let debounceHandler = useDebounce(eventRecordMemo, saveDataInStateTimer);

    // useEffect(() => {
    //   if (
    //     updateChecker &&
    //     updateChecker?.eventId === undefined &&
    //     layout.isTableEditable.length > 0
    //   ) {
    //     MemoizedHandleTableFieldOld(updateChecker);
    //   }
    // }, [debounceHandler]);

    const currentInputRef = useRef(updateChecker);
    const saveDataInStateTimer: number = 500;
    useEffect(() => {
      currentInputRef.current = updateChecker;

      if (
        currentInputRef.current &&
        currentInputRef.current.eventId === undefined &&
        layout.isTableEditable.length > 0
      ) {
        MemoizedHandleTableFieldOld(currentInputRef.current);
      }
    }, [updateChecker]);

    const handleOnSelection = (
      data: any,
      eIndex: any,
      rIndex: any,
      option: any,
      filedName: any,
      visibleOptions: any,
      currentOptionsSelected: any
    ) => {
      let updatedOptions =
        visibleOptions && size(visibleOptions) > 0
          ? visibleOptions
          : data?.target?.id === "select-all"
          ? option
          : null;
      if (data?.target?.id === "select-all") {
        let target = {
          name: filedName,
          value: updatedOptions,
        };
        MemoizedHandleTableField({ target }, eIndex, rIndex);
      } else if (data?.target?.id === "deselect-all") {
        let target = {
          name: filedName,
          value: removeSelectAll(currentOptionsSelected, updatedOptions),
        };
        MemoizedHandleTableField({ target }, eIndex, rIndex);
      }
    };

    const focusOnPriceField = (data: any, eIndex: any, rIndex: any) => {
      setPriceFieldFocused({ data: data, eIndex: eIndex, rIndex: rIndex });
    };

    const ppeAction: any = useMemo(() => {
      return layout.isTableEditable?.length > 0
        ? checkPPEExistForTableLevelNEw(event, isGodAdmin)
        : "";
    }, [event, layout.isTableEditable?.length]);

    let ppeErrosValidation = isGodAdmin
      ? layout?.isTableEditable?.length > 0 &&
        (ppeAction?.godAdminError ||
          (!ppeAction?.godAdminError &&
            !ppeAction?.allPPE &&
            ppeAction?.somePPE))
      : layout?.isTableEditable?.length > 0 &&
        !ppeAction?.allPPE &&
        ppeAction?.somePPE;

    return (
      <div
        className={clsx("parList", {
          "row-error-new group-hover:bg-rose-550 relative":
            isEventRecordError ||
            eventRecord?.status === "failed" ||
            isEventRecordBEError,
          "group-hover:bg-sky-blue-300 row-hold-new": isEventRecordHoldTickets,
          "row-processing-new": eventRecord?.processing,
        })}
        onClick={() => {
          if (layout.isTableEditable.length === 0) {
            layout.setCurrentIndex({ rIndex: rIndex, eIndex: event?.id });
          }
        }}
      >
        <React.Fragment
          key={"eventFieldIn" + eventRecord?.id + rIndex + `ListingRecord`}
        >
          {/* {Date.now()} */}
          {/* {JSON.stringify(eventRecord?.loader)}--eventRecord <br /> */}
          {/* {JSON.stringify(tempEventRecord?.loader)}--tempEventRecord <br /> */}
          <div
            className={clsx(
              "first_row border-b items-center flex table_row",
              (isALLTicketUploadedWithPublish || eventRecord?.isDisabled) &&
                "bulkUploaded",
              eventRecord?.bulkUpdate && "row-disabled !bg-gray-300/40",
              layout?.isBulkActionLoader && "row-disabled !bg-gray-300/40",
              eventRecord?.deleteLoader && "fade-effect",
              eventRecord?.bulkLoader && "row-disabled",
              layout.tableAlert?.confirm && "pointer-events-none",
              eventRecord?.id === firstSelectedIndex?.id &&
                eventRecord?.selected &&
                "first",
              event?.value === "" && "row-disabled",
              eventRecord?.processing && "row-processing",
              isDisabled,
              (eventRecord?.selected ||
                (matchIndexView?.pIndex === eIndex &&
                  matchIndexView?.cIndex === rIndex) ||
                layout.logsData?.id === eventRecord?.id) &&
                "bg-indigo-500 bg-opacity-[7%]",
              eventRecord?.status === "partPublished" &&
                "bg-orange-500/10 hover:bg-orange-500/10",
              layout.isDeleteActive && "pointer-events-none",
              layout.isEditPopUpActive && "pointer-events-none",
              eventRecord?.selected && layout.confirmRowDelete && "fade",
              (tempEventRecord?.loader ||
                // eventRecord?.loader ||
                (eventRecord?.loader &&
                  (eventRecord?.bulkLoader || layout?.updateAllLoader))) &&
                "row-disabled",
              isGodAdmin &&
                eventRecord?.isLock === true &&
                "pointer-events-none bg-gray-300/40"
            )}
          >
            {/* LOCK / UNLOCK COMPONENT */}
            <LockComponent
              event={event}
              eventRecord={eventRecord}
              rIndex={rIndex}
              setList={setList}
            />

            {/* CHECKBOX  */}
            <ListingCheckbox
              event={event}
              eventRecord={eventRecord}
              eIndex={eIndex}
              rIndex={rIndex}
              list={list}
              setList={setList}
              MemoizedHandleTableField={MemoizedHandleTableField}
            />

            {/* LISTING - FIELDS */}
            {getColumnItems?.map((columnItem: any, index: number) => {
              const getUniqueID = (fieldName: string) => {
                return `${event?.id}-${columnItem.id}-${eventRecord?.id}-${index}-${fieldName}`;
              };

              switch (columnItem.id) {
                case 0:
                  return (
                    <div
                      className={`p-1.5 font-medium td_block pl-[.9375rem]`}
                      key={getUniqueID("eventFieldListingID")}
                    >
                     <input
                        type="text"
                        className={`bg-gray-100 truncate h-6 max-w-[6.25rem] min-w-[6.25rem] text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 !border-none !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                        value={eventRecord?.seller_listing_id ? eventRecord?.seller_listing_id :  crc32(eventRecord?.id)}
                        placeholder="Listing ID"
                        disabled={true}
                      />
                    </div>
                  );
                case 1:
                  return (
                    <div
                      className={`p-1.5 font-medium td_block `}
                      key={getUniqueID("team_name")}
                    >
                      <div className="ellips-text max-w-[6.875rem] min-w-[6.875rem]">
                        {eventRecord?.team_name &&
                          firstLetterCapital(eventRecord?.team_name)}
                      </div>
                    </div>
                  );
                case 2:
                  return (
                    <div
                      className={`p-1.5 font-medium td_block `}
                      key={getUniqueID("user_name")}
                    >
                      <div className="ellips-text max-w-[6.875rem] min-w-[6.875rem] ">
                        {eventRecord?.user_name &&
                          firstLetterCapital(eventRecord?.user_name)}
                      </div>
                    </div>
                  );

                case 3:
                  return (
                    <Fragment key={getUniqueID("listing-id")}>
                      {isALLTicketUploadedWithPublish ||
                      eventRecord?.isDisabled ? (
                        <div className=" p-1.5 max-w-[7.625rem] min-w-[7.625rem]">
                          <input
                            type="text"
                            className={`bg-gray-100 truncate h-6 w-full text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-gray-100 !text-violet-800 placeholder:!text-violet-800 disable px-2.5 `}
                            value={ticketTypeValue?.name}
                            placeholder="Listing ID"
                            disabled={true}
                          />
                        </div>
                      ) : (
                        <div
                          className={`p-1.5 font-medium ${
                            layout.tableDataEditChanging?.includes(
                              "ticket_type"
                            ) && "edited"
                          }   `}
                          id={`ticket_type-${event.id}-${rIndex}`}
                        >
                          <TableDropdown
                            isCapital={true}
                            isValueCapital={true}
                            value={ticketTypeValue}
                            isClearable={false}
                            options={layout.ticketType}
                            placeholder={"Ticket type"}
                            name="ticket_type"
                            handleOnChange={(data: any) => {
                              MemoizedHandleTableField(data, eIndex, rIndex);
                              // setTimeout(() => {
                              //   setList(refreshEventsWhileActionPerform(list));
                              // }, 1);
                            }}
                            isDisabled={isEventRecordHoldTickets}
                            errorClassName={
                              eventRecord?.fieldErrors?.ticket_type &&
                              "error-field"
                            }
                            errorEventIndex={
                              eventRecord?.fieldErrors?.ticket_type &&
                              `ticket_type-${eventRecord?.id}`
                            }
                            errorMessage={
                              eventRecord?.fieldErrors?.ticket_type
                                ? eventRecord?.fieldErrors?.ticket_type
                                : ""
                            }
                          />
                        </div>
                      )}

                      {paperTypeExist && (
                        <div
                          className={`p-1.5 font-medium min-w-[7.875rem] ${
                            layout.tableDataEditChanging?.includes(
                              "delivery_options"
                            ) && "edited"
                          }`}
                        >
                          <TableMultiDropdown
                            name="delivery_options"
                            isCapital={true}
                            options={layout.deliveryType}
                            value={eventRecord?.delivery_options}
                            classNamePrefix={"table_multiDropdown"}
                            placeholder={
                              eventRecord?.delivery_options &&
                              size(eventRecord?.delivery_options) > 0
                                ? ""
                                : "Delivery type"
                            }
                            handleOnChange={(data: any) => {
                              MemoizedHandleTableField(data, eIndex, rIndex);
                            }}
                            getOptionLabel="type"
                            isDisabled={
                              eventRecord?.hold_tickets?.length > 0 ||
                              eventRecord?.isDisabled ||
                              (typeof eventRecord?.ticket_type === "object" &&
                                eventRecord?.ticket_type?.id !== "paper")
                                ? true
                                : typeof eventRecord?.ticket_type ===
                                    "string" &&
                                  eventRecord?.ticket_type !== "paper"
                                ? true
                                : false
                            }
                          />
                        </div>
                      )}

                      {paperTypeExist &&
                        isArray(selectedDeliveryTypes) &&
                        selectedDeliveryTypes.length > 0 &&
                        _.orderBy(selectedDeliveryTypes, deliveryTypeOrders, [
                          "asc",
                        ]).map((selected: any) => {
                          let typeTemp = _.find(layout.deliveryType, {
                            type: selected,
                          });
                          let delivery = find(eventRecord?.delivery_options, {
                            id: typeTemp?.id,
                          });
                          return delivery &&
                            eventRecord?.hold_tickets.length === 0 ? (
                            <div
                              id={`${delivery?.id}-${event.id}-${rIndex}`}
                              className={`p-1.5 font-medium ${
                                layout.tableDataEditChanging?.includes(
                                  delivery?.id
                                ) && "edited"
                              }`}
                            >
                              <div className="relative">
                                <input
                                  type="text"
                                  name={delivery?.id}
                                  className={`
              truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded  focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5 
            ${
              eventRecord?.fieldErrors?.[delivery?.id] &&
              "border-rose-500 focus:border-rose-500 #{!important} placeholder:text-rose-500"
            }
              `}
                                  value={
                                    eventRecord?.[delivery?.id] != undefined
                                      ? eventRecord?.[delivery?.id]
                                      : Number(delivery?.price)
                                      ? Number(delivery?.price)
                                      : ""
                                  }
                                  placeholder={selected}
                                  id={`${[delivery?.id]}`}
                                  onChange={(event: any) => {
                                    MemoizedHandleTableField(
                                      event,
                                      eIndex,
                                      rIndex
                                    );
                                  }}
                                  onKeyDown={(e: any) => {
                                    secureStateWhileTabbing(
                                      e,
                                      saveDataInStateTimer
                                    );
                                    priceFieldInputKeyDown(e);
                                  }}
                                />
                                {eventRecord?.[delivery?.id]
                                  ? ""
                                  : eventRecord?.fieldErrors?.[
                                      delivery?.id
                                    ] && (
                                      <>
                                        <TooltipPortal
                                          id={`delivery-type-${delivery.id}-error-${eIndex}-${rIndex}`}
                                          content={
                                            eventRecord?.fieldErrors?.[
                                              delivery?.id
                                            ]
                                          }
                                          place="top"
                                          variant="light"
                                          className="text-center !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-20 max-w-[9rem] !text-rose-500"
                                        />
                                        <div
                                          data-tooltip-id={`delivery-type-${delivery.id}-error-${eIndex}-${rIndex}`}
                                          className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                                        >
                                          <ComponentSVG className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition">
                                            <Warning1 />
                                          </ComponentSVG>
                                        </div>
                                      </>
                                    )}
                              </div>
                            </div>
                          ) : (
                            <div className="p-1.5 font-medium">
                              <div className="relative">
                                <input
                                  type="number"
                                  className={`bg-gray-100 text-gray-400 placeholder:text-gray-400 placeholder:truncate pointer-events-none truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-none rounded focus:border-indigo-500 focus:bg-violet-300/50 px-2.5 123 `}
                                  placeholder={selected}
                                  disabled={true}
                                  value={``}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </Fragment>
                  );
                case 5:
                  return (
                    <React.Fragment key={getUniqueID("quantity_available")}>
                      <div
                        className={clsx(
                          "p-1.5 font-medium",
                          layout.tableDataEditChanging?.includes(
                            "quantity_available"
                          ) && "edited"
                        )}
                        id={`quantity_available-${event.id}-${rIndex}`}
                      >
                        <div className="relative">
                          <input
                            disabled={isEventRecordHoldTickets}
                            id={event?.id + rIndex + `_quantity_available`}
                            type="text"
                            name="quantity_available"
                            className={`truncate h-6 max-w-[4.0625rem] text-xs font-weight-500 py-0 border-gray-300 rounded  focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5 ${
                              eventRecord?.fieldErrors?.quantity_available &&
                              "border-rose-500 focus:border-rose-500 #{!important} placeholder:text-rose-500"
                            } ${
                              (isALLTicketUploadedWithPublish ||
                                eventRecord?.isDisabled) &&
                              "pointer-events-none bg-gray-100 !border-gray-100"
                            }
                            ${isEventRecordHoldTickets && `bg-gray-100`}
                              `}
                            value={eventRecord?.quantity_available}
                            placeholder="Quantity"
                            onKeyDown={(e: any) => {
                              // secureStateWhileTabbing(e, saveDataInStateTimer);
                              handleKeyDown(e);
                            }}
                            onChange={(e: any) => {
                              e.preventDefault();
                              MemoizedHandleTableField(e, eIndex, rIndex);
                            }}
                          />
                          {eventRecord?.fieldErrors?.quantity_available && (
                            <>
                              <TooltipPortal
                                id={`quantity-error-${eIndex}-${rIndex}`}
                                content={
                                  eventRecord?.fieldErrors?.quantity_available
                                }
                                className="text-center !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-20 max-w-[13.75rem] !text-rose-500"
                              />
                              <div
                                data-tooltip-id={`quantity-error-${eIndex}-${rIndex}`}
                                className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                              >
                                <ComponentSVG className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition">
                                  <Warning1 />
                                </ComponentSVG>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="p-1.5 font-medium">
                        <input
                          type="number"
                          className={`bg-gray-100 truncate h-6 max-w-[3.4375rem] text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-none !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                          value={eventRecord?.quantity_sold}
                          placeholder="Sold"
                          disabled={true}
                        />
                      </div>
                    </React.Fragment>
                  );

                case 6:
                  return (
                    <React.Fragment key={getUniqueID("splitTypeValue")}>
                      {isALLTicketUploadedWithPublish ||
                      eventRecord?.isDisabled ? (
                        <div className=" p-1.5 max-w-[7.625rem] min-w-[7.625rem]">
                          <input
                            type="text"
                            className={`bg-gray-100 truncate h-6 w-full  text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-gray-100 !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                            value={
                              splitTypeValue?.name === "No Preference"
                                ? "No preference"
                                : splitTypeValue?.name
                            }
                            placeholder="Split type"
                            disabled={true}
                          />
                        </div>
                      ) : (
                        <div
                          className={`p-1.5 font-medium ${
                            layout.tableDataEditChanging?.includes(
                              "split_type"
                            ) && "edited"
                          }`}
                          key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                        >
                          <TableDropdown
                            value={splitTypeValue}
                            isClearable={false}
                            options={layout.splitType}
                            placeholder={"Split type"}
                            name="split_type"
                            handleOnChange={(data: any) => {
                              MemoizedHandleTableField(data, eIndex, rIndex);
                              // setTimeout(() => {
                              //   setList(refreshEventsWhileActionPerform(list));
                              // }, 1);
                            }}
                            isDisabled={isEventRecordHoldTickets}
                            errorClassName={
                              eventRecord?.fieldErrors?.splitType &&
                              "error-field"
                            }
                            errorEventIndex={
                              eventRecord?.fieldErrors?.splitType &&
                              `splitType-${eventRecord?.id}`
                            }
                            errorMessage={
                              eventRecord?.fieldErrors?.splitType &&
                              eventRecord?.fieldErrors?.splitType
                            }
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );
                case 7:
                  return (
                    <div
                      className={`p-1.5 font-medium ${
                        layout.tableDataEditChanging?.includes(
                          "split_quantity"
                        ) && "edited"
                      }`}
                      key={getUniqueID("split_quantity")}
                    >
                      <input
                        type="text"
                        className={`${splitCondition} ${
                          isEventRecordHoldTickets &&
                          `bg-gray-100 pointer-events-none !border-gray-100 text-gray-400 placeholder:text-gray-400 disable`
                        }  ${
                          (isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled) &&
                          "pointer-events-none bg-gray-100 !border-gray-100"
                        }  truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                        value={eventRecord?.split_quantity}
                        placeholder="Sell In Multiples"
                        name="split_quantity"
                        id={`${event?.id}${rIndex}split_quantity`}
                        onKeyDown={(e: any) => {
                          // secureStateWhileTabbing(e, saveDataInStateTimer);
                          handleKeyDown(e);
                        }}
                        onChange={(e: any) => {
                          e.preventDefault();
                          MemoizedHandleTableField(e, eIndex, rIndex);
                        }}
                        disabled={isEventRecordHoldTickets}
                      />
                    </div>
                  );

                case 8:
                  return (
                    <div
                      className={clsx(
                        "p-1.5 font-medium",
                        layout.tableDataEditChanging?.includes(
                          "quantity_display"
                        ) && "edited"
                      )}
                      key={getUniqueID("quantity_display")}
                    >
                      <input
                        type="text"
                        className={`${
                          isEventRecordHoldTickets &&
                          `bg-gray-100 pointer-events-none !border-gray-100 text-gray-400 placeholder:text-gray-400 disable`
                        }  ${
                          (isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled) &&
                          "pointer-events-none bg-gray-100 !border-gray-100"
                        }   truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                        value={eventRecord?.quantity_display}
                        placeholder="Max display quantity"
                        name="quantity_display"
                        id={`${event?.id}${rIndex}quantity_display`}
                        onKeyDown={(e: any) => {
                          // secureStateWhileTabbing(e, saveDataInStateTimer);
                          handleKeyDown(e);
                        }}
                        onChange={(e: any) => {
                          e.preventDefault();
                          MemoizedHandleTableField(e, eIndex, rIndex);
                        }}
                        disabled={isEventRecordHoldTickets}
                      />
                    </div>
                  );

                case 9:
                  return (
                    <React.Fragment key={getUniqueID("category")}>
                      {isALLTicketUploadedWithPublish ||
                      eventRecord?.isDisabled ? (
                        <div
                          className=" p-1.5 md:max-w-[13.25rem] md:min-w-[13.25rem] max-w-[11.25rem] min-w-[11.25rem]"
                          id={`category-${event.id}-${rIndex}`}
                        >
                          <input
                            type="text"
                            className={` bg-gray-100 truncate h-6 w-full text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none !border-gray-100 !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                            value={categoryValue?.name}
                            placeholder="Category"
                            disabled={true}
                          />
                        </div>
                      ) : (
                        <div
                          className={`p-1.5 font-medium auto_width_row ${
                            layout.tableDataEditChanging?.includes(
                              "category"
                            ) && "edited"
                          }`}
                          key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                          id={`category-${event.id}-${rIndex}`}
                        >
                          <TableDropdown
                            value={
                              eventRecord?.category?.id
                                ? eventRecord?.category
                                : event?.categories &&
                                  event?.categories.find(
                                    (category: any) =>
                                      Number(category.id) ===
                                      Number(eventRecord?.category)
                                  )
                            }
                            options={uniqueByNameWithPriority(
                              event?.categories,
                              eventRecord?.category?.id
                                ? eventRecord?.category?.id
                                : eventRecord?.category
                            )}
                            placeholder={"Category"}
                            isCapital={true}
                            isValueCapital={true}
                            name="category"
                            handleOnChange={(data: any) => {
                              MemoizedHandleTableField(data, eIndex, rIndex);
                              // setTimeout(() => {
                              //   setList(refreshEventsWhileActionPerform(list));
                              // }, 1);
                            }}
                            isDisabled={isEventRecordHoldTickets}
                            errorClassName={
                              eventRecord?.fieldErrors?.category &&
                              "error-field"
                            }
                            errorEventIndex={
                              eventRecord?.fieldErrors?.category &&
                              `category-${eventRecord?.id}`
                            }
                            errorMessage={
                              eventRecord?.fieldErrors?.category
                                ? eventRecord?.fieldErrors?.category
                                : ""
                            }
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );

                case 10:
                  return (
                    <React.Fragment key={getUniqueID("section")}>
                      {isALLTicketUploadedWithPublish ||
                      eventRecord?.isDisabled ? (
                        <div className=" p-1.5 md:max-w-[13.25rem] md:min-w-[13.25rem] max-w-[11.25rem] min-w-[11.25rem]">
                          <input
                            type="text"
                            className={`bg-gray-100 w-full  truncate h-6  text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-gray-100 !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                            value={sectionValue?.name?.trim()}
                            placeholder="Section"
                            disabled={true}
                          />
                        </div>
                      ) : (
                        <div
                          className={`p-1.5 font-medium auto_width_row ${
                            layout.tableDataEditChanging?.includes("section") &&
                            "edited"
                          }`}
                          key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                        >
                          <TableDropdown
                            value={
                              eventRecord?.section && eventRecord?.section?.id
                                ? eventRecord?.section
                                : eventRecord?.section &&
                                  event?.categories &&
                                  Array.isArray(event?.categories) &&
                                  event?.categories
                                    .find(
                                      (category: any) =>
                                        Number(category.id) ===
                                        Number(eventRecord?.category)
                                    )
                                    ?.sections?.find(
                                      (item: any) =>
                                        Number(item?.id) ===
                                        Number(eventRecord?.section)
                                    )
                            }
                            options={
                              eventRecord?.category?.sections
                                ? uniqueByNameWithPriority(
                                    eventRecord?.category?.sections,
                                    eventRecord?.section?.id
                                      ? eventRecord?.section?.id
                                      : eventRecord?.section
                                  )
                                : event?.categories &&
                                  Array.isArray(event?.categories) &&
                                  uniqueByNameWithPriority(
                                    event?.categories.find(
                                      (category: any) =>
                                        Number(category.id) ===
                                        Number(eventRecord?.category)
                                    )?.sections,
                                    eventRecord?.section?.id
                                      ? eventRecord?.section?.id
                                      : eventRecord?.section
                                  )
                            }
                            placeholder={"Section/block"}
                            isCapital={true}
                            isValueCapital={true}
                            name="section"
                            handleOnChange={(data: any) => {
                              MemoizedHandleTableField(data, eIndex, rIndex);
                              // setTimeout(() => {
                              //   setList(refreshEventsWhileActionPerform(list));
                              // }, 1);
                            }}
                            isDisabled={isEventRecordHoldTickets}
                            errorClassName={
                              eventRecord?.fieldErrors?.section && "error-field"
                            }
                            errorEventIndex={
                              eventRecord?.fieldErrors?.section &&
                              `section-${eventRecord?.id}`
                            }
                            errorMessage={
                              eventRecord?.fieldErrors?.section
                                ? eventRecord?.fieldErrors?.section
                                : ""
                            }
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );

                case 11:
                  return (
                    <div
                      className={clsx(
                        "p-1.5 font-medium",
                        layout.tableDataEditChanging?.includes("row") &&
                          "edited"
                      )}
                      key={getUniqueID("row")}
                    >
                      <input
                        type="text"
                        className={`${
                          isEventRecordHoldTickets &&
                          `pointer-events-none bg-gray-100 !border-gray-100 text-gray-400 placeholder:text-gray-400 disable`
                        }  ${
                          (isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled) &&
                          "pointer-events-none bg-gray-100 !border-gray-100"
                        }  h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                        value={eventRecord?.row && eventRecord?.row}
                        placeholder="Row"
                        name="row"
                        id={`${event?.id}${rIndex}row`}
                        onChange={(e: any) => {
                          e.preventDefault();
                          MemoizedHandleTableField(e, eIndex, rIndex);
                        }}
                        disabled={isEventRecordHoldTickets}
                        // onKeyDown={(e: any) =>
                        //   secureStateWhileTabbing(e, saveDataInStateTimer)
                        // }
                      />
                    </div>
                  );

                case 12:
                  return (
                    <div
                      className={clsx(
                        "p-1.5",
                        layout.tableDataEditChanging?.includes("first_seat") &&
                          "edited"
                      )}
                      key={getUniqueID("first_seat")}
                    >
                      <input
                        type="text"
                        className={`${
                          isEventRecordHoldTickets &&
                          `pointer-events-none bg-gray-100 !border-gray-100 text-gray-400 placeholder:text-gray-400 disable`
                        }  ${
                          (isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled) &&
                          "pointer-events-none bg-gray-100 !border-gray-100"
                        } h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                        value={eventRecord?.first_seat}
                        placeholder="First seat"
                        name="first_seat"
                        id={`${event?.id}${rIndex}first_seat`}
                        onKeyDown={(e: any) => {
                          // secureStateWhileTabbing(e, saveDataInStateTimer);
                          handleKeyDown(e);
                        }}
                        onChange={(e: any) => {
                          MemoizedHandleTableField(e, eIndex, rIndex);
                        }}
                        disabled={isEventRecordHoldTickets}
                      />
                    </div>
                  );

                // face value
                case 13:
                  return (
                    <div
                      className={clsx(
                        "p-1.5 font-medium",
                        layout.tableDataEditChanging?.includes("face_value") &&
                          "edited"
                      )}
                      id={`face_value-${event.id}-${rIndex}`}
                      key={getUniqueID("face_value")}
                    >
                      <div>
                        <PriceFieldWithComma
                          value={
                            typeof eventRecord?.face_value === "object"
                              ? eventRecord?.face_value?.value
                              : decimalNumber(eventRecord?.face_value)
                          }
                          currenncySymbol={
                            eventRecord?.face_value_currency
                              ? eventRecord?.face_value_currency
                              : eventRecord?.face_value?.symbol
                          }
                          placeholder={"Face Value"}
                          textFielClassName={`truncate`}
                          name="face_value"
                          id={`${event?.id}${rIndex}face_value`}
                          handleOnChange={(data: any) => {
                            data?.target?.value?.event?.preventDefault();
                            MemoizedHandleTableField(data, eIndex, rIndex);
                          }}
                          isDisabled={
                            isEventRecordHoldTickets ||
                            isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled
                          }
                          isDisabledFace={
                            isEventRecordHoldTickets ||
                            isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled
                          }
                          holdTicket={eventRecord.hold_tickets}
                          tabTiming={saveDataInStateTimer}
                          errorClass={`${
                            eventRecord?.fieldErrors?.face_value
                              ? eventRecord?.fieldErrors?.face_value
                              : ""
                          }`}
                          errorMessage={`${
                            eventRecord?.fieldErrors?.face_value
                              ? eventRecord?.fieldErrors?.face_value
                              : ""
                          }`}
                          errorEventIndex={
                            eventRecord?.fieldErrors?.face_value &&
                            `face_value-${eventRecord?.id}`
                          }
                        />
                      </div>
                    </div>
                  );

                case 14:
                  return (
                    !layout?.proceedPriceToggle && (
                      <div
                        className={clsx(
                          "p-1.5 font-medium proceed-price",
                          layout.tableDataEditChanging?.includes(
                            "sell_price"
                          ) && "edited",
                          "relative"
                        )}
                        id={`sell_price-${event.id}-${rIndex}`}
                        key={getUniqueID("sell_price")}
                      >
                        <div>
                          <PriceField
                            value={
                              typeof eventRecord?.sell_price === "object"
                                ? eventRecord?.sell_price?.value
                                : decimalNumber(eventRecord?.sell_price)
                            }
                            id={`${event?.id}${rIndex}sell_price`}
                            currenncySymbol={
                              eventRecord?.sell_price_currency
                                ? eventRecord?.sell_price_currency
                                : eventRecord?.sell_price?.symbol
                            }
                            placeholder={"Proceed Price"}
                            className={`${
                              eventRecord?.errors?.sell_price &&
                              "focus:border-rose-500 border-rose-500 text-rose-500"
                            }`}
                            textFielClassName={`${
                              eventRecord?.errors?.sell_price &&
                              "focus:border-rose-500 border-rose-500 text-rose-500"
                            } truncate`}
                            name="sell_price"
                            handleOnChange={(data: any) => {
                              data?.target?.value?.event?.preventDefault();
                              MemoizedHandleTableField(data, eIndex, rIndex);
                            }}
                            handleOnBlur={(data: any) => {
                              const value = decimalNumber(
                                data.target?.value?.value
                              );
                              let event = {
                                target: {
                                  name: data.target.name,
                                  value: {
                                    name: data.target.name,
                                    value: !isValidPriceFieldNumber(
                                      data.target.value.value
                                    )
                                      ? decimalNumber(0)
                                      : value,
                                    symbol: data?.target?.value?.symbol,
                                  },
                                },
                              };
                              MemoizedHandleTableField(event, eIndex, rIndex);
                            }}
                            errorClass={`${
                              eventRecord?.fieldErrors?.sell_price
                                ? eventRecord?.fieldErrors?.sell_price
                                : ""
                            }`}
                            errorMessage={`${
                              eventRecord?.fieldErrors?.sell_price
                                ? eventRecord?.fieldErrors?.sell_price
                                : ""
                            }`}
                            errorEventIndex={
                              eventRecord?.fieldErrors?.sell_price &&
                              `sell_price-${eventRecord?.id}`
                            }
                            handleOnFocus={(data: any) => {
                              focusOnPriceField(data, eIndex, rIndex);
                            }}
                            tabTiming={saveDataInStateTimer}
                            isPriceField={
                              priceFiledFocused?.data &&
                              priceFiledFocused?.eIndex === eIndex &&
                              priceFiledFocused?.rIndex === rIndex
                                ? {
                                    withoutCategory: categoryExist(
                                      event,
                                      eventRecord,
                                      "withoutcategory"
                                    ),
                                    withCategory: categoryExist(
                                      event,
                                      eventRecord,
                                      "withcategory"
                                    ),
                                    isTableField: false,
                                    isFocused: priceFiledFocused?.data,
                                  }
                                : false
                            }
                            fieldMessage={
                              eventRecord?.avg_price &&
                              categoryExist(event, eventRecord, "withcategory")
                                ? priceCalculation(
                                    typeof eventRecord?.sell_price === "object"
                                      ? eventRecord?.sell_price?.value
                                      : eventRecord?.sell_price,
                                    eventRecord?.avg_price
                                      ? Number(eventRecord?.avg_price)
                                      : null,
                                    Number(price_suggestion_factor)
                                  )
                                : false
                            }
                            isValidationPopupVisible={
                              categoryExist(
                                event,
                                eventRecord,
                                "withcategory"
                              ) && !eventRecord?.avg_price
                                ? true
                                : false
                            }
                            isPriceTable={false}
                            indexPlusLength={{
                              rIndex: rIndex,
                              recordLength: event?.eventRecords?.length,
                            }}
                            avgPrice={eventRecord?.avg_price}
                            isDisabled={
                              (isEventRecordHoldTickets &&
                                eventRecord?.quantity_available <
                                  eventRecord?.listing_ticket_files?.length) ||
                              eventRecord?.price_per_exchange ||
                              (layout?.isTableEditable?.length > 0 &&
                                (ppeAction?.somePPE ||
                                  (isGodAdmin && ppeAction?.godAdminError)))
                            }
                          />

                          {ppeErrosValidation &&
                            firstSelectedIndex?.id === eventRecord?.id && (
                              <>
                                <TooltipPortal
                                  id={`ppe-error-${rIndex}`}
                                  content={
                                    ppeAction?.godAdminError
                                      ? Messages?.bulkEditRestrictDiffTeam
                                      : Messages?.bulkEditRestrictPPE
                                  }
                                  className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium  text-xs z-[999999999] max-w-[9rem] cursor-default !text-rose-500"
                                />
                                <div
                                  id={`ppe-error-${rIndex}`}
                                  data-tooltip-id={`ppe-error-${rIndex}`}
                                  className="absolute top-1/2 -translate-y-1/2 right-4 "
                                >
                                  <ComponentSVG className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition">
                                    <Warning1 />
                                  </ComponentSVG>
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    )
                  );

                case 15:
                  return (
                    <React.Fragment key={getUniqueID("benefits")}>
                      {isALLTicketUploadedWithPublish ||
                      eventRecord?.isDisabled ? (
                        <div className="p-1.5 max-w-[13.25rem] min-w-[13.25rem]">
                          <input
                            type="text"
                            className={`bg-gray-100 truncate w-full h-6  text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none !border-gray-100 !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                            value={benefitsValue?.name}
                            placeholder="Benefits"
                            disabled={true}
                          />
                        </div>
                      ) : (
                        <div
                          className={`p-1.5 font-medium auto_width_row ${
                            layout.tableDataEditChanging?.includes(
                              "benefits"
                            ) && "edited"
                          }`}
                          key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                        >
                          <TableMultiDropdown
                            name="benefits"
                            options={layout.benefits}
                            value={benefitsValue}
                            classNamePrefix={"table_multiDropdown"}
                            placeholder={
                              benefitsValue && size(benefitsValue) > 0
                                ? ""
                                : "Benefits"
                            }
                            handleOnChange={(data: any) => {
                              MemoizedHandleTableField(data, eIndex, rIndex);
                            }}
                            isDisabled={isEventRecordHoldTickets}
                            handleOnSelection={(
                              data: any,
                              visibleOptions: any
                            ) => {
                              handleOnSelection(
                                data,
                                eIndex,
                                rIndex,
                                layout.benefits,
                                "benefits",
                                visibleOptions,
                                benefitsValue
                              );
                            }}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );

                case 16:
                  return (
                    <React.Fragment key={getUniqueID("restrictions")}>
                      {isALLTicketUploadedWithPublish ||
                      eventRecord?.isDisabled ? (
                        <div className="p-1.5 max-w-[13.25rem] min-w-[13.25rem]">
                          <input
                            type="text"
                            className={`bg-gray-100 truncate w-full h-6  text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none !border-gray-100 !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                            value={restrictionValue?.name}
                            placeholder="Restrictions"
                            disabled={true}
                          />
                        </div>
                      ) : (
                        <div
                          className={`p-1.5 font-medium auto_width_row ${
                            layout.tableDataEditChanging?.includes(
                              "restrictions"
                            ) && "edited"
                          }`}
                          key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                        >
                          <TableMultiDropdown
                            name="restrictions"
                            options={layout.restrictions}
                            value={restrictionValue}
                            classNamePrefix={"table_multiDropdown"}
                            placeholder={
                              restrictionValue && size(restrictionValue) > 0
                                ? ""
                                : "Restrictions"
                            }
                            handleOnChange={(data: any) => {
                              MemoizedHandleTableField(data, eIndex, rIndex);
                            }}
                            handleOnSelection={(
                              data: any,
                              visibleOptions: any
                            ) => {
                              handleOnSelection(
                                data,
                                eIndex,
                                rIndex,
                                layout.restrictions,
                                "restrictions",
                                visibleOptions,
                                restrictionValue
                              );
                            }}
                            isDisabled={isEventRecordHoldTickets}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );

                case 17:
                  return (
                    <div
                      className={`relative p-1.5 font-medium ${
                        layout.tableDataEditChanging?.includes(
                          "ticketsInHands"
                        ) && "edited"
                      }`}
                      key={getUniqueID("ticketsInHands")}
                    >
                      <label
                        className={` in-hand-check px-4 cursor-pointer py-1 border rounded text-center h-6 flex items-center justify-center ${
                          isEventRecordHoldTickets
                            ? `bg-gray-100 pointer-events-none`
                            : `bg-white`
                        }  ${
                          (isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled) &&
                          "pointer-events-none !bg-gray-100"
                        } `}
                      >
                        <input
                          id={`inHand-${eIndex}${rIndex}`}
                          type="checkbox"
                          className={`w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 
                        rounded-sm focus:ring-indigo-500 cursor-pointer ${
                          isEventRecordHoldTickets &&
                          `pointer-events-none disable`
                        } ${
                            isALLTicketUploadedWithPublish &&
                            "pointer-events-none "
                          } `}
                          checked={eventRecord?.ticketsInHands ? true : false}
                          name={"ticketsInHands"}
                          onChange={(data: any) => {
                            MemoizedHandleTableField(data, eIndex, rIndex);
                          }}
                          disabled={isEventRecordHoldTickets}
                        />
                      </label>
                    </div>
                  );

                case 18:
                  return (
                    <div
                      className={`relative p-1.5 font-medium ${
                        layout.tableDataEditChanging?.includes(
                          "date_to_ship"
                        ) && "edited"
                      }`}
                      key={getUniqueID("date_to_ship")}
                    >
                      <div className="w-[7.5rem]">
                        <FormDatePickerField
                          name="date_to_ship"
                          maxDate={event?.date ? new Date(event?.date) : null}
                          placeholder={`Date to ship`}
                          value={eventRecord?.date_to_ship}
                          handleOnChange={(data: any) => {
                            if (data.target.value === "") {
                              data.target.value = new Date();
                            }
                            MemoizedHandleTableField(data, eIndex, rIndex);
                            // setTimeout(() => {
                            //   setList(refreshEventsWhileActionPerform(list));
                            // }, 1);
                          }}
                          disabledKeyboardNavigation={false}
                          isDisabled={
                            isEventRecordHoldTickets ||
                            eventRecord?.ticketsInHands ||
                            isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled
                          }
                          error={
                            eventRecord?.fieldErrors?.date_to_ship
                              ? true
                              : false
                          }
                          intializeField={eventRecord?.intializeField}
                        />
                        {eventRecord?.fieldErrors?.date_to_ship && (
                          <>
                            <Tooltip
                              anchorId={`dateship-error-${eventRecord?.id}`}
                              content={eventRecord?.fieldErrors?.date_to_ship}
                              place="top"
                              variant="light"
                              className="text-center !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-10 max-w-[10rem] !text-rose-500"
                            />
                            <div
                              id={`dateship-error-${eventRecord?.id}`}
                              className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                            >
                              <ComponentSVG className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition">
                                <Warning1 />
                              </ComponentSVG>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
              }
            })}

            {/* SIDE ACTION BUTTONS - (PPP, TICKETS IN HAND, METER, PUBLISH) */}
            <ActionButtonsWrapper
              event={event}
              eventRecord={eventRecord}
              eIndex={eIndex}
              rIndex={rIndex}
              tableRefSide={tableRefSide}
              editData={editData}
              list={list}
              setList={setList}
              setEventRecord={setEventRecord}
              MemoizedHandleTableField={MemoizedHandleTableField}
              updateVisibility={updateVisibility}
              handleEticket={handleEticket}
              setEditData={setEditData}
              setIsOpenTicketPopUp={setIsOpenTicketPopUp}
              setIsOpenListingQualityPopup={setIsOpenListingQualityPopup}
              handleListingAction={handleListingAction}
              firstSelectedIndex={myIndex}
              ppeErrosValidation={ppeErrosValidation}
              ppeAction={ppeAction}
              isGodAdmin={isGodAdmin}
            />
          </div>
          {/* PPE BLOCK */}
          {eventRecord?.ppeIsOpen && (
            <PPPWrapper
              key={`${event?.id}-${eventRecord?.id}-ppp-wrapper`}
              event={event}
              eventRecord={eventRecord}
              mainStateRecord={tempEventRecord}
              eIndex={eIndex}
              rIndex={rIndex}
              MemoizedHandleTableField={MemoizedHandleTableField}
              setList={setList}
              isPriceField={{
                withoutCategory: categoryExist(
                  event,
                  eventRecord,
                  "withoutcategory"
                ),
                withCategory: categoryExist(event, eventRecord, "withcategory"),
                isTableField: false,
              }}
              firstSelectedIndex={myIndex}
              fieldMessage={
                eventRecord?.avg_price &&
                categoryExist(event, eventRecord, "withcategory")
                  ? priceCalculation(
                      typeof eventRecord?.sell_price === "object"
                        ? eventRecord?.sell_price?.value
                        : eventRecord?.sell_price,
                      eventRecord?.avg_price
                        ? Number(eventRecord?.avg_price)
                        : null,
                      Number(price_suggestion_factor)
                    )
                  : false
              }
              isValidationPopupVisible={
                categoryExist(event, eventRecord, "withcategory") &&
                !eventRecord?.avg_price
                  ? true
                  : false
              }
              indexPlusLength={{
                rIndex: rIndex,
                recordLength: event?.eventRecords?.length,
              }}
              avgPrice={eventRecord?.avg_price}
              ppeErrosValidation={ppeErrosValidation}
              ppeAction={ppeAction}
            />
          )}
          {/* ALL ERRORS, PROCESSING, TICKET HOLD, RESTRICTIONS */}
          {allErrorsProcessingAndRestrictions ? (
            <EventRecordStatus
              event={event}
              eventRecord={eventRecord}
              eIndex={eIndex}
              rIndex={rIndex}
            />
          ) : (
            <></>
          )}
        </React.Fragment>
      </div>
    );
  }
);

export default EventField;
