/* eslint-disable @typescript-eslint/no-unused-vars */
import { LayoutContext } from "components/core/LayoutProvider";
import { useContext, useState } from "react";
import fileSVG from "../../../assets/media/icons/other_icons/file.svg";
import processSVG from "../../../assets/media/icons/other_icons/pure-process.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { getThreeMonthsLater } from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import { TXContext } from "../core/TXProvider";
import { TX_TAB } from "../core/const";
import {
  getTxPayListing,
  getVCListForGodadmin,
  getVcTransactionsData,
} from "../core/requests";

const CSVExport = ({ isPDFExport = false }: any) => {
  const [loader, setLoader] = useState<boolean>();
  const layout = useContext(TXContext);
  const coreLayout = useContext(LayoutContext);
  const isGodAdmin = coreLayout.allowedAccess?.account_info?.is_god_admin;

  const downloadFile = ({ data, fileName, fileType }: any) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    setLoader(false);
  };

  const exportToCsv = (e: any) => {
    e.preventDefault();

    if (layout.activeTab === TX_TAB.wallet) {
      setLoader(true);
      const data: any = getTxPayListing({
        filters: isGodAdmin
          ? layout?.TXPayFilterHandleCopy
          : layout?.TXPayFilterHandle,
        export_to_csv: 1,
      }).then((response: any) => {
        downloadFile({
          data: response?.data.replace(/\\n/g, "\n"),
          fileName: "TX Pay.csv",
          fileType: "text/csv",
        });
      });
    } else if (layout.activeTab === TX_TAB.virtualCard) {
      setLoader(true);
      const filters = isGodAdmin
        ? { ...layout.virtualFilterHandleCopy }
        : {
            ...layout.virtualFilterHandle,
          };

      if (isPDFExport) filters.export_to_pdf = 1;
      else filters.export_to_csv = 1;

      if (isGodAdmin) {
        getVCListForGodadmin(
          isGodAdmin
            ? layout.virtualFilterHandleCopy?.activeId
            : layout.virtualFilterHandle?.activeId,
          filters,
          isGodAdmin
            ? layout.virtualFilterHandleCopy?.query
            : layout.virtualFilterHandle?.query
        )().then((response: any) => {
          let data: any = isPDFExport
            ? response
            : response?.data.replace(/\\n/g, "\n");
          let fileName: any = `Virtual-Card.${isPDFExport ? "pdf" : "csv"}`;
          let fileType: any = isPDFExport ? "application/pdf" : "text/csv";
          downloadFile({ data, fileName, fileType });
        });
      } else {
        getVcTransactionsData(
          isGodAdmin
            ? layout.virtualFilterHandleCopy?.activeId
            : layout.virtualFilterHandle?.activeId,
          filters,
          isGodAdmin
            ? layout.virtualFilterHandleCopy?.query
            : layout.virtualFilterHandle?.query
        )().then((response: any) => {
          let data: any = isPDFExport
            ? response
            : response?.data.replace(/\\n/g, "\n");
          let fileName: any = `Virtual-Card.${isPDFExport ? "pdf" : "csv"}`;
          let fileType: any = isPDFExport ? "application/pdf" : "text/csv";
          downloadFile({ data, fileName, fileType });
        });
      }
    }
  };
  let transactionDate = {
    startDate: isGodAdmin
      ? layout?.TXPayFilterHandleCopy?.transaction_date?.startDate
      : layout?.TXPayFilterHandle?.transaction_date?.startDate,
    endDate: isGodAdmin
      ? layout?.TXPayFilterHandleCopy?.transaction_date?.endDate
      : layout?.TXPayFilterHandle?.transaction_date?.endDate,
  };

  return (
    <div>
      <button
        type="button"
        className={`${
          loader && "pointer-events-none bg-gray-100 hover:bg-gray-100"
        } group inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false`}
        onClick={(event) => {
          if (
            !(layout.activeTab === TX_TAB.virtualCard) &&
            ((!transactionDate?.startDate && !transactionDate?.endDate) ||
              (transactionDate?.startDate &&
                transactionDate?.endDate &&
                getThreeMonthsLater(
                  transactionDate?.startDate,
                  transactionDate?.endDate
                )))
          ) {
            showAlert(
              "You may export upto 3 months of data at once, please select a time frame of 3 months from the Transaction date filter",
              true,
              5000
            );
          } else {
            !loader && exportToCsv(event);
          }
        }}
      >
        <IKTSVG
          className="items-center justify-center fill-violet-500  group-hover:fill-white transition animate-spin hidden mr-1"
          path={processSVG}
        />
        {loader ? (
          <IKTSVG
            className="flex items-center justify-center fill-violet-500 transition animate-spin group-hover:fill-white mr-1"
            path={processSVG}
          />
        ) : (
          <IKTSVG
            className="min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white"
            path={fileSVG}
          />
        )}
        {isPDFExport ? "Download PDF" : "Export to CSV"}
      </button>
    </div>
  );
};

export default CSVExport;
