import { size } from "lodash";
import React, { useContext } from "react";
import { default as refreshSVG } from "../../../../../assets/media/icons/other_icons/refresh.svg";
import crossSVG from "../../../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import { NEW_ORDER_INITIAL_FILTER_STATE } from "../../../../../helpers/const";
import { addCommaInNumber } from "../../../../../helpers/Functions";
import TooltipPortal from "../../../../tx_trade/table/component/TooltipPortal";
import { SalesContext } from "../../../core/SalesProvider";

interface OrderNewListingFilterChipInterface {
  paginateData?: any;
}

const OrderNewListingFilterChip: React.FC<
  OrderNewListingFilterChipInterface
> = ({ paginateData }: any) => {
  const layout = useContext(SalesContext);
  let filterData = layout.orderNewFilterHandle;

  // RESET ALL FILTERS
  const handleRefresh = () => {
    layout.setCustomNewOrderLoader(true);
    layout.setOrderNewFilterHandle(NEW_ORDER_INITIAL_FILTER_STATE);
  };

  return (
    <div className="flex flex-wrap customStyle max-md:border-t relative bg-white">
      <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center">
        {layout.customNewOrderLoader ? (
          <div className="shimmer-effect inline-block rounded">
            <span className="opacity-0">
              {Number(paginateData?.total)
                ? addCommaInNumber(paginateData?.total)
                : "0"}{" "}
              results
            </span>
          </div>
        ) : (
          <>
            {Number(paginateData?.total)
              ? addCommaInNumber(paginateData?.total)
              : "0"}{" "}
            results
          </>
        )}
      </div>
      <div className="flex items-center  justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full min-h-[3.375rem] max-sm:border-t">
        {layout.customNewOrderLoader ? (
          <div className="flex gap-5">
            <div
              className={`flex !rounded overflow-hidden ${
                layout.customNewOrderLoader && "shimmer-effect"
              }`}
              style={{ height: "1.625rem", width: "9.375rem" }}
            >
              &nbsp;
            </div>
          </div>
        ) : (
          <>
            {/* RESET ALL - FILTERS */}
            {(size(filterData?.team_id) > 0 ||
              size(filterData?.category_id) > 0 ||
              (filterData?.quantity &&
                size(filterData?.quantity) > 0 &&
                filterData?.quantity?.name != "Any") ||
              filterData?.ticket_uploaded ||
              filterData?.ticket_in_hand) && (
              <button
                type="button"
                className={`group border text-center bg-white rounded w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition `}
                onClick={handleRefresh}
                data-tooltip-id="reset"
              >
                <TooltipPortal
                  id={`reset`}
                  content={`Reset`}
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-100"
                />
                <IKTSVG
                  path={refreshSVG}
                  className="fill-violet-500 group-hover:fill-white transition"
                  svgClassName="mx-[.313rem] w-3 h-3"
                />
              </button>
            )}

            {/* TEAM ID - CHIP */}
            {size(filterData?.team_id) > 0 &&
              filterData?.team_id?.map((user: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="flex py-1 px-2.5 text-xs border rounded-full my-0.5"
                      data-te-chip-init
                      // data-te-ripple-init
                    >
                      {user?.name}
                      <button
                        type="button"
                        className="ml-2 hover:fill-indigo-500"
                        onClick={(e: any) => {
                          layout.setCustomNewOrderLoader(true);
                          layout.setOrderNewFilterHandle((currentVal: any) => {
                            let team_id = currentVal?.team_id?.filter(
                              (user: any, indexNumber: number) =>
                                index !== indexNumber
                            );
                            return {
                              ...currentVal,
                              team_id: team_id,
                              page: 1,
                            };
                          });
                        }}
                      >
                        <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                      </button>
                    </div>
                  </React.Fragment>
                );
              })}

            {/* CATEGORY -  CHIP */}
            {size(filterData?.category_id) > 0 &&
              filterData?.category_id?.map((user: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <div
                      className="flex py-1 px-2.5 text-xs border rounded-full my-0.5"
                      data-te-chip-init
                      // data-te-ripple-init
                    >
                      {user?.name}
                      <button
                        type="button"
                        className="ml-2 hover:fill-indigo-500"
                        onClick={(e: any) => {
                          layout.setCustomNewOrderLoader(true);
                          layout.setOrderNewFilterHandle((currentVal: any) => {
                            let category_id = currentVal?.category_id?.filter(
                              (user: any, indexNumber: number) =>
                                index !== indexNumber
                            );
                            return {
                              ...currentVal,
                              category_id: category_id,
                              page: 1,
                            };
                          });
                        }}
                      >
                        <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                      </button>
                    </div>
                  </React.Fragment>
                );
              })}

            {/* QUANTITY - CHIP */}
            {size(filterData?.quantity) > 0 &&
              filterData?.quantity?.name != "Any" && (
                <div
                  className="flex py-1 px-2.5 text-xs border rounded-full my-0.5"
                  data-te-chip-init
                  // data-te-ripple-init
                >
                  Quantity {filterData?.quantity?.name}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={(e: any) => {
                      layout.setCustomNewOrderLoader(true);
                      layout.setOrderNewFilterHandle((currentVal: any) => {
                        return {
                          ...currentVal,
                          quantity: NEW_ORDER_INITIAL_FILTER_STATE?.quantity,
                          page: 1,
                        };
                      });
                    }}
                  >
                    <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                  </button>
                </div>
              )}

            {/* TICKETS IN HAND - CHIP */}
            {filterData?.ticket_in_hand && (
              <div
                className="flex py-1 px-2.5 text-xs border rounded-full"
                data-te-chip-init
                // data-te-ripple-init
              >
                Tickets in hand
                <button
                  type="button"
                  className="ml-2  hover:fill-indigo-500"
                  onClick={(e: any) => {
                    layout.setCustomNewOrderLoader(true);
                    layout.setOrderNewFilterHandle((currentVal: any) => {
                      return {
                        ...currentVal,
                        ticket_in_hand: false,
                        page: 1,
                      };
                    });
                  }}
                >
                  <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                </button>
              </div>
            )}

            {/* TICKETS UPLOADED - CHIP */}
            {filterData?.ticket_uploaded && (
              <div
                className="flex py-1 px-2.5 text-xs border rounded-full"
                data-te-chip-init
                // data-te-ripple-init
              >
                Tickets uploaded
                <button
                  type="button"
                  className="ml-2  hover:fill-indigo-500"
                  onClick={(e: any) => {
                    layout.setCustomNewOrderLoader(true);
                    layout.setOrderNewFilterHandle((currentVal: any) => {
                      return {
                        ...currentVal,
                        ticket_uploaded: false,
                        page: 1,
                      };
                    });
                  }}
                >
                  <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrderNewListingFilterChip;
