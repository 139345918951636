import { LayoutContext } from "components/core/LayoutProvider";
import { unionBy } from "lodash";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import NoDataComponent from "../../../../components/tableComponent/NoDataComponent";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import { QUERIES } from "../../../../helpers/const";
import { SalesContext } from "../../core/SalesProvider";
import { suggestListingReplacement } from "../../core/_requests";
import BackToOrder from "./components/BackToOrder";
import FilterContainerNewListing from "./components/FilterContainerNewListing";
import OrderNewReplacement from "./components/OrderNewReplacement";
import OrderNewTable from "./components/OrderNewTable";

const OrderNewListing = () => {
  const layout = useContext(SalesContext);
  const coreLayout = useContext(LayoutContext);
  const [listing, setListing] = useState<any>([]);
  const [commonWallet, setCommonWallet] = useState<any>();
  const [paginateData, setPaginateData] = useState<any>();
  const [categoryOptions, setCategoryOptions] = useState<any>([]);

  let filterData = layout.orderNewFilterHandle;
  let orderAssign = layout.orderAssign?.data;
  let customNewOrderLoader = layout.customNewOrderLoader;

  // LISTING API
  const { data } = useQuery(
    [
      `${QUERIES.SUGGEST_REPLACEMENT_LISTING}`,
      orderAssign?.order_id,
      filterData,
    ],
    () =>
      suggestListingReplacement({
        ...filterData,
        order_id: orderAssign?.order_id,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: orderAssign ? true : false,
      onSettled(data, error) {
        // DISABLED THE LOADER
        layout.setCustomNewOrderLoader(false);
        setPaginateData(data?.meta);
        if (data?.data?.status) {
          // UPDATE CATEGORY - OPTIONS
          setCategoryOptions(data?.data?.categories);
          setCommonWallet(data?.data?.wallet_balance);
          // UPDATE REPLACEMENT - LIST
          setListing((current: any) => {
            if (layout.orderNewFilterHandle?.page === 1) {
              let salesreplacementTable: any = document.getElementById(
                "sales-repalcement-lazyload"
              );
              salesreplacementTable?.scrollTo(0, 0);
              return data?.data?.lists;
            } else if (layout.orderNewFilterHandle?.page > 1) {
              return unionBy([...current, ...data?.data?.lists], "id");
            }
          });
        }
      },
    }
  );

  return (
    <React.Fragment>
      <div
        className={`md:max-w-[calc(100%_-_3.75rem)] ${
          coreLayout.asideMenuOpen && "lg2:max-w-[calc(100%_-_13.875rem)]"
        } show !scrollbar-thin  !scrollbar-thumb-violet-800 !scrollbar-thumb-rounded-full scrollbar-track-gray-650 !h-full overflow-y-auto overflow-x-hidden outline-none upload-ticket-popup offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding  transition duration-300 ease-in-out font-medium z-[50] max-h-[calc(100%-3.75rem)] min-h-[calc(100%-3.75rem)] bg-gray-650 `}
      >
        <div className="relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current outline-none dark:bg-neutral-600 md:h-full">
          <div className=" main-content-wrap pt-10 min-h-full bg-gray-650 shadow-lg ">
            {/* BACK TO ORDER LISTING   */}
            <BackToOrder
              title={`Assign order to new listing : ${layout?.orderAssign?.data?.order_id}`}
            />

            <div className="inner-main-content p-5  md:h-[calc(100vh-6.875rem)] h-auto flex flex-col">
              <div className="top-filterbar rounded shadow-op2 bg-white mb-5">
                {/* FILTER CONTAINER  */}
                <FilterContainerNewListing
                  categoryOptions={categoryOptions}
                  paginateData={paginateData}
                />
              </div>

              {/* TABLE */}
              {customNewOrderLoader ? (
                <>
                  {Array.from({ length: 10 }, (v, i) => (
                    <div
                      className={`accordion font-medium  mb-2.5 ${
                        i === 0 && "mt-0"
                      } `}
                    >
                      <div
                        key={i}
                        className={`accordion-item bg-white !rounded overflow-hidden ${
                          customNewOrderLoader && "shimmer-effect"
                        } `}
                        style={{ height: "40px" }}
                      ></div>
                    </div>
                  ))}
                  <ProgressBardLoader
                    LoadingText=" Loading your data"
                    secondCounter={1.5}
                  />
                </>
              ) : listing?.length > 0 ? (
                <OrderNewTable listing={listing} paginateData={paginateData} />
              ) : (
                listing?.length === 0 &&
                !customNewOrderLoader && <NoDataComponent />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* ORDER REPLACEMENT - TABLE POPUP */}
      {layout.orderAssign?.backToOrderListingStatus && (
        <OrderNewReplacement
          setListing={setListing}
          commonWallet={commonWallet}
        />
      )}
    </React.Fragment>
  );
};

export default OrderNewListing;
