import moment from "moment";
import { useContext } from "react";
import DataCell from "../../../../tx_account/Table/component/DataCell";
import { PersonalDetailsContext } from "../../core/PersonalDetailsProvider";
import { getDemoStatus, getValidDemoDateTime } from "../../core/functions";

const ItemContent = ({ list }: any) => {
  const layout = useContext(PersonalDetailsContext);

  //  HEADER GET COLUMNS
  const getColumnItems = () => {
    return layout.columnItems?.filter(
      (columnItem) => columnItem.checked === true
    );
  };

  const getFilterIndexForCheckbox = (id: any) => {
    let index = layout.columnItems.findIndex((item: any) => item.id === id);
    return index;
  };

  return (
    <>
      {getColumnItems().map((columnItem: any) => {
        switch (columnItem.id) {
          case 0:
            return (
              <>
                {layout.columnItems[`${getFilterIndexForCheckbox(0)}`]
                  ?.checked && (
                  <DataCell
                    value={list?.name}
                    classname="block"
                    padding="pl-5"
                    width="w-[8.75rem]"
                  />
                )}
              </>
            );
          case 1:
            return (
              <>
                {layout.columnItems[`${getFilterIndexForCheckbox(1)}`]
                  ?.checked && (
                  <DataCell
                    value={list?.firstname}
                    classname="block"
                    width="w-[5.875rem]"
                  />
                )}
              </>
            );
          case 2:
            return (
              <>
                {layout.columnItems[`${getFilterIndexForCheckbox(2)}`]
                  ?.checked && (
                  <DataCell
                    value={list?.lastname ? list?.lastname : "-"}
                    classname="block"
                    width="w-[6.125rem]"
                  />
                )}
              </>
            );
          // case 3:
          //   return (
          //     <>
          //       {layout.columnItems[`${getFilterIndexForCheckbox(3)}`]
          //         ?.checked && (
          //         <DataCell
          //           value={list?.package_name ? list?.package_name : "-"}
          //           classname="block"
          //           width="w-[6.125rem]"
          //         />
          //       )}
          //     </>
          //   );
          case 4:
            return (
              <>
                {layout.columnItems[`${getFilterIndexForCheckbox(4)}`]
                  ?.checked && (
                  <DataCell
                    value={list?.status?.name}
                    classname="block"
                    width="w-[10.5rem]"
                  />
                )}
              </>
            );
          case 5:
            return (
              <>
                {layout.columnItems[`${getFilterIndexForCheckbox(5)}`]
                  ?.checked && (
                  <DataCell
                    value={list?.total_secondary_users}
                    classname="block"
                    width="w-[7.5rem]"
                  />
                )}
              </>
            );
          case 6:
            return (
              <>
                {layout.columnItems[`${getFilterIndexForCheckbox(6)}`]
                  ?.checked && (
                  <DataCell
                    value={
                      list?.register_date
                        ? moment(list?.register_date).format("DD/MM/YYYY")
                        : ""
                    }
                    classname="block"
                    width="w-[7.5rem]"
                  />
                )}
              </>
            );
          case 7:
            return (
              <>
                {layout.columnItems[`${getFilterIndexForCheckbox(7)}`]
                  ?.checked && (
                  <DataCell
                    value={
                      list?.last_login
                        ? moment(list?.last_login).format("DD/MM/YYYY HH:MM")
                        : ""
                    }
                    classname="block"
                    width="w-[7.5rem]"
                  />
                )}
              </>
            );
          // case 8:
          //   return (
          //     <>
          //       {layout.columnItems[`${getFilterIndexForCheckbox(8)}`]
          //         ?.checked && (
          //         <DataCell
          //           value={sentenceCase(list?.risk_level_name)}
          //           classname="block"
          //           width="w-[7.5rem]"
          //         />
          //       )}
          //     </>
          //   );
          case 9:
            return (
              <>
                {layout.columnItems[`${getFilterIndexForCheckbox(9)}`]
                  ?.checked && (
                  <DataCell
                    value={list?.tx_trade_user ? "Buyer" : "Seller"}
                    classname="block"
                    width="w-[7.5rem]"
                  />
                )}
              </>
            );
          case 10:
            return (
              <>
                {layout.columnItems[`${getFilterIndexForCheckbox(10)}`]
                  ?.checked && (
                  <DataCell
                    value={`${
                      list?.seller_level_name
                        ? `${list?.seller_level_name}`
                        : "-"
                    } `}
                    classname="block"
                    width="w-[7.5rem]"
                  />
                )}
              </>
            );

          case 11:
            return (
              <DataCell
                id={Date.now() + Math.random()}
                value={getDemoStatus(list)}
                classname="block"
                width="w-auto"
                infoIconValue={getValidDemoDateTime(list, true)}
              />
            );
        }
      })}
    </>
  );
};

export default ItemContent;
