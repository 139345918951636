const Header = (data: any, className = "") => {
  return (
    <div
      className={
        data?.headerClassName ?? "evt_detail flex items-center border-b -mb-px"
      }
    >
      <h5 className="ellips-text text-sm13 font-semibold max-w-[18.75rem] md:px-5 px-2.5 p-3 leading-4 flex items-center">
        {/* {paneltyToggle && ( */}
        {data?.checkbox && (
          <label className="relative inline-flex items-center cursor-pointer false [&.disabled>div:after]:bg-gray-400 [&.disabled]:pointer-events-none mr-2.5">
            <input
              type="checkbox"
              // name="addAllEvents"
              value=""
              className="sr-only peer"
              checked={data?.checkboxValue}
              onChange={data?.handleCheckbox}
            />
            <div
              className={`bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500"
                          } w-[1.875rem] h-3.5  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-[1.125rem] after:w-[1.125rem] after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8  bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 relative`}
            ></div>
          </label>
        )}
        {/* )} */}
        {data?.title}
      </h5>
    </div>
  );
};

export default Header;
