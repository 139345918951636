import NoDataComponent from "components/tableComponent/NoDataComponent";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import TableShimmer from "pages/subscribe/personal_details/component/TableShimmer";

const TableWrapper = ({ loading, listings, loadingText, children }: any) => {
  if (loading) {
    return (
      <>
        <TableShimmer loading={loading} />
        <ProgressBardLoader LoadingText={loadingText} secondCounter={2} />
      </>
    );
  } else if (listings && listings?.length > 0) {
    return children;
  } else {
    return <NoDataComponent />;
  }
};

export default TableWrapper;
