/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Select, { components } from "react-select";
// import AsyncSelect from "react-select/async";
import SpinnerSVG from "../../../../assets/media/icons/other_icons/spinner.svg";
import crossIcon from "../../../../assets/media/icons/standard_icons/SearchR.svg";
import CalendarSVG from "../../../../assets/media/icons/standard_icons/calendar.svg";
import ClockOutlineSVG from "../../../../assets/media/icons/standard_icons/clock-outline.svg";
import searchIcon from "../../../../assets/media/icons/standard_icons/search-violet.svg";

import { IKTSVG } from "components/ui/IKTSVG";
import { isMobileView } from "helpers/Functions";
import { MULTI_SELECT_INPUT } from "helpers/const";
import { isArray } from "lodash";
import { convertDate, convertTime } from "pages/add_inventory/core/_functions";
import React from "react";
import SVG from "react-inlinesvg";
import GEOSVG from "../../../../assets/media/icons/standard_icons/geo_icon.svg";

// STYLING
const customStyle = {
  control: (base: any, state: any) => {
    return {
      ...base,
      display: "flex",
      flexDirection: "row-reverse",
      border: state.isFocused
        ? "2px solid #6f6cff"
        : state.isDisabled
        ? "none"
        : "2px solid lightgrey",
      minHeight: "1px",
      backgroundColor: state.isFocused
        ? "#E9E9FF"
        : state.isDisabled
        ? "#F2F2F2"
        : "white",
      height: "2.5rem",
      padding: "1px 8px",
      borderWidth: "2px",
      borderRadius: "4px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid",
        borderColor: "#6f6cff ",
        borderWidth: "2px",
      },
    };
  },
  container: (provided: any, state: any) => {
    return {
      ...provided,

      // marginTop: 50,
      // width:
      //   state.selectProps.name === "upcomingEvents" && state.isFocused
      //     ? "100%"
      //     : state.selectProps.name === "tableSearchField"
      //     ? "100%"
      //     : "70%",
      // transition: "all 0.3s ease",
    };
  },
  option: (base: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      // ...base,
      // padding:'6px',
      // paddingLeft:'8px',
      // paddingRight:'8px'
      // backgroundColor: isFocused ? "#E9E9FF" : "white",
      // color: isFocused ? "black" : "black",
      // "&:hover": {
      //   color: "black",
      // },
    };
  },
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      overflow: "visible",
    };
  },
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
  placeholder: (base: any) => {
    if (isMobileView) {
      return {
        ...base,
        pointerEvents: "none",
        userSelect: "none",
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
      };
    } else {
      return base;
    }
  },
  input: (base: any) => {
    if (isMobileView) {
      return {
        ...base,
        ...MULTI_SELECT_INPUT,
      };
    } else {
      return { ...base, ...MULTI_SELECT_INPUT };
    }
  },
};

// CONTROL - COMPONENT
const Control = ({ children, ...props }: any) => {
  // @ts-ignore
  const { onButtonClick, styles }: any = props.selectProps;

  const [selectedOption, setSelectedOption] = useState("Event");
  const { buttonTypes } = props?.selectProps;

  useEffect(() => {
    if (styles?.typeComponent?.name)
      setSelectedOption(styles?.typeComponent?.name);
  }, [styles?.typeComponent?.name]);

  const handleOptionChange = (option: any) => {
    const { innerText: name, control } = option?.target;
    const id = control?.name;
    setSelectedOption(name);
    if (onButtonClick) onButtonClick({ name: name, id: id });
  };

  return (
    <components.Control {...props} className="pl-2">
      <button
        className="search_btn"
        type="button"
        id="asdasd"
        onClick={(e: any) =>
          props.selectProps.inputValue ? onButtonClick(e) : null
        }
        onTouchStart={(e: any) =>
          props.selectProps.inputValue ? onButtonClick(e) : null
        }
        onMouseDown={(e: any) =>
          !props.selectProps.inputValue && onButtonClick(e)
        }
      >
        <SVG
          className="w-3.5"
          id={props.selectProps.inputValue ? "cross" : "search"}
          src={props.selectProps.inputValue ? crossIcon : searchIcon}
        />
      </button>
      {children}

      {!styles?.hideCategoryButtons && buttonTypes?.length > 1 ? (
        <div
          className={`w-auto inline-flex  p-0.5 mr-0.5 mb-3 !my-0 border border-indigo-500/20 rounded bg-white pointer-events-none`}
          onMouseDown={handleOptionChange}
          onTouchStart={handleOptionChange}
        >
          {buttonTypes?.map((item: any, index: number) => {
            return (
              <div
                className="relative flex items-center sm:flex-0 flex-auto pointer-events-auto"
                key={index}
              >
                <input
                  id={`${props.selectProps?.name}-${index}`}
                  type="radio"
                  name={props.selectProps?.name}
                  className="absolute hidden peer"
                  checked={selectedOption === item}
                  readOnly
                />
                <label
                  htmlFor={`${props.selectProps?.name}-${index}`}
                  className="py-0.5 px-1 w-full text-center rounded text-indigo-500 peer-checked:text-white peer-checked:bg-indigo-500 peer-checked:shadow transition text-xxs cursor-pointer"
                >
                  {" "}
                  {item}
                </label>
              </div>
            );
          })}
        </div>
      ) : null}
    </components.Control>
  );
};

// LOADING MESSAGE COMPONENT
const LoadingMessage = () => {
  return (
    <React.Fragment>
      <IKTSVG
        svgClassName="fill-indigo-500 h-4 w-4 center mb-4"
        path={SpinnerSVG}
      />
    </React.Fragment>
  );
};

// OPTIONS COMPONENT
const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: any) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);
  const { buttonTypes } = rest?.selectProps;

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
  };

  const selectedOpt = useMemo(
    () =>
      isArray(rest?.selectProps?.options) &&
      rest?.selectProps?.options.find(
        (opt: any) => opt?.id === rest?.selectProps?.value?.id
      ),
    [rest?.selectProps?.options, rest?.selectProps?.value?.id]
  );

  const isOptionHighligted = useMemo(
    () => selectedOpt?.id === rest.data?.id,
    [rest.data?.id, selectedOpt?.id]
  );

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <ul className="p-0 list-none">
        {buttonTypes?.[0] === "Event" &&
        rest?.typeComponent?.name === "Event" ? (
          <li>
            <span
              className={`${
                isSelected && "checked"
              } search__item mb-2.5 flex flex-wrap m-1 border rounded-sm duration-150 cursor-pointer ${
                isOptionHighligted &&
                "bg-indigo-500 bg-opacity-[7%] text-indigo-500"
              } `}
            >
              <div className="flex flex-wrap w-full">
                <div
                  className={`flex flex-wrap flex-1 max-w-${
                    rest.isMulti ? "[calc(100%-1.875rem)]" : "100%"
                  }`}
                >
                  <span className="search__item__title px-2 py-1 text-xs first:w-full first:border-r-0 first:border-b border-r">
                    {rest.data.name}
                  </span>
                  <span className="inline-flex items-center px-2 py-1 text-xs border-r text-xxs">
                    <IKTSVG
                      path={CalendarSVG}
                      className={`pr-1.5`}
                      svgClassName={`w-3 h-3 ${
                        isOptionHighligted && "fill-indigo-500"
                      }`}
                    />
                    <span className="">
                      {rest.data.event_date_local
                        ? convertDate(rest.data.event_date_local)
                        : "N/A"}{" "}
                    </span>
                  </span>
                  <span className="inline-flex items-center px-2 py-1 text-xs border-r text-xxs">
                    <IKTSVG
                      path={ClockOutlineSVG}
                      className={`pr-1.5`}
                      svgClassName={`w-3 h-3 ${
                        isOptionHighligted && "fill-indigo-500"
                      }`}
                    />
                    <span className="">
                      {rest.data.event_date_local
                        ? convertTime(rest.data.event_date_local)
                        : "N/A"}
                    </span>
                  </span>
                  <span className="inline-flex items-center flex-1 px-2 py-1 text-xs border-r-0 text-xxs ellips-text max-w-[7.875rem]">
                    <IKTSVG
                      path={GEOSVG}
                      className={`pr-1.5`}
                      svgClassName={`w-3 h-3 ${
                        isOptionHighligted && "fill-indigo-500"
                      }`}
                    />
                    <span className="ellips-text">
                      <p title={rest.data.venue_name}>{rest.data.venue_name}</p>
                    </span>
                  </span>
                </div>
                {rest?.isMulti && (
                  <div className="w-[1.875rem] flex items-center justify-center border-l flex-[0_0_30px]">
                    <input
                      type="checkbox"
                      checked={isSelected}
                      readOnly
                      className="w-3 h-3 text-violet-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0   dark:bg-gray-700 dark:border-gray-600 peer form-check-input checked:bg-indigo-500 transition  cursor-pointer border checked:border-indigo-500 hover:border-indigo-500"
                    />
                  </div>
                )}
              </div>
            </span>
          </li>
        ) : (
          <li>
            <span className="search__item mb-2.5 flex flex-wrap m-1 border rounded-sm duration-150">
              <span
                className={`search__item__title px-2 py-1 text-xs first:w-full first:border-r-0 border-r ${
                  isOptionHighligted &&
                  "bg-indigo-500 bg-opacity-[7%] text-indigo-500"
                } `}
              >
                {rest.data.name}
              </span>
            </span>
          </li>
        )}
      </ul>
    </components.Option>
  );
};

// MENU LIST - COMPONENT
const MenuList = (props: any) => {
  return (
    <components.MenuList
      {...props}
      className="scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
    >
      {props.children}
    </components.MenuList>
  );
};

const MultiSelectWithButtons = React.memo(
  ({
    name,
    placeholder,
    options,
    handleOnChange,
    value,
    className,
    classNamePrefix,
    handleInputChange,
    inputValue,
    onSearch,
    handleKeyDown,
    isLoading,
    onMenuScrollToBottom,
    isMulti = true,
    isMenuPortalTarget = undefined,
    typeComponent,
    closeMenuOnSelect = false,
    hideCategoryButtons = false,
    buttonTypes,
    handleIsMenuFocused,
  }: any) => {
    const selectRef: any = useRef();

    // SEARCH ON CLICK - BUTTON
    const onButtonClick = (e: any) => {
      onSearch(e);
    };

    const [isMenuOpen, setIsMenuOpen] = useState<any>(false);
    const [algoliaOptions, setAlgoliaOptions] = useState<any>();
    useEffect(() => {
      if (options && options?.length > 0) {
        setAlgoliaOptions(options);
      } else {
        setAlgoliaOptions([]);
      }
    }, [options, inputValue]);

    const getData = useCallback(() => {
      return options;
    }, [options]);

    const [menuPlacement, setMenuPlacement] = useState<any>("auto");

    useEffect(() => {
      const adjustMenuPlacement = () => {
        const selectElement = selectRef.current?.controlRef; // Accessing the DOM element here
        if (selectElement) {
          const boundingRect = selectElement.getBoundingClientRect();
          const spaceAbove = boundingRect.top;
          const spaceBelow = window.innerHeight - boundingRect.bottom;

          if (spaceBelow < 200 && spaceAbove > spaceBelow) {
            setMenuPlacement("top");
          } else {
            setMenuPlacement("bottom");
          }
        }
      };

      adjustMenuPlacement();
      window.addEventListener("resize", adjustMenuPlacement);

      return () => {
        window.removeEventListener("resize", adjustMenuPlacement);
      };
    }, []);

    return (
      <>
        <Select
          // @ts-ignore
          onButtonClick={(e: any) => onButtonClick(e)}
          components={{
            Control,
            MenuList,
            Option: (props: any) => {
              return <InputOption {...props} typeComponent={typeComponent} />;
            },
            // Option: InputOption,
            LoadingMessage,
          }}
          ref={selectRef}
          name={name}
          placeholder={placeholder}
          options={getData() !== false && getData()}
          // @ts-ignore
          isMulti={isMulti}
          value={value !== null ? value : value === null ? null : undefined}
          inputValue={inputValue}
          classNamePrefix={classNamePrefix}
          className={className}
          isLoading={isLoading}
          onChange={(selectedOption: any, e: any) => {
            let event = { target: { name: name, value: selectedOption } };
            if (!isMulti && e?.action !== "clear") {
              setIsMenuOpen(false);
            }
            handleOnChange(event, e);
          }}
          onFocus={() => {
            setIsMenuOpen(true);
            handleIsMenuFocused(true);
          }}
          onBlur={() => {
            setIsMenuOpen(false);
            handleIsMenuFocused(false);
          }}
          menuIsOpen={!isMulti ? isMenuOpen : undefined}
          noOptionsMessage={() =>
            (!algoliaOptions || algoliaOptions?.length === 0) && null
          }
          onKeyDown={(event: any) => {
            handleKeyDown(event);
          }}
          onInputChange={(data: any, event: any) =>
            handleInputChange(data, event, name)
          }
          onMenuScrollToBottom={() => onMenuScrollToBottom(true)}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.id}
          isSearchable
          closeMenuOnSelect={closeMenuOnSelect}
          isClearable={true}
          blurInputOnSelect={true}
          // menuIsOpen={true}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          menuShouldScrollIntoView={false}
          // menuPlacement="top"
          menuPlacement={menuPlacement}
          // @ts-ignore
          styles={{ ...customStyle, typeComponent, hideCategoryButtons }}
          filterOption={null}
          tabSelectsValue={false}
          isMenuPortalTarget={document.body}
          menuPosition="fixed"
          buttonTypes={buttonTypes}
        />
      </>
    );
  }
);

export default MultiSelectWithButtons;
