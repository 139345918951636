import { useEffect } from "react";

const PopupFooter = ({ paginate }: any) => {
  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader-2");
    if (
      paginate?.current_page === paginate?.last_page &&
      paginate?.current_page !== undefined &&
      paginate?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginate?.current_page !== paginate?.last_page &&
      paginate?.current_page < paginate?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginate]);

  return (
    <>
      {/* LOADER */}
      <div id="custom-loader-2" className={`accordion bg-white font-medium`}>
        <div
          className={`accordion-item bg-white m-5 md:gap-y-4 gap-y-2.5 p-2.5 md:p-5 !rounded overflow-hidden shimmer-effect`}
          style={{ height: "4.4375rem" }}
        ></div>
        <div
          className={`accordion-item bg-white m-5 md:gap-y-4 gap-y-2.5 p-2.5 md:p-5 !rounded overflow-hidden shimmer-effect`}
          style={{ height: "4.4375rem" }}
        ></div>
      </div>
    </>
  );
};

export default PopupFooter;
