import { IKTSVG } from "components/ui/IKTSVG";
import { Tooltip } from "react-tooltip";
import { default as crossArrow } from "../../assets/media/icons/standard_icons/cross.svg";

const HeaderDetail = ({
  label,
  labelClass,
  title,
  handleClosePopup,
  tooltipId = `close`,
}: any) => {
  return (
    <div className="flex justify-between pl-5 items-center border-b">
      <div>
        <span
          className={`md:text-sm15 text-sm12 block font-semibold ${labelClass}`}
        >
          {" "}
          {label} {title}
        </span>
      </div>
      <div className="flex flex-wrap">
        <button
          type="button"
          className="group relative mail flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          data-tooltip-id={tooltipId}
          onClick={() => {
            handleClosePopup();
          }}
        >
          <IKTSVG
            className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition"
            path={crossArrow}
            svgClassName="w-2.5 h-2.5"
          />

          <Tooltip
            id={tooltipId}
            content={`Close`}
            place={"left"}
            variant="light"
            className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
          />
        </button>
      </div>
    </div>
  );
};

export default HeaderDetail;
