import { createContext, PropsWithChildren, useContext, useState } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../helpers/AssetHelpers";
import {
  PAYOUT_VIEW_INTIALSTATE,
  TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER,
  TX_ACCOUNT_COLUMN,
  TX_ACCOUNT_FILTER,
  TX_ACCOUNT_INVOICE_INITIAL_FILTER,
  TX_ACCOUNT_INVOICE_VIEW_DATA,
  TX_ACCOUNT_OPTIONS_STATE,
  TX_ACCOUNT_ORDER_DRAFT_INITIAL_STATE,
  TX_ACCOUNT_ORDER_INITIAL_FILTER,
  TX_ACCOUNT_ORDER_PAYOUT_SELLER,
  TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
  TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER,
  TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
} from "../../../helpers/const";
import { tabConditions } from "./functions";

export interface LayoutContextModel {
  // GENERAL
  isTabActive: any;
  setTabActive: (data: any) => void;

  filterItems: Array<any>;
  setFilterItems: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;

  customLoader: boolean;
  setCustomLoader: (data: any) => void;

  filterOptions: any;
  setFilterOptions: (data: any) => void;

  currencyBalance: any;
  setCurrencyBalance: (data: any) => void;

  // ORDER
  orderList: any;
  setOrderList: (data: any) => void;

  orderFilters: any;
  setOrderFilters: (data: any) => void;

  ordersOverViewDetails: any;
  setOrdersOverViewDetails: (data: any) => void;

  commonConfirmation: any;
  setCommonConfirmation: (data: any) => void;

  orderPayoutSeller: any;
  setOrderPayoutSeller: (data: any) => void;

  // INVOICE
  invoiceList: any;
  setInvoiceList: (data: any) => void;

  invoicesFilter: any;
  setInvoicesFilter: (data: any) => void;

  invoicesViewData: any;
  setInvoicesViewData: (data: any) => void;

  invoiceOverViewDetails: any;
  setInvoiceOverViewDetails: (data: any) => void;

  // PAYOUT
  payOutsFilter: any;
  setPayOutFilter: (data: any) => void;

  payoutList: any;
  setPayoutList: (data: any) => void;

  payoutsOverViewDetails: any;
  setPayoutsOverViewDetails: (data: any) => void;

  draggedColumns: Array<any>;
  setDraggedColumns: (data: any) => void;

  columnItems: Array<any>;
  setColumnItems: (data: any) => void;

  payoutViewData: any;
  setPayoutViewData: (data: any) => void;

  // SELLER WALLETS
  sellerWalletsFilter: any;
  setSellerWalletsFilter: (data: any) => void;

  sellerWalletsList: any;
  setSellerWalletsList: (data: any) => void;

  sellerWalletsMeta: any;
  setSellerWalletsMeta: (data: any) => void;

  // Bank accounts
  bankAccountFilter: any;
  setBankAccountFilter: (data: any) => void;

  bankAccountList: any;
  setBankAccountList: (data: any) => void;

  bankAccountMeta: any;
  setBankAccountMeta: (data: any) => void;

  // WITHDRAW REQUEST
  withdrawRequestFilter: any;
  setWithdrawRequestFilter: (data: any) => void;

  withdrawRequestList: any;
  setWithdrawRequestList: (data: any) => void;

  withdrawRequestMeta: any;
  setWithdrawRequestMeta: (data: any) => void;

  isSelectAll: any;
  setIsSelectAll: (data: any) => void;

  commonFilter: any;
  setCommonFilter: (data: any) => void;
}

const TxAccountContext = createContext<LayoutContextModel>({
  // GENERAL
  isTabActive: {},
  setTabActive: (data: any) => {},

  filterItems: [],
  setFilterItems: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},

  customLoader: false,
  setCustomLoader: (data: any) => {},

  filterOptions: [],
  setFilterOptions: (data: any) => {},

  currencyBalance: [],
  setCurrencyBalance: (data: any) => {},

  // ORDER
  orderList: [],
  setOrderList: (data: any) => {},

  orderFilters: {},
  setOrderFilters: (data: any) => {},

  ordersOverViewDetails: {},
  setOrdersOverViewDetails: (data: any) => {},

  commonConfirmation: {},
  setCommonConfirmation: (data: any) => {},

  orderPayoutSeller: {},
  setOrderPayoutSeller: (data: any) => {},

  // INVOICE
  invoiceList: [],
  setInvoiceList: (data: any) => {},

  invoicesFilter: {},
  setInvoicesFilter: (data: any) => {},

  invoicesViewData: {},
  setInvoicesViewData: (data: any) => {},

  invoiceOverViewDetails: {},
  setInvoiceOverViewDetails: (data: any) => {},

  // PAYOUT
  payOutsFilter: {},
  setPayOutFilter: (data: any) => {},

  payoutList: [],
  setPayoutList: (data: any) => {},

  payoutsOverViewDetails: [],
  setPayoutsOverViewDetails: (data: any) => {},

  draggedColumns: [],
  setDraggedColumns: (data: any) => {},

  columnItems: [],
  setColumnItems: (data: any) => {},

  payoutViewData: {},
  setPayoutViewData: (data: any) => {},

  // SELLER WALLETS
  sellerWalletsFilter: {},
  setSellerWalletsFilter: (data: any) => {},

  sellerWalletsList: [],
  setSellerWalletsList: (data: any) => {},

  sellerWalletsMeta: [],
  setSellerWalletsMeta: (data: any) => {},

  // BANK ACCOUNT
  bankAccountFilter: {},
  setBankAccountFilter: (data: any) => {},

  bankAccountList: [],
  setBankAccountList: (data: any) => {},

  bankAccountMeta: [],
  setBankAccountMeta: (data: any) => {},

  // WITHDRAW REQUEST
  withdrawRequestFilter: {},
  setWithdrawRequestFilter: (data: any) => {},

  withdrawRequestList: [],
  setWithdrawRequestList: (data: any) => {},

  withdrawRequestMeta: [],
  setWithdrawRequestMeta: (data: any) => {},

  isSelectAll: [],
  setIsSelectAll: (data: any) => {},

  commonFilter: {},
  setCommonFilter: (data: any) => {},
});

const TxAccountProvider = ({ children }: PropsWithChildren) => {
  const globalLayout = useContext(LayoutContext);

  // GENERAL
  const [isTabActive, setTabActive] = useState<string>("Orders");

  const tabCondition = tabConditions(isTabActive, "filter");

  // FILTER - CONDITION
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.[
      `tx_account_filter_${tabCondition}`
    ];

  let item = getHeaderFilter(
    `tx_account_filter_${tabCondition}`,
    isFilterExistInDB,
    TX_ACCOUNT_FILTER
  );

  item = item?.filter((obj: any) => {
    return obj?.tab.includes(tabCondition);
  });

  const [filterItems, setFilterItems] = useState<any>(() => {
    return item;
  });
  const [draggedItems, setDraggedItems] = useState(filterItems);
  const [customLoader, setCustomLoader] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<any>(
    TX_ACCOUNT_OPTIONS_STATE
  );
  const [draggedColumns, setDraggedColumns] = useState([]);

  const tabConditionColumn = tabConditions(isTabActive, "column");

  // FILTER - CONDITION
  let isColumnExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.[
      `tx_account_column_${tabConditionColumn}`
    ];

  let itemColumn = getHeaderFilter(
    `tx_account_column_${tabConditionColumn}`,
    isColumnExistInDB,
    TX_ACCOUNT_COLUMN
  );

  const [columnItems, setColumnItems] = useState(() => {
    return itemColumn;
  });
  const [currencyBalance, setCurrencyBalance] = useState({
    overview: undefined,
  });

  // ORDER
  const [orderList, setOrderList] = useState<any>([]);
  const [orderFilters, setOrderFilters] = useState(
    TX_ACCOUNT_ORDER_INITIAL_FILTER
  );
  const [commonConfirmation, setCommonConfirmation] = useState<any>(
    TX_ACCOUNT_ORDER_DRAFT_INITIAL_STATE
  );
  const [ordersOverViewDetails, setOrdersOverViewDetails] = useState<any>();
  const [orderPayoutSeller, setOrderPayoutSeller] = useState<any>(
    TX_ACCOUNT_ORDER_PAYOUT_SELLER
  );

  // INVOICE
  const [invoiceList, setInvoiceList] = useState<any>([]);
  const [invoicesFilter, setInvoicesFilter] = useState<any>(
    TX_ACCOUNT_INVOICE_INITIAL_FILTER
  );
  const [invoicesViewData, setInvoicesViewData] = useState<any>(
    TX_ACCOUNT_INVOICE_VIEW_DATA
  );
  const [invoiceOverViewDetails, setInvoiceOverViewDetails] = useState<any>();

  // PAYOUT
  const [payOutsFilter, setPayOutFilter] = useState<any>(
    TX_ACCOUNT_PAYOUT_INTIAL_FILTER
  );
  const [payoutList, setPayoutList] = useState<any>([]);
  const [payoutsOverViewDetails, setPayoutsOverViewDetails] = useState<any>();
  const [payoutViewData, setPayoutViewData] = useState<any>(
    PAYOUT_VIEW_INTIALSTATE
  );

  // SELLER WALLETS
  const [sellerWalletsFilter, setSellerWalletsFilter] = useState<any>(
    TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER
  );
  const [sellerWalletsList, setSellerWalletsList] = useState<any>([]);
  const [sellerWalletsMeta, setSellerWalletsMeta] = useState<any>();

  // BANK ACCOUNT
  const [bankAccountFilter, setBankAccountFilter] = useState<any>(
    TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER
  );
  const [bankAccountList, setBankAccountList] = useState<any>();
  const [bankAccountMeta, setBankAccountMeta] = useState<any>();

  // WITHDRAW REQUEST
  const [withdrawRequestFilter, setWithdrawRequestFilter] = useState<any>(
    TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER
  );
  const [withdrawRequestList, setWithdrawRequestList] = useState<any>();
  const [withdrawRequestMeta, setWithdrawRequestMeta] = useState<any>();
  const [isSelectAll, setIsSelectAll] = useState<any>({
    status: "",
  });

  const [commonFilter, setCommonFilter] = useState<any>({
    orderFilters: TX_ACCOUNT_ORDER_INITIAL_FILTER,
    orderPayoutSeller: TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
    invoicesFilter: TX_ACCOUNT_INVOICE_INITIAL_FILTER,
  });

  // CONTEXT VALUE
  const value: LayoutContextModel = {
    isTabActive,
    setTabActive,
    filterItems,
    setFilterItems,
    draggedItems,
    setDraggedItems,
    customLoader,
    setCustomLoader,
    filterOptions,
    setFilterOptions,
    draggedColumns,
    setDraggedColumns,
    columnItems,
    setColumnItems,
    currencyBalance,
    setCurrencyBalance,

    // ORDER
    orderList,
    setOrderList,
    orderFilters,
    setOrderFilters,
    ordersOverViewDetails,
    setOrdersOverViewDetails,
    commonConfirmation,
    setCommonConfirmation,
    orderPayoutSeller,
    setOrderPayoutSeller,

    // INVOICE
    invoiceList,
    setInvoiceList,
    invoicesFilter,
    setInvoicesFilter,
    invoicesViewData,
    setInvoicesViewData,
    invoiceOverViewDetails,
    setInvoiceOverViewDetails,

    // PAYOUT
    payOutsFilter,
    setPayOutFilter,
    payoutList,
    setPayoutList,
    payoutsOverViewDetails,
    setPayoutsOverViewDetails,
    payoutViewData,
    setPayoutViewData,

    // SELLER WALLET
    sellerWalletsFilter,
    setSellerWalletsFilter,
    sellerWalletsList,
    setSellerWalletsList,
    sellerWalletsMeta,
    setSellerWalletsMeta,

    // BANK ACCOUNT
    bankAccountFilter,
    setBankAccountFilter,
    bankAccountList,
    setBankAccountList,
    bankAccountMeta,
    setBankAccountMeta,

    // WITHDRAW REQUEST
    withdrawRequestFilter,
    setWithdrawRequestFilter,
    withdrawRequestList,
    setWithdrawRequestList,
    withdrawRequestMeta,
    setWithdrawRequestMeta,
    isSelectAll,
    setIsSelectAll,
    commonFilter,
    setCommonFilter,
  };

  return (
    <TxAccountContext.Provider value={value}>
      {children}
    </TxAccountContext.Provider>
  );
};
export { TxAccountContext, TxAccountProvider };
