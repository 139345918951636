import { isMobile } from "helpers/const";
import moment from "moment";
import { TX_PAY_TAB_ROUTES } from "./const";

export const display = (isOpen: boolean) => {
  var body = document.querySelector("body");
  var html = document.querySelector("html");
  var pop_width = document.getElementsByClassName("pay-detail")[0]?.clientWidth;
  if (isOpen) {
    // @ts-ignore
    document.getElementById("TXAccordion").style.marginRight = `${
    isMobile ? 
      pop_width : pop_width - 20
    }px`;
    // document.body.style.overflow = 'hidden';
    body?.classList.add("scrollbar-none");
    html?.classList.add("scrollbar-none");
  } else if (!isOpen) {
    // @ts-ignore
    document.getElementById("TXAccordion").style.marginRight = "0px";
    // document.body.style.overflow = 'unset';
    body?.classList.add("scrollbar-none");
    html?.classList.add("scrollbar-none");
  }
};

export const convertIntoDateFormat = (date: any, format?: any) => {
  if (!date) return null;

  return moment(date).format(format ?? "YYYY-MM-DD");
};

export const isVirtualCardTab = (pathname: any) =>
  Boolean(pathname === `/tx-pay${TX_PAY_TAB_ROUTES.virtualCard}`);

export const isCardPending = (card: any) => Boolean(card?.status === "Pending");
