import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { checkWeightageOfEvent } from "helpers/AssetHelpers";
import { LISTINGS_UTILS } from "helpers/const";
import { showAlert } from "helpers/ShowAlert";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import CalenderSVG from "../../../assets/media/icons/standard_icons/calendar.svg";
import Card from "../../../components/ui/Card";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { getTopSellingEvents } from "../core/requests";

const flattenOptions = (channels: any) => {
  let options: any = [];

  const flatten = (channel: any, depth = 0, parentName = "") => {
    options.push({
      id: channel.id,
      name: channel.name,
      depth: depth, // Add indentation based on depth
      parent: parentName,
    });

    if (channel.children && channel.children.length > 0) {
      channel.children.forEach((child: any) =>
        flatten(child, depth + 1, channel.name)
      );
    }
  };
  channels?.forEach((channel: any) => flatten(channel));
  return options;
};

const TopSellingCard = ({
  isLoadingTopSelling,
  data,
  options,
  metaDataTopSelling,
  topSellingEventHandle,
  setTopSellingEventHandle,
  isGodAdmin,
  selectedTeams,
  categoryLoading = false,
  coreLayout,
}: any) => {
  const navigate = useNavigate();
  const [error, setError] = useState<any>();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [metaData, setMetaData] = useState(metaDataTopSelling);
  const [createListingLoader, setCreateListingLoader] = useState<any>(false);
  const sellerData = coreLayout?.allowedAccess?.team_data?.seller_level;

  const durationOptions = [
    { id: 7, name: "Last 7 days" },
    { id: 15, name: "Last 15 days" },
    { id: 30, name: "Last 30 days" },
    { id: 60, name: "Last 60 days" },
  ];

  let value2 = durationOptions?.find(
    (item: any) => topSellingEventHandle?.duration === item.id
  );

  const infiniteScrollRef = useRef<any>(null);

  // HANDLE THE DROPDOWN CHANGE
  const handleOnChange = (data: any) => {
    setPage(1);
    setTopSellingEventHandle((current: any) => {
      return {
        ...current,
        [data?.target?.name]: data?.target?.value,
        page: 1,
      };
    });
  };

  // HANDLE THE HANDLEDAYSCHANGE DROPDOWN CHANGE
  const handleDaysChange = (data: any) => {
    setPage(1);
    setTopSellingEventHandle((current: any) => {
      return {
        ...current,
        [data?.target?.name]: data?.target?.value?.id,
        page: 1,
      };
    });
  };

  // FETCH MORE DATA
  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let prevPage: number = page + 1;
      getTopSellingEvents({
        page: prevPage,
        team_id: selectedTeams,
        data: topSellingEventHandle,
      }).then((response: any) => {
        if (response) {
          setItems((prevItems: any) => [
            ...prevItems,
            ...response?.data?.event_sell_list,
          ]);
          setPage(prevPage);
          setIsLoading(false);
          setMetaData(response?.data?.meta);
        }
      });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setItems(data);
  }, [data]);

  useEffect(() => {
    setMetaData(metaDataTopSelling);
  }, [metaDataTopSelling]);

  const [categoryOptions, setCategoryOptions] = useState<any>(options);

  useEffect(() => {
    if (options) {
      setCategoryOptions(options);
      setTopSellingEventHandle((current: any) => {
        let topSellingExist = options?.find(
          (item: any) => item?.id === current?.topSellingEvents?.id
        );
        return {
          ...current,
          [data?.target?.name]: data?.target?.value?.id,
          topSellingEvents: topSellingExist ? topSellingExist : null,
          page: 1,
        };
      });
    }
  }, [options]);

  return (
    <>
      <div className="lg2:w-1/2 w-full md:px-2 md:mb-4 mb-2">
        <Card
          title="Top-Selling Events"
          threeDot={false}
          data={data}
          isClearable={true}
          options={flattenOptions(categoryOptions)}
          options2={durationOptions}
          placeholder="All categories"
          //   options={options}
          isCapital={true}
          handleOnChange={handleOnChange}
          handleOnChange2={handleDaysChange}
          value={
            topSellingEventHandle?.topSellingEvents
              ? topSellingEventHandle?.topSellingEvents
              : null
          }
          value2={value2 ? value2 : null}
          cardTitle="Top selling events"
          name="topSellingEvents"
          name2="duration"
          isGodAdmin={isGodAdmin}
          borderBottom="true"
          categoryLoading={categoryLoading}
        >
          <div className="divide-gray-200 divide-y-2 pt-2 mt-2 ">
            {isLoadingTopSelling ? (
              <>
                <div className="relative flex flex-col	 justify-between bg-white rounded px-5  pb-5  gap-5 mt-1.5">
                  {Array.from(
                    {
                      length: 6,
                    },
                    (v, i) => (
                      <div className="ul 1 rounded shimmer-effect h-7  ">
                        {" "}
                        <span className=" false ">second div</span>
                      </div>
                    )
                  )}
                </div>
              </>
            ) : data?.length > 0 ? (
              <div
                className={`flow-root max-h-[18.4375rem] outline-black overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full 
               ${isLoadingTopSelling && `pointer-events-none`}
               `}
                id="topSellingCardId"
                ref={infiniteScrollRef}
              >
                <div className="px-5 sticky top-0 bg-white text-gray-400 z-10">
                  <div className="flex items-center border-b border-gray-100 py-1.5 space-x-2.5">
                    <div className={isGodAdmin ? `flex-1 w-[65%]` : "flex-1"}>
                      <p className="text-xs font-medium line-clamp-1">
                        Event description
                      </p>
                    </div>

                    <div
                      className={
                        isGodAdmin ? `flex-1 w-[20%]` : `flex-1 w-[20%]`
                      }
                    >
                      <p className="flex items-left text-xs px-2 py-0 ">
                        Event date
                      </p>
                    </div>
                    {isGodAdmin && (
                      <div className=" w-[15%] min-w-[5.1875rem]">
                        <p className="text-xs font-medium line-clamp-1">
                          Tixstock sales
                        </p>
                      </div>
                    )}
                    {!isGodAdmin && (
                      <div className="text-right min-w-[5rem]"></div>
                    )}
                  </div>
                </div>
                <InfiniteScroll
                  dataLength={items?.length ? items?.length : 0}
                  next={fetchData}
                  hasMore={metaData?.current_page < metaData?.last_page}
                  loader={Array.from({ length: 3 }, (v, i) => (
                    <div
                      className={`accordion font-medium px-5 mb-2 ${
                        i === 0 && "mt-2"
                      } `}
                      key={i}
                    >
                      <div
                        className={`accordion-item bg-white !rounded overflow-hidden shimmer-effect`}
                        style={{ height: "30px" }}
                      ></div>
                    </div>
                  ))}
                  scrollThreshold="100px"
                  scrollableTarget="topSellingCardId"
                >
                  <ul className="max-h-50 overflow-y-auto">
                    {items?.map((item: any, index: any) => {
                      return (
                        <li
                          className="hover:bg-indigo-500/5 px-5 transition"
                          key={index}
                        >
                          <div className="flex items-center border-b border-gray-100 py-1.5 space-x-2.5">
                            <div
                              className={
                                isGodAdmin ? `flex-1 w-[65%]` : "flex-1"
                              }
                            >
                              <p
                                className="text-xs font-medium line-clamp-1"
                                title={`${item?.event_name}`}
                              >
                                {item?.event_name}
                              </p>
                            </div>

                            <div
                              className={
                                isGodAdmin ? `flex-1 w-[20%]` : `flex-1 w-[20%]`
                              }
                            >
                              {" "}
                              <p className="flex items-left text-xs px-2 py-0 ">
                                <IKTSVG
                                  path={CalenderSVG}
                                  className="min-w-[0.69rem] fill-violet-800 mr-1.5"
                                />
                                {item?.event_datetime
                                  ? moment(item?.event_datetime)
                                      .utc()
                                      .format("ddd, DD MMM YYYY")
                                  : "-"}
                              </p>
                            </div>
                            {isGodAdmin && (
                              <div className="  w-[15%] min-w-[5.1875rem]">
                                <p className="text-xs font-medium line-clamp-1">
                                  {item?.total_tickets}
                                </p>
                              </div>
                            )}
                            {!isGodAdmin && (
                              <div className="button text-right min-w-[5rem] flex flex-wrap">
                                <button
                                  type="button"
                                  className={`bg-violet-500 text-white text-xxs font-medium px-2 py-0.5 rounded hover:bg-indigo-500 ${
                                    createListingLoader && "pointer-events-none"
                                  } `}
                                  onClick={async () => {
                                    try {
                                      let selectedEventsIDS = [
                                        { id: item?.event_id },
                                      ];
                                      setCreateListingLoader(true);

                                      const { checkWeightage, level } =
                                        await checkWeightageOfEvent(
                                          selectedEventsIDS,
                                          coreLayout,
                                          sellerData
                                        );

                                      if (checkWeightage) {
                                        const message = `
                                        Seller Level [${level}] is not eligible to create listings for this event`;
                                        showAlert(message, 2);
                                      } else {
                                        navigate(
                                          `..${LISTINGS_UTILS?.addListings?.url}`,
                                          {
                                            replace: true,
                                            state: item?.event_id,
                                          }
                                        );
                                      }
                                    } catch (error) {
                                      console.error(
                                        "Error while calling the API:",
                                        error
                                      );
                                    } finally {
                                      setCreateListingLoader(false);
                                    }
                                  }}
                                >
                                  Create listing
                                </button>
                              </div>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </InfiniteScroll>
                {error && <p>Error: {error.message}</p>}
              </div>
            ) : (
              <div className="text-center text-sm13 py-4">
                No records could be found at the moment.
              </div>
            )}
          </div>
        </Card>
        {createListingLoader && <ProgressBardLoader secondCounter={2} />}
      </div>
    </>
  );
};

export default TopSellingCard;
