import DataCell from "pages/Sales/table/component/DataCell";

const VenueItemContent = ({ list }: any) => {
  return (
    <DataCell
      value={list?.name}
      classname="block"
      padding="pl-5"
      width="w-[11.25rem]"
    />
  );
};

export default VenueItemContent;
