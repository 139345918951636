import { SYMBOLS } from "helpers/const";
import moment from "moment";
import { useCallback } from "react";
import { convertIntoFormatValue, decimalNumber } from "../../helpers/Functions";
import {
  convertIntoDateTimeFormat,
  objectHasKey,
  stringifyComplexValue,
} from "../../pages/inventory/core/_functions";
import { KTSVG } from "../ui/KTSVG";
import {
  CHANGE_LOGS_KEY,
  DATE_TIME_KEYS,
  FORMAT_EXCEPTION_KEYS,
  formattedKeyValues,
  INITIAL_KEY,
  UTC_DATE_TIME_KEYS,
} from "./constants/constants";
const formatDateTime = (date: any, withUtc = false) => {
  if (!date) return;
  return withUtc
    ? moment(date).utc().format("DD/MM/YYYY HH:mm:ss")
    : moment(date).format("DD/MM/YYYY HH:mm:ss");
};

const convertIntoTitleStr = (str: string) => {
  if (!str || typeof str !== "string") return null;

  const formattedStr = str.split("_").join(" ");
  return formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);
};

export const ExpandableList = (props: any) => {
  const { listItem, index } = props;

  const isUpdated = useCallback(
    (key: any) =>
      // Check if fieldValue is an object and has 'updated' property with value 1
      Array.isArray(listItem?.updatedKeys) &&
      listItem?.updatedKeys.includes(key),
    [listItem?.updatedKeys]
  );

  const getValue = useCallback(
    (key: any) => {
      let listItemValue: any = listItem?.data[key];
      if (Object.keys(formattedKeyValues).includes(key))
        return convertIntoFormatValue(key, listItem?.data);

      if (DATE_TIME_KEYS.includes(key)) return formatDateTime(listItemValue);

      if (UTC_DATE_TIME_KEYS.includes(key))
        return formatDateTime(listItemValue, true);

      if (FORMAT_EXCEPTION_KEYS[key]) {
        let getValue = FORMAT_EXCEPTION_KEYS[key];
        if (getValue) return getValue(listItemValue) ?? listItemValue;
      }
      if (Array.isArray(listItemValue) && listItemValue.length === 0) {
        return undefined;
      }

      // Check if listItemValue has a 'value' property
      if (objectHasKey(listItemValue, "value"))
        return stringifyComplexValue(listItemValue?.value); // Return the string representation of 'value' if it's an array or object

      return stringifyComplexValue(listItemValue); // Return the string representation of listItemValue itself if it's an array or object
    },
    [listItem?.data]
  );

  const addDollarSignAfterKey = (obj: any, key: any) => {
    const keys = Object.keys(obj);
    const index = keys.indexOf(key);
    const modifiedKeyValuePairs = [];

    if (index !== -1) {
      if (!Array.isArray(obj.ppe_price_array)) {
        obj.ppe_price_array = [];
      }
      for (let i = index + 1; i < keys.length; i++) {
        const currentKey = keys[i];
        const value = parseFloat(obj[currentKey]);

        if (!isNaN(value)) {
          const formattedValue = `${
            SYMBOLS[obj?.sell_price_currency]
          }${decimalNumber(value)}`;
          obj[currentKey] = formattedValue;

          const keyValuePair = { key: currentKey, value: formattedValue };

          modifiedKeyValuePairs.push(keyValuePair);

          obj.ppe_price_array.push(keyValuePair);
        }
      }
      delete obj["ppe_prices"];
      for (const pair of modifiedKeyValuePairs) {
        delete obj[pair.key];
      }
    }
  };
  const output = addDollarSignAfterKey(listItem?.data, "ppe_prices");

  const isUpdatedPPE = useCallback(
    (key: any) =>
      Array.isArray(listItem?.updatedKeys) &&
      listItem?.updatedKeys.includes(key),
    [listItem?.updatedKeys]
  );

  return (
    <div className="acc_wrap border rounded mb-1.5">
      <div className="single_accordion ">
        <div
          className="acc_title inventory-table sales-table overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full "
          id="dropdownItems"
        >
          <div
            id={`single_accordion${index}`}
            className="group rounded-t [&:not([data-te-collapse-collapsed])_svg]:fill-white [&:not([data-te-collapse-collapsed])]:bg-violet-700 [&:not([data-te-collapse-collapsed])]:text-white cursor-pointer flex"
            data-te-collapse-init
            data-te-collapse-collapsed
            data-te-target={`#collapseItem${index}`}
            aria-expanded="false"
            aria-controls={`collapseItem${index}`}
          >
            <div className="flex justify-between items-center w-full text-sm14 px-4 capitalize max-md:flex-wrap max-md:py-1">
              <h4 className="max-md:mb-1">
                Action: {listItem?.action}
                {listItem?.updated_by && (
                  <span className="ml-1">by {listItem.updated_by}</span>
                )}
              </h4>
              <h5 className="ml-auto">
                {convertIntoDateTimeFormat(listItem?.timestamp)}
              </h5>
            </div>

            <div
              style={{ width: "2.5rem" }}
              className="ml-auto border-l font-medium group px-4 py-2.5 min-w-[2.5rem] min-h-[2.5rem] flex items-center justify-center"
            >
              <KTSVG
                className="flex items-center justify-center  fill-violet-500 hover:fill-indigo-500 transition
            shrink-0 rotate-[-180deg] duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 motion-reduce:transition-none pointer-events-none"
                path="standard_icons/arrowDown.svg"
              />
            </div>
          </div>

          <div
            key={`dropdowns${index}`}
            id={`collapseItem${index}`}
            className="!visible hidden accordion-collapse border-t group "
            data-te-collapse-item
            data-te-collapse-collapsed
            aria-labelledby={`collapseItem${index}`}
          >
            <div className="border-b last-of-type:border-b-0 p-4">
              <div className="text-xs text-left md:columns-2 md:gap-4">
                {listItem?.data &&
                typeof listItem?.data === "object" &&
                Object.keys(listItem?.data).length > 0 ? (
                  Object.keys(listItem?.data)
                    .map((key) => CHANGE_LOGS_KEY[key] ?? key)
                    .sort()
                    .filter(
                      (key) => !Object.values(formattedKeyValues).includes(key)
                    )
                    .map((col: any, index: any) => {
                      let initialKey: any = INITIAL_KEY[col] ?? col;

                      return (
                        <div
                          style={{ width: col.width }}
                          className={`whitespace-nowrap md:w-full font-medium min-w-[8.125rem] `}
                          key={index}
                        >
                          <div
                            className={`w-full flex border -mt-px ${
                              isUpdated(initialKey) &&
                              "bg-indigo-500 bg-opacity-[7%]"
                            } ${
                              convertIntoTitleStr(col) === "Ppe price array"
                                ? "hidden"
                                : ""
                            }  `}
                          >
                            <div
                              className={`  whitespace-nowrap overflow-hidden text-ellipsis text-gray-400  w-1/2 border-r  p-1`}
                              title={`${convertIntoTitleStr(col)}`}
                            >
                              {convertIntoTitleStr(col)} :
                            </div>
                            <div
                              className={`w-1/2 p-1 whitespace-nowrap overflow-hidden text-ellipsis `}
                              title={getValue(initialKey)}
                            >
                              {getValue(initialKey)}
                            </div>
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <div className="border-t" id={`collapseItem`}>
                    <div className="py-3 px-1.5 text-center">No Result</div>
                  </div>
                )}
              </div>
            </div>
            {listItem?.data?.ppe_price_array?.length > 0 && (
              <div className="border-b last-of-type:border-b-0 p-4 pt-0">
                <span className="text-sm">PPP pricing</span>
                <div className="text-xs text-left md:columns-2 md:gap-4">
                  {listItem?.data?.ppe_price_array?.map(
                    (col: any, index: any) => {
                      let initialKey: any = INITIAL_KEY[col] ?? col;

                      return (
                        <div
                          style={{ width: col.width }}
                          className={`whitespace-nowrap md:w-full font-medium min-w-[8.125rem] `}
                          key={index}
                        >
                          <div
                            className={`w-full flex border -mt-px ${
                              isUpdatedPPE(initialKey?.key) &&
                              "bg-indigo-500 bg-opacity-[7%]"
                            }`}
                          >
                            <div
                              className={`${
                                convertIntoTitleStr(col) === "PPE prices"
                                  ? "w-full"
                                  : " w-1/2 border-r"
                              }  whitespace-nowrap overflow-hidden text-ellipsis text-gray-400   p-1`}
                              title={`${convertIntoTitleStr(col?.key)}`}
                            >
                              {convertIntoTitleStr(col?.key)} :
                            </div>
                            <div
                              className={`w-1/2 p-1 whitespace-nowrap overflow-hidden text-ellipsis `}
                              title={initialKey?.value}
                            >
                              {/* {getValue(initialKey?.value)}
                               */}
                              {initialKey?.value}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
