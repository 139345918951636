import { createContext, PropsWithChildren, useState } from "react";

export interface LayoutContextModel {
  sellerLevelData: any;
  setSellerLevelData: (data: any) => void;

  sellerLevelEditData: any;
  setSellerLevelEditData: (data: any) => void;

  sellerLevelMovementData: any;
  setSellerLevelMovementData: (data: any) => void;

  sellerLevelMovementFilters: any;
  setSellerLevelMovementFilters: (data: any) => void;

  commonFilter: any;
  setCommonFilter: (data: any) => void;
}

const SellerLevelsProviderContex = createContext<LayoutContextModel>({
  sellerLevelData: [],
  setSellerLevelData: (data: any) => {},

  sellerLevelEditData: {},
  setSellerLevelEditData: (data: any) => {},

  sellerLevelMovementData: {},
  setSellerLevelMovementData: (data: any) => {},

  sellerLevelMovementFilters: {},
  setSellerLevelMovementFilters: (data: any) => {},

  commonFilter: {},
  setCommonFilter: (data: any) => {},
});

const SellerLevelsProvider = ({ children }: PropsWithChildren) => {
  const [sellerLevelData, setSellerLevelData] = useState<any>();
  const [sellerLevelEditData, setSellerLevelEditData] = useState<any>();
  const [sellerLevelMovementData, setSellerLevelMovementData] = useState();
  const [sellerLevelMovementFilters, setSellerLevelMovementFilters] = useState({
    page: 1,
    per_page: 20,
  });

  const [commonFilter, setCommonFilter] = useState({
    page: 1,
    per_page: 20,
  });

  const value: LayoutContextModel = {
    sellerLevelData,
    setSellerLevelData,

    sellerLevelEditData,
    setSellerLevelEditData,

    sellerLevelMovementData,
    setSellerLevelMovementData,

    sellerLevelMovementFilters,
    setSellerLevelMovementFilters,

    commonFilter,
    setCommonFilter,
  };

  return (
    <SellerLevelsProviderContex.Provider value={value}>
      {children}
    </SellerLevelsProviderContex.Provider>
  );
};

export { SellerLevelsProvider, SellerLevelsProviderContex };
