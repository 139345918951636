import React from "react";
import NoMoreItemInTable from "../NoMoreItemInTable";

export const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left last-auto with-sticky"
    style={{
      ...style,
    }}
  />
);
export const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 last-auto"
    style={{
      ...style,
    }}
  />
);
export const TableRow = React.memo(({ isMatched, loading, ...props }: any) => {
  return (
    <>
      {false ? (
        <tr {...props} className="shimmer-effect highlight" />
      ) : (
        <tr
          {...props}
          className={`border-b bg-white ${isMatched ? "matchView" : ""}
          `}
        />
      )}
    </>
  );
});

export const TableFoot = ({ data, paginateData }: any) =>
  data?.length === paginateData?.total && paginateData?.current_page > 1 ? (
    <NoMoreItemInTable />
  ) : null;
