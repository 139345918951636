import { useEffect, useRef } from "react";

const useSwiperWheelNavigation = (
  swiperRef: React.RefObject<any>,
  swiperRefContainer: React.RefObject<any>,
  nextClass: string,
  prevClass: string
) => {
  const isScrolling = useRef(false);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      const swiper = swiperRef.current?.swiper;

      if (!swiper || isScrolling.current) return;

      let horizontalScroll = 0;

      if (event.deltaX !== 0) {
        horizontalScroll = event.deltaX;
      } else if (event.shiftKey && event.deltaY !== 0) {
        horizontalScroll = event.deltaY;
      }

      if (horizontalScroll !== 0) {
        event.preventDefault();
        event.stopPropagation();

        isScrolling.current = true;

        requestAnimationFrame(() => {
          const nextBtn: HTMLCollectionOf<HTMLElement> =
            document.getElementsByClassName(
              nextClass
            ) as HTMLCollectionOf<HTMLElement>;

          const previousBtn: HTMLCollectionOf<HTMLElement> =
            document.getElementsByClassName(
              prevClass
            ) as HTMLCollectionOf<HTMLElement>;

          if (horizontalScroll > 0) {
            nextBtn?.[0]?.click();
          } else if (horizontalScroll < 0) {
            previousBtn?.[0]?.click();
          }

          setTimeout(() => {
            isScrolling.current = false;
          }, 200);
        });
      }
    };

    const swiperContainer = swiperRef.current?.swiper?.el;

    if (swiperContainer) {
      swiperContainer.addEventListener("wheel", handleWheel, {
        passive: false,
      });
    }

    return () => {
      if (swiperContainer) {
        swiperContainer.removeEventListener("wheel", handleWheel);
      }
    };
  }, [swiperRef, nextClass, prevClass, swiperRefContainer]);
};

export default useSwiperWheelNavigation;
