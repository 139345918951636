import { useContext, useEffect, useMemo, useRef, useState } from "react";
// import Tab from "../tx_trade/component/Tab";
import _, { size } from "lodash";
import { useQuery } from "react-query";
import close from "../../../assets/media/icons/other_icons/Clear.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import NoDataComponent from "../../../components/tableComponent/NoDataComponent";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import {
  QUERIES,
  TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER,
  TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER,
  TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
} from "../../../helpers/const";
import { getReportsOptions } from "../../Reports/core/requests";
import useDebounce from "../../add_inventory/core/useDebounce";
import TableShimmer from "../../tx_account/component/TableShimmer";
import { tabConditions } from "../../tx_account/core/functions";
import DataTableBankAccount from "./Table/DataTableBankAccount";
import DataTableSellerwallet from "./Table/DataTableSellerwallet";
import DataTableWithdrawRequest from "./Table/DataTableWithdrawRequest";
import HeaderFilterContainer from "./Table/component/HeaderFilterContainer";
import Tabs from "./Table/component/Tabs";
import CommonFilter from "./component/CommonFilter";
import FiltersChips from "./component/FiltersChips";
import { WalletAndBankDetailsContext } from "./core/WalletAndBankDetailsProvider";
import {
  getTXAccountBankAccountListings,
  getTXAccountSellerWalletListings,
  getTXAccountWithdrawRequestListings,
} from "./core/_request";
import Footer from "./layout/Footer";

const WalletAndBankDetails = () => {
  const layout = useContext(WalletAndBankDetailsContext);
  const coreLayout = useContext(LayoutContext);
  const tab = layout.isTabActive;
  const filterOptions = layout.filterOptions;
  const sellerWalletsFilter = layout.sellerWalletsFilter;
  const bankAccountFilter = layout.bankAccountFilter;
  const withdrawRequestFilter = layout.withdrawRequestFilter;

  const {
    sellerWalletsFilterCopy,
    bankAccountFilterCopy,
    withdrawRequestFilterCopy,
  } = layout.commonFilter;

  const heightRef1: any = useRef();
  const toggleRef: any = useRef();
  const [IsToggle, setIsToggle] = useState<any>(true);
  const [overViewHeight, setOverViewHeight] = useState<any>();
  const element: any = document.querySelector("#inventoryaccordion");
  const stickyElement = document.getElementById("topFilters") as HTMLElement;

  // STATES
  const [isLoadingSellerWalletVirtous, setIsSellerWalletVirtous] =
    useState<any>(false);
  const [isLoadingBankAccountVirtous, setIsLoadingBankAccountVirtous] =
    useState<any>(false);
  const [isLoadingwithdrawRequestVirtous, setIsLoadingwithdrawRequestVirtous] =
    useState<any>(false);

  // let txAccountSellerWalletFilter: any = useMemo(() => {
  //   return [sellerWalletsFilter];
  // }, [sellerWalletsFilter]);

  // let txAccountbankAccountFilter: any = useMemo(() => {
  //   return [bankAccountFilter];
  // }, [bankAccountFilter]);

  // let txAccountWithdrawRequestFilterFilter: any = useMemo(() => {
  //   return [withdrawRequestFilter];
  // }, [withdrawRequestFilter]);

  // let txAccountSelFilterMemo: any = useDebounce(
  //   JSON.stringify(txAccountSellerWalletFilter),
  //   200
  // );
  // let txAccountbankAccountFilterMemo: any = useDebounce(
  //   JSON.stringify(txAccountbankAccountFilter),
  //   200
  // );
  // let txAccountWithdrawRequestFilterMemo: any = useDebounce(
  //   JSON.stringify(txAccountWithdrawRequestFilterFilter),
  //   200
  // );

  let txAccountSellerWalletFilter: any = useMemo(() => {
    return [sellerWalletsFilterCopy];
  }, [sellerWalletsFilterCopy]);

  let txAccountbankAccountFilter: any = useMemo(() => {
    return [bankAccountFilterCopy];
  }, [bankAccountFilterCopy]);

  let txAccountWithdrawRequestFilterFilter: any = useMemo(() => {
    return [withdrawRequestFilterCopy];
  }, [withdrawRequestFilterCopy]);

  let txAccountSelFilterMemo: any = useDebounce(
    JSON.stringify(txAccountSellerWalletFilter),
    200
  );
  let txAccountbankAccountFilterMemo: any = useDebounce(
    JSON.stringify(txAccountbankAccountFilter),
    200
  );
  let txAccountWithdrawRequestFilterMemo: any = useDebounce(
    JSON.stringify(txAccountWithdrawRequestFilterFilter),
    200
  );

  useEffect(() => {
    if (layout.porterState?.team_id) {
      layout.setBankAccountFilter((pre: any) => {
        return {
          ...pre,
          teams: [
            {
              id: layout.porterState?.team_id,
              name: layout.porterState?.team_name,
            },
          ],
          id: layout.porterState?.team_id,
          page: 1,
          per_page: 20,
        };
      });

      layout.setCommonFilter((pre: any) => {
        return {
          sellerWalletsFilterCopy: TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER,
          bankAccountFilterCopy: {
            ...pre.bankAccountFilterCopy,
            teams: [
              {
                id: layout.porterState?.team_id,
                name: layout.porterState?.team_name,
              },
            ],
            id: layout.porterState?.team_id,
            page: 1,
            per_page: 20,
          },
          withdrawRequestFilterCopy: TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
        };
      });
    }

    if (layout.porterState?.team_id && size(layout.bankAccountList) === 1) {
      layout.setPorterState(layout.bankAccountList?.[0]);
    }
  }, [layout.porterState, layout.bankAccountList]);

  // sellerWalletsFilterCopy,
  // bankAccountFilterCopy,
  // withdrawRequestFilterCopy,
  // GET TX ACCOUNT FOR SELLER WALLET
  const {
    isLoading: txaccountListingSellerWalletLoading,
    refetch: sellerRefetch,
  } = useQuery(
    [
      QUERIES.TX_ACCOUNT_SELLER_WALLET_LISTIGNS,
      ...JSON.parse(txAccountSelFilterMemo),
    ],
    () =>
      getTXAccountSellerWalletListings({
        sellerWalletsFilter: sellerWalletsFilterCopy,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      enabled: tab === "Seller wallets" && true,
      onSettled(data, error) {
        if (data) {
          if (data?.data?.wallet_list?.length > 0) {
            layout.setSellerWalletsList((prevOrders: any) => {
              const updatedOrders =
                data?.meta?.current_page > 1
                  ? _.uniqBy(
                      [...prevOrders, ...data?.data?.wallet_list],
                      "team_id"
                    )
                  : data?.data?.wallet_list;
              return updatedOrders;
            });
          } else {
            layout.setSellerWalletsList([]);
          }
          if (data?.meta?.current_page === 1) {
            if (data?.data?.revenue) {
              layout.setCurrencyBalance(() => {
                return {
                  overview: data?.data?.revenue,
                };
              });
            } else {
              layout.setCurrencyBalance({
                overview: undefined,
              });
            }
          }
          setIsSellerWalletVirtous(false);
        }

        layout.setSellerWalletsMeta(data?.meta);
      },
    }
  );
  // GET TX ACCOUNT FOR BANK ACCOUNT
  const {
    isLoading: txaccountListingBankAccountLoading,
    refetch: bankAccountRefetch,
  } = useQuery(
    [
      QUERIES.TX_ACCOUNT_BANK_ACCOUNT_LISTIGNS,
      ...JSON.parse(txAccountbankAccountFilterMemo),
    ],
    () =>
      getTXAccountBankAccountListings({
        bankAccountFilter: bankAccountFilterCopy,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      enabled: tab === "Bank accounts" && true,
      onSettled(data, error) {
        if (data) {
          if (data?.data?.length > 0) {
            layout.setBankAccountList((prevOrders: any) => {
              const updatedOrders =
                data?.meta?.current_page > 1
                  ? _.uniqBy([...prevOrders, ...data?.data], "team_id")
                  : data?.data;
              return updatedOrders;
            });
          } else {
            layout.setBankAccountList([]);
          }
          if (data?.meta?.current_page === 1) {
            if (data?.data?.revenue) {
              layout.setCurrencyBalance(() => {
                return {
                  overview: data?.data?.revenue,
                };
              });
            } else {
              layout.setCurrencyBalance({
                overview: undefined,
              });
            }
          }
          setIsLoadingBankAccountVirtous(false);
        }

        layout.setBankAccountMeta(data?.meta);
      },
    }
  );
  // GET TX ACCOUNT FOR WITHDRAW REQUEST
  const {
    isLoading: txaccountListingWithdrawRequestLoading,
    refetch: withdrawListingRefetch,
  } = useQuery(
    [
      QUERIES.TX_ACCOUNT_WITHDRAW_REQUEST_LISTIGNS,
      ...JSON.parse(txAccountWithdrawRequestFilterMemo),
    ],
    () =>
      getTXAccountWithdrawRequestListings({
        withdrawRequestFilter: withdrawRequestFilterCopy,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      enabled: tab === "Withdraw requests" && true,
      onSettled(data, error) {
        if (data) {
          if (data?.data?.lists?.length > 0) {
            layout.setWithdrawRequestList((prevOrders: any) => {
              const updatedOrders =
                data?.meta?.current_page > 1
                  ? _.uniqBy([...prevOrders, ...data?.data?.lists], "id")
                  : data?.data?.lists;
              return updatedOrders;
            });
          } else {
            layout.setWithdrawRequestList([]);
          }
          if (data?.meta?.current_page === 1) {
            if (data?.data?.revenue) {
              layout.setCurrencyBalance(() => {
                return {
                  overview: data?.data?.revenue,
                };
              });
            } else {
              layout.setCurrencyBalance({
                overview: undefined,
              });
            }
          }
          setIsLoadingwithdrawRequestVirtous(false);
        }

        layout.setWithdrawRequestMeta(data?.meta);
      },
    }
  );

  // GET FILTERS OPTIONS
  useQuery("TX-ACCOUNT-OPTIONS", () => getReportsOptions(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    onError: (err) => {},
    enabled: true,
    onSettled(data, error) {
      if (data?.data) {
        const teams = coreLayout?.allowedAccess?.teams;
        const orderStatusOptions: any = data?.data?.order_statuses
          ?.flatMap((group: any) => group.data)
          ?.filter(
            (option: any) =>
              option?.description === "Completed" ||
              option?.description === "Cancelled" ||
              option?.description === "Awaiting Delivery"
          );
        const paymentOptions = [
          { id: "paid", name: "Paid", value: "yes" },
          { id: "unpaid", name: "Unpaid", value: "no" },
        ];
        const marketplacepaymentstatus = [
          { id: "paid", name: "Paid", value: "1" },
          { id: "unpaid", name: "Unpaid", value: "0" },
        ];

        layout.setFilterOptions((pre: any) => {
          return {
            ...pre,
            options_teams: teams,
            options_marketplace: data?.data?.order_exchanges?.filter(
              (option: any) => option?.name !== "Tgces"
            ),
            options_orderstatus: orderStatusOptions,
            options_internalorderstatus: data?.data?.internal_order_statuses,
            options_paymentstatus: paymentOptions,
            options_marketplace_paymentstatus: marketplacepaymentstatus,
            options_invoice_statuses: data?.data?.invoice_statuses,
            options_walletstatus: data?.data?.wallet_statuses,
            options_withdraw_request_statuses:
              data?.data?.withdraw_request_statuses,
            options_marketplace_currencies: data?.data?.marketplace_currencies,
          };
        });
      }
    },
  });

  // HANDLE CHANGE
  const handleOnCommonFilter = (data: any) => {
    if (tab === "Seller wallets") {
      layout.setSellerWalletsFilter((pre: any) => {
        return {
          ...pre,
          [data.target.name]: data?.target?.value
            ? data?.target?.value
            : data?.target?.checked,
          page: 1,
        };
      });
    }
    if (tab === "Bank accounts") {
      layout.setBankAccountFilter((pre: any) => {
        let final = {
          ...pre,
          [data.target.name]: data?.target?.value
            ? data?.target?.value
            : data?.target?.checked,
          page: 1,
        };
        delete final?.id;
        return final;
      });
    }
    if (tab === "Withdraw requests") {
      layout.setIsSelectAll({ status: "" });
      layout.setWithdrawRequestFilter((pre: any) => {
        return {
          ...pre,
          [data.target.name]: data?.target?.value
            ? data?.target?.value
            : data?.target?.checked,
          page: 1,
        };
      });
    }
  };

  // STICKY HEADER - CODE
  useEffect(() => {
    if (stickyElement) {
      if (stickyElement) {
        const initialPosition = stickyElement.getBoundingClientRect().top;
        let MarginTopTotle = 0;
        let topPosition = 0;
        const recalculateMarginTopTotle = () => {
          const accordion = document.getElementById(
            "inventoryaccordion"
          ) as HTMLElement;
          const accordionStyle = window.getComputedStyle(accordion);
          const accordionheight: any = parseFloat(
            accordionStyle.getPropertyValue("height")
          );

          const filter = document.getElementById(
            "filters_block"
          ) as HTMLElement;
          const heightheightRef2 = filter.offsetHeight;

          const salesFilter: any =
            document.getElementById("salesFilter")?.clientHeight;

          const HeaderHeight: any =
            document.querySelector("header")?.clientHeight;

          var computedStyle = window.getComputedStyle(stickyElement);
          var paddingBottom = computedStyle.paddingBottom;
          var paddingBottomValue = parseInt(paddingBottom, 10);
          // topPosition = heightheightRef2 + salesFilter + HeaderHeight + 20;
          // topPosition =
          //   heightheightRef2 +
          //   salesFilter +
          //   HeaderHeight +
          //   paddingBottomValue -
          //   1;

          if (window.matchMedia("(max-width: 767px)").matches) {
            topPosition = HeaderHeight;
          } else {
            topPosition =
              heightheightRef2 +
              salesFilter +
              HeaderHeight +
              paddingBottomValue -
              1;
          }

          // Recalculate MarginTopTotle based on updated heights or values
          const heightheightRef1 = heightRef1?.current?.offsetHeight;
          MarginTopTotle =
            IsToggle === true
              ? heightheightRef1 + accordionheight - HeaderHeight
              : HeaderHeight + heightheightRef1;
        };

        const makeSticky = function () {
          const HeaderHeight: any =
            document.querySelector("header")?.clientHeight;
          const sticky_head: any = document.getElementById("sticky-head");
          const stickyShadow: any = document.getElementById("sticky-shadow");
          const sticky_headHeight: any = sticky_head?.clientHeight;

          recalculateMarginTopTotle();
          if (window.scrollY + HeaderHeight >= initialPosition) {
            stickyElement.classList.add("sticky");
            stickyElement.style.top =
              IsToggle === true
                ? `-${MarginTopTotle}px`
                : `${MarginTopTotle}px`;
            sticky_head && (sticky_head.style.top = topPosition + "px");
            stickyShadow.style.top = topPosition + sticky_headHeight + "px";
          } else {
            stickyElement.classList.remove("sticky");
            stickyElement.style.top = `0px`;
            sticky_head && (sticky_head.style.top = "0px");
            stickyShadow.style.top = "0px";
          }
        };
        const handleWindowResize = () => {
          // Handle logic when the window is resized
          makeSticky(); // Update sticky behavior based on new values
          recalculateMarginTopTotle(); // Recalculate MarginTopTotle on window resize
        };
        window.addEventListener("scroll", makeSticky);
        window.addEventListener("change", makeSticky);
        window.addEventListener("blur", makeSticky);
        // bulk_order?.addEventListener("click", makeSticky);

        setTimeout(() => {
          document.addEventListener("resize", handleWindowResize);
        }, 200);
        return () => {
          window.removeEventListener("scroll", makeSticky);
          window.removeEventListener("change", makeSticky);
          window.removeEventListener("blur", makeSticky);
          document.removeEventListener("resize", handleWindowResize);
        };
      }
    }
  }, [toggleRef, IsToggle, overViewHeight, stickyElement]);

  // STATE TYPE
  const stateReturn = (type: string) => {
    switch (type) {
      case "state":
        return tab === "Seller wallets"
          ? sellerWalletsFilter
          : tab === "Bank accounts"
          ? bankAccountFilter
          : tab === "Withdraw requests"
          ? withdrawRequestFilter
          : "";
      case "loading":
        return tab === "Seller wallets"
          ? txaccountListingSellerWalletLoading
          : tab === "Bank accounts"
          ? txaccountListingBankAccountLoading
          : tab === "Withdraw requests"
          ? txaccountListingWithdrawRequestLoading
          : false;

      case "total":
        return tab === "Seller wallets"
          ? layout?.sellerWalletsMeta?.total
          : tab === "Bank accounts"
          ? layout.bankAccountMeta?.total
          : tab === "Withdraw requests"
          ? layout?.withdrawRequestMeta?.total
          : "";

      case "balance":
        return tab === "Seller wallets" ||
          tab === "Bank accounts" ||
          tab === "Withdraw requests"
          ? layout?.currencyBalance?.overview?.tixstock_currency_balances
          : "";
      default:
        break;
    }
  };

  // PROGRESS LOADER
  const progressLoader = () => {
    // MAP WHICH IS STATE
    const filterSetterMap: any = {
      "Seller wallets": {
        page: sellerWalletsFilter?.page,
        loader: txaccountListingSellerWalletLoading,
        title: "seller wallets",
      },
      "Bank accounts": {
        page: bankAccountFilter?.page,
        loader: txaccountListingBankAccountLoading,
        title: "Bank accounts",
      },
      "Withdraw requests": {
        page: withdrawRequestFilter?.page,
        loader: txaccountListingWithdrawRequestLoading,
        title: "Withdraw requests",
      },
    };
    return filterSetterMap[tab];
  };

  function ToggleElemClose() {
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.remove("activeFIlter");
    document.body.classList.remove("overflow-hidden");
  }
  function ToggleElemOpen() {
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.add("activeFIlter");
    document.body.classList.add("overflow-hidden");
  }
  // CLEAR ALL FILTER
  const handleOnclear = () => {
    if (tab === "Seller wallets") {
      layout.setSellerWalletsFilter(TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER);
    }
    if (tab === "Bank accounts") {
      layout.setBankAccountFilter(TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER);
    }
    if (tab === "Withdraw requests") {
      layout.setIsSelectAll({ status: "" });
      layout.setWithdrawRequestFilter((current: any) => {
        return {
          ...TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
          statuses: null,
        };
      });
    }
  };

  const filterVisibile =
    // SELLER WALLET
    size(sellerWalletsFilter?.teams) > 0 ||
    size(sellerWalletsFilter?.statuses) > 0 ||
    // BANK ACCOUNT FILTER
    size(bankAccountFilter?.teams) > 0 ||
    bankAccountFilter?.txpay_change_request ||
    bankAccountFilter?.is_withdrawal_acc_configured?.name ||
    // WITHDRAW REQUEST
    size(withdrawRequestFilter?.teams) > 0 ||
    (tab === "Withdraw requests" && size(withdrawRequestFilter?.statuses) > 0
      ? true
      : false) ||
    withdrawRequestFilter?.txpay_change_request ||
    (withdrawRequestFilter?.requestedDate?.startDate !== undefined &&
      withdrawRequestFilter?.requestedDate?.endDate !== undefined);

  const handleOnApplyFilters = (
    action: any,
    activeTab: any,
    filterData: any
  ) => {
    if (activeTab === "Seller wallets") {
      layout.setCommonFilter((pre: any) => {
        return {
          sellerWalletsFilterCopy: filterData,
          bankAccountFilterCopy: TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER,
          withdrawRequestFilterCopy: TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
        };
      });
    }
    if (activeTab === "Bank accounts") {
      layout.setCommonFilter((pre: any) => {
        return {
          sellerWalletsFilterCopy: TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER,
          bankAccountFilterCopy: filterData,
          withdrawRequestFilterCopy: TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
        };
      });
    }
    if (activeTab === "Withdraw requests") {
      layout.setCommonFilter((pre: any) => {
        return {
          sellerWalletsFilterCopy: TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER,
          bankAccountFilterCopy: TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER,
          withdrawRequestFilterCopy: filterData,
        };
      });
    }
  };

  return (
    <>
      <div className="main-content-wrap">
        <div
          className={`topFilters sticky-element top-[3.75rem] z-[11] pb-5 bg-gray-200 max-md:!static`}
          id="topFilters"
        >
          <div className="top-accor shadow-op2">
            {/* HEADER */}
            <HeaderFilterContainer
              heightRef1={heightRef1}
              setIsToggle={() => {
                setIsToggle((pre: any) => {
                  let height3: any;

                  setTimeout(() => {
                    height3 = pre ? element?.offsetHeight : 0;
                    setOverViewHeight(height3);
                  }, 400);
                  return !pre;
                });
              }}
            />

            <div
              className="bg-white border-t accordion-collapse font-medium collapse show"
              id="inventoryaccordion"
              data-te-collapse-item
              data-te-collapse-show
              aria-labelledby="headingOne"
            >
              {/* TABS */}
              <Tabs />

              {/* ACCOUNTS BALANCE - Commented with request of client  */}

              {/* {(layout.isTabActive === "Withdraw requests" ||
                layout.isTabActive === "Seller wallets") && (
                <AccountBalanceWithFlag
                  value={stateReturn("balance")}
                  loading={stateReturn("loading")}
                />
              )} */}
            </div>

            {/* SECOND LAYER FILTERS */}
            <div
              id="filtersMob"
              className="filtersMob max-md:flex max-md:flex-col"
            >
              <div
                className="flex flex-wrap gap-y-2.5 py-4 px-[.9375rem] bg-white max-md:pt-14 max-md:flex-1 max-md:overflow-auto max-md:content-start"
                id={`filters_block`}
              >
                <div
                  className="closePopup hidden max-md:flex"
                  id="closePopup"
                  onClick={ToggleElemClose}
                >
                  <IKTSVG
                    path={close}
                    className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
                    svgClassName="w-4 h-4"
                  />
                </div>
                <CommonFilter
                  activeTab={tab}
                  handleOnChange={handleOnCommonFilter}
                  options={filterOptions}
                  value={stateReturn("state")}
                  loading={
                    stateReturn("state")?.page === 1 && stateReturn("loading")
                      ? true
                      : false
                  }
                  handleOnApplyFilters={handleOnApplyFilters}
                  filterVisibile={filterVisibile}
                />
              </div>
              <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl">
                <button
                  className={`border  hover:border-indigo-500  hover:text-white  hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5 ${
                    filterVisibile
                      ? "bg-white text-violet-800 border-gray-300"
                      : "bg-gray-100 text-gray-400 pointer-events-none border-gray-100"
                  }`}
                  onClick={handleOnclear}
                >
                  Clear
                </button>

                <button
                  className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
                  onClick={() => {
                    ToggleElemClose();
                    handleOnApplyFilters("apply", layout.isTabActive, {
                      ...stateReturn("state"),
                      page: 1,
                    });
                  }}
                >
                  Apply
                </button>
              </div>
            </div>

            {/*THIRD LAYER*/}
            <div
              className="flex flex-wrap customStyle relative bg-white  border-t max-sm:justify-between"
              id={`salesFilter`}
            >
              <FiltersChips
                totalList={stateReturn("total")}
                loading={
                  stateReturn("state")?.page === 1 && stateReturn("loading")
                    ? true
                    : false
                }
                ToggleElemOpen={ToggleElemOpen}
                handleOnApplyFilters={handleOnApplyFilters}
              />
            </div>
          </div>
        </div>

        {layout.isTabActive === "Seller wallets" && (
          <>
            {txaccountListingSellerWalletLoading &&
            sellerWalletsFilter?.page === 1 ? (
              <TableShimmer loader={txaccountListingSellerWalletLoading} />
            ) : layout.sellerWalletsList &&
              layout.sellerWalletsList?.length > 0 ? (
              <DataTableSellerwallet
                loading={isLoadingSellerWalletVirtous}
                setIsLoading={setIsSellerWalletVirtous}
                refetch={sellerRefetch}
                handleOnApplyFilters={handleOnApplyFilters}
                copyFilterData={sellerWalletsFilterCopy}
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
        {layout.isTabActive === "Bank accounts" && (
          <>
            {txaccountListingBankAccountLoading &&
            bankAccountFilter?.page === 1 ? (
              <TableShimmer loader={txaccountListingBankAccountLoading} />
            ) : layout.bankAccountList && layout.bankAccountList?.length > 0 ? (
              <DataTableBankAccount
                loading={isLoadingBankAccountVirtous}
                setIsLoading={setIsLoadingBankAccountVirtous}
                bankAccountRefetch={bankAccountRefetch}
                handleOnApplyFilters={handleOnApplyFilters}
                copyFilterData={bankAccountFilterCopy}
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
        {layout.isTabActive === "Withdraw requests" && (
          <>
            {txaccountListingWithdrawRequestLoading &&
            withdrawRequestFilter?.page === 1 ? (
              <TableShimmer loader={txaccountListingWithdrawRequestLoading} />
            ) : layout.withdrawRequestList &&
              layout.withdrawRequestList?.length > 0 ? (
              <DataTableWithdrawRequest
                loading={isLoadingwithdrawRequestVirtous}
                setIsLoading={setIsLoadingwithdrawRequestVirtous}
                refetch={withdrawListingRefetch}
                handleOnApplyFilters={handleOnApplyFilters}
                copyFilterData={withdrawRequestFilterCopy}
              />
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </div>

      {/* FOOTER  */}
      <Footer />

      {/* LOADER */}
      {progressLoader()?.loader && progressLoader()?.page === 1 && (
        <ProgressBardLoader
          LoadingText={`Loading your ${progressLoader()?.title} data`}
          secondCounter={"3"}
        />
      )}

      {layout.customLoader && (
        <ProgressBardLoader
          secondCounter={1}
          LoadingText={`Loading your ${tabConditions(tab)} data`}
        />
      )}
    </>
  );
};

export default WalletAndBankDetails;
