import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { useEffect, useState } from "react";
import circle from "../../../assets/media/icons/other_icons/circle.svg";
import eyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import eyeCrossSVG from "../../../assets/media/icons/other_icons/EyeCross.svg";
import masterCards from "../../../assets/media/icons/other_icons/masterCards.svg";
import visalogo from "../../../assets/media/icons/other_icons/visaLogo.svg";
import Cross from "../../../assets/media/icons/standard_icons/cross.svg";
import logo from "../../../assets/media/icons/tix_logo.svg";
import DropDownField from "../../../components/formComponent/DropDownField";
import Textfield from "../../../components/formComponent/Textfield";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  colorVirtualCard,
  getOptionForDropdown,
} from "../../../helpers/AssetHelpers";
import { COLOR_NAME_ID } from "../core/const";

function formatCardNumber(data: any, showMasked: boolean): string {
  if (!data || !data?.fullCardNumber) return "";

  if (showMasked) {
    return data?.fullCardNumber.replace(/(\d{4})(?=\d)/g, "$1 ");
  }
  return `**** **** **** ${data?.cardNumber}`;
}

const SettingPopup = ({
  data,
  setData,
  loader,
  submitLoader,
  handleOnConfirm,
}: any) => {
  // const [cardNameEdit, setCardNameEdit] = useState<any>(true);
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedcvv, setIsCopiedcvv] = useState(false);
  const [show, setShow] = useState<any>({ isCardNumber: false, isCvv: false });
  const handleOnCardData = (e: any) => {
    const updateData = {
      ...data,
      isUpdated: true,
      ...(e?.target && { [e.target.name]: e.target.value }),
    };
    setData(updateData);
  };

  const card_status_option = [
    { id: "Active", name: "Active", label: "Active" },
    { id: "Inactive", name: "Inactive", label: "Inactive" },
  ];

  const color_code = [
    {
      id: "dark-purple",
      name: "Purple",
      label: "Purple",
      value: "Purple",
      icon: circle,
    },
    {
      id: "dark-green",
      name: "Green",
      label: "Green",
      value: "Green",
      icon: circle,
    },
    {
      id: "dark-blue",
      name: "Blue",
      label: "Blue",
      value: "Blue",
      icon: circle,
    },
    {
      id: "dark-parrot-green",
      name: "Teal",
      label: "Teal",
      value: "Teal",
      icon: circle,
    },
    {
      id: "dark-purple-blue",
      name: "Violet",
      label: "Violet",
      value: "Violet",
      icon: circle,
    },
  ];

  const handleCopyCardNumber = () => {
    if (data?.fullCardNumber) {
      navigator.clipboard.writeText(data.fullCardNumber);
      setIsCopied(true);
    }
  };

  const handleCopyCVV = () => {
    if (data?.cvv) {
      navigator.clipboard.writeText(data.cvv);
      setIsCopiedcvv(true);
    }
  };

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => setIsCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);
  useEffect(() => {
    if (isCopiedcvv) {
      const timer = setTimeout(() => setIsCopiedcvv(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [isCopiedcvv]);

  const closePopupHander = () => {
    setData({});
    setShow({});
  };

  useEffect(() => {
    setShow({});
  }, [data?.time]);

  return (
    <div
      className={` offcanvas upload-ticket-popup  offcanvas-end  fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium md:max-w-[45.25rem] max-w-full w-full `}
      tabIndex={-1}
      id="offcanvasView"
      aria-labelledby="offcanvasViewLabel"
      // data-bs-backdrop="false"
    >
      <div className="relative   flex flex-col h-full bg-white ml-auto">
        <div className="offcanvas-header flex justify-between border-t border-b">
          <h5 className="offcanvas-title mb-0 leading-normal font-semibold py-2 px-5 text-sm15">
            {data?.cardName} Virtual Card Settings
          </h5>
          <button
            type="button"
            id="close-setting"
            className="flex items-center justify-center h-10 w-10 border-l box-content group"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => closePopupHander()}
          >
            <IKTSVG
              className="fill-violet-500 group-hover:fill-indigo-500 transition"
              path={Cross}
              svgClassName="w-[.6875rem] h-[.6875rem]"
            />
          </button>
        </div>

        <div
          id="settingPopup"
          className="offcanvas-body max-h-full w-full flex flex-col overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full p-5 h-full"
        >
          <div
            className={`card_info ${
              !loader && "bg-gray-100/50"
            }  p-[1.875rem] pb-5 rounded-lg `}
          >
            <div className="cardInfoWrap max-w-[21.0625rem] m-auto ">
              <div
                className={`${
                  loader && "shimmer-effect"
                } card_detail_top max-w-[21.0625rem] min-h-[13.25rem] bg-vir_card_bg bg-no-repeat bg-left-bottom relative z-0 rounded-xl -m-[.0625rem] shadow-op21 ${
                  data?.status === "Inactive" && "!bg-gray-300"
                }`}
                style={{
                  backgroundColor: colorVirtualCard(
                    typeof data?.color === "object"
                      ? data?.color?.name
                      : data?.color
                  ),
                }}
              >
                <span className="bg-gradient-to-br from-white/0 to-white/0 via-white absolute left-0 top-0 w-full h-full opacity-[7%] -z-[1]"></span>
                <div className="card-detail p-5 absolute top-0 left-0 w-full h-full flex justify-between flex-col">
                  <div className="top-card flex items-start justify-between">
                    <div className="logo card-svg-logo">
                      <IKTSVG
                        path={logo}
                        svgClassName="w-[9.9375rem] h-6 fill-white"
                      />
                    </div>
                    <span className="text-sm11 font-medium text-white max-w-[8.125rem]">
                      {data?.cardName}
                    </span>
                  </div>
                  <div className="bottom-card flex items-center justify-between">
                    <span className="text-sm15 text-white">
                      •••• •••• •••• {data?.cardNumber}
                    </span>
                    <div className="logo">
                      <IKTSVG
                        path={
                          data?.cardBrand === "MasterCard"
                            ? masterCards
                            : visalogo
                        }
                        svgClassName={`w-[3.875rem] h-auto max-h-[2.25rem]`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="details mt-5">
                <div className="detail-row flex items-center justify-between text-sm13 leading-4 mb-[.9375rem]">
                  <span className="font-semibold">Status</span>
                  <span
                    className={`text-gray-600 ${
                      loader && "shimmer-effect rounded w-20"
                    } `}
                  >
                    <span className={` ${loader && "opacity-0"} `}>
                      {typeof data?.status === "object"
                        ? data?.status?.id
                        : data?.status}
                    </span>
                  </span>
                </div>
                <div className="detail-row flex items-center justify-between text-sm13 leading-4 mb-[.9375rem]">
                  <span className="font-semibold">Card number</span>
                  <p
                    className={`text-gray-600 ${
                      loader && "shimmer-effect rounded w-20"
                    } `}
                  >
                    <span
                      className={`flex items-center ${loader && "opacity-0"} `}
                    >
                      <p>{formatCardNumber(data, show.isCardNumber)}</p>
                      <button
                        className=" w-4 h-4 ml-1.5"
                        onClick={() =>
                          setShow({ ...show, isCardNumber: !show.isCardNumber })
                        }
                      >
                        <IKTSVG
                          className={`${
                            show.isCardNumber
                              ? "fill-indigo-500"
                              : "fill-violet-500"
                          } hover:fill-indigo-500 transition`}
                          svgClassName="w-4 h-4 "
                          path={show.isCardNumber ? eyeCrossSVG : eyeSVG}
                        />
                      </button>
                    </span>
                  </p>
                </div>
                {show.isCardNumber && (
                  <button
                    // onClick={() =>
                    //   navigator.clipboard.writeText(data?.fullCardNumber)
                    // }
                    onClick={handleCopyCardNumber}
                    className={`w-full font-medium text-sm13 py-0.5 text-white rounded mb-[.9375rem] transition ${
                      isCopied
                        ? "bg-green-600"
                        : "bg-violet-500 hover:bg-indigo-500"
                    }`}
                  >
                    {isCopied ? "Copied!" : "Copy card number"}
                  </button>
                )}
                <div className="detail-row flex items-center justify-between text-sm13 leading-4 mb-[.9375rem]">
                  <span className="font-semibold">Expiry date</span>
                  <span
                    className={`text-gray-600 ${
                      loader && "shimmer-effect rounded w-20"
                    } `}
                  >
                    <span className={` ${loader && "opacity-0"} `}>
                      {data?.exp_date}
                    </span>
                  </span>
                </div>
                <div className="detail-row flex items-center justify-between text-sm13 leading-4">
                  <span className="font-semibold">CVV</span>
                  <span
                    className={`text-gray-600 ${
                      loader && "shimmer-effect rounded w-20"
                    } `}
                  >
                    <span
                      className={`flex items-center ${loader && "opacity-0"} `}
                    >
                      {show.isCvv ? data?.cvv : "***"}
                      <button
                        className=" w-4 h-4 ml-1.5"
                        onClick={() => setShow({ ...show, isCvv: !show.isCvv })}
                      >
                        <IKTSVG
                          className={`${
                            show.isCvv ? "fill-indigo-500" : "fill-violet-500"
                          } hover:fill-indigo-500 transition`}
                          svgClassName="w-4 h-4 "
                          path={show.isCvv ? eyeCrossSVG : eyeSVG}
                        />
                      </button>
                    </span>
                  </span>
                </div>
                {show.isCvv && (
                  <button
                    onClick={handleCopyCVV}
                    className={`w-full font-medium text-sm13 py-0.5 text-white rounded mt-[.9375rem] transition ${
                      isCopiedcvv
                        ? "bg-green-600"
                        : "bg-violet-500 hover:bg-indigo-500"
                    }`}
                  >
                    {isCopiedcvv ? "Copied!" : "Copy CVV"}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="card_options mt-5">
            <div className="px-[.9375rem] py-[.875rem] border rounded mb-5 last:mb-0 border-gray-200">
              <h4 className="text-sm13 font-semibold leading-4 mb-[.9375rem]">
                Card status
              </h4>
              <div className="formField whiteFormFields smallDropdown flex flex-wrap flex-col">
                <div className={`${loader && "shimmer-effect rounded"}`}>
                  <DropDownField
                    // className="block overflow-ellipsis overflow-hidden w-auto py-1 text-xs font-medium text-gray-900 border-1.5 border-gray-300 rounded focus:ring-indigo-300 focus:border-indigo-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    // className={`!h-10 !font-medium`}
                    name={"status"}
                    placeholder={``}
                    options={card_status_option}
                    value={
                      typeof data?.status === "object"
                        ? data?.status
                        : getOptionForDropdown(data?.status, data?.status, "")
                    }
                    handleOnChange={(e: any) => handleOnCardData(e)}
                    getOptionValue="label"
                    // isClearable={false}
                    // selectInputRef={selectRef2}
                    connexpayDropdown={true}
                  />
                </div>
              </div>
            </div>
            <div className="px-[.9375rem] py-[.875rem] border rounded mb-5 last:mb-0 border-gray-200">
              <h4 className="text-sm13 font-semibold leading-4 mb-[.9375rem]">
                Card personalisation
              </h4>
              <div className="formField whiteFormFields mb-[.9375rem] flex flex-wrap flex-col last:mb-0">
                <label className="text-gray-500 text-sm12 font-medium leading-[.9375rem] mb-[.3125rem]">
                  Card alias*
                </label>
                <div
                  className={`relative ${loader && "shimmer-effect rounded"} `}
                >
                  <Textfield
                    height={`!h-[1.875rem] !text-sm13`}
                    name="cardName"
                    // isDisabled={cardNameEdit}
                    value={data?.cardName}
                    handleOnChange={(e: any) => handleOnCardData(e)}
                  />
                  {/* <button
                    type="button"
                    className="group absolute top-1/2 -translate-y-1/2 right-5"
                    onClick={() => setCardNameEdit(false)}
                  >
                    <IKTSVG
                      path={EditIcon}
                      className="fill-violet-500 group-hover:fill-indigo-500"
                      svgClassName="w-[.8125rem] h-[.8125rem]"
                    />
                  </button> */}
                </div>
              </div>
              <div className="formField whiteFormFields smallDropdown mb-5 flex flex-wrap flex-col last:mb-0">
                <label className="text-gray-500 text-sm12 font-medium leading-[.9375rem] mb-[.3125rem]">
                  Card colour
                </label>
                <div className={`${loader && "shimmer-effect rounded"}`}>
                  <DropDownField
                    placeholder={``}
                    options={color_code}
                    value={
                      typeof data?.color === "object"
                        ? data?.color
                        : getOptionForDropdown(
                            COLOR_NAME_ID[data?.color],
                            data?.color,
                            circle
                          )
                    }
                    name="color"
                    handleOnChange={(e: any) => handleOnCardData(e)}
                    menuPortalTarget={true}
                    menuPosition={"top"}
                    menuPlacement={"top"}
                    isClearable={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer border-t py-3 px-5 false text-right">
          <button
            type="button"
            className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
        bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13  py-[.1875rem] leading-[1rem] px-2.5 mr-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => closePopupHander()}
          >
            Cancel
          </button>

          <button
            type="submit"
            className={`${
              (!data?.cardName || loader || submitLoader || !data?.isUpdated) &&
              "!bg-gray-100 !border-gray-300 pointer-events-none !border-0 !text-gray-400"
            } transition py-[.1875rem] leading-[1rem] px-2.5 text-sm13  border border-violet-500 bg-violet-500 text-white hover:bg-indigo-500 hover:border-indigo-500 rounded`}
            onClick={handleOnConfirm}
          >
            Update
          </button>
        </div>
      </div>
      {submitLoader && <ProgressBardLoader secondCounter={2} />}
    </div>
  );
};

export default SettingPopup;
