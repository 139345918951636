import { LayoutContext } from "components/core/LayoutProvider";
import { size } from "lodash";
// import HeaderCell from "pages/subscribe/personal_details/table/component/HeaderCell";
// import DataCell from "pages/tx_account/Table/component/DataCell";
import { isMobile } from "helpers/const";
import { handleScroll } from "helpers/Functions";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { TableVirtuoso } from "react-virtuoso";
import DataCell from "./component/DataCell";
import HeaderCell from "./component/HeaderCell";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left last-auto with-sticky"
    style={{
      ...style,
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 last-auto"
    style={{
      ...style,
      position: "static",
    }}
  />
);

const DynamicDataTable = ({
  refetch,
  columns,
  data,
  onEndReach,
  paginateData,
  filters,
  setFilters,
  perPage = 20,
  activeId,
  refreshArrow,
  Popup,
  copyFilters,
  handleOnApplyButtonClick = () => {},
}: any) => {
  const globalLayout = useContext(LayoutContext);
  const [height, setHeight] = useState<any | undefined>(undefined);

  const tableRef: any = useRef<any>();
  const stickyTableRef: any = useRef<any>();

  const scroll = (scrollOffset: any) => {
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };

  const [paginateData1, setPaginateData] = useState(paginateData);
  useEffect(() => {
    if (paginateData) {
      setPaginateData(paginateData);
    }
  }, [paginateData]);

  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#sales-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight + 1;

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 500);
  };

  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    renderCall();
  }, [globalLayout?.htmlFontSize]);

  let timeout: any;
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("tableId1");
      const stickyHead = document.getElementById("sticky-head");
      const scrollHandler = () => handleScroll(element, stickyHead);
      if (element) {
        element.removeEventListener("scroll", scrollHandler);
        element.addEventListener("scroll", scrollHandler);
        scrollHandler(); // Initial check
        return () => {
          element.removeEventListener("scroll", scrollHandler);
        };
      }
    }, 200);
  }, [refreshArrow]);
  // useEffect(() => {

  //   const handleScrollTable = () => {
  //     const EventAccordion = document.getElementById(
  //       "EventAccordion"
  //     ) as HTMLElement;

  //     const topfilterTop: any = document.getElementById("topFilters");

  //     const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
  //     // if (topValue.includes("-")) {
  //     // Check if the value contains a negative sign
  //     const currentValue = parseInt(topValue); // Extract the numeric value
  //     const newValue = Math.abs(currentValue); // Make it positive
  //     topfilterTop && (topfilterTop.style.top = `${newValue}px`); // Apply the new positive value back as a string
  //     // }
  //     const windScroll: number = window.scrollY;

  //     const headingOne: any =
  //       document.getElementById("headingOne")?.clientHeight;
  //     const inventoryaccordion: any =
  //       document.getElementById("inventoryaccordion")?.clientHeight;
  //     const salesFilter =
  //       document.getElementById("salesFilter")?.offsetHeight || 0;
  //     // const totleScroll = headingOne + inventoryaccordion + 16;
  //     // const currentTop = parseInt(topfilterTop.style.top || "0");
  //     let totleScroll = 0;
  //     if (window.matchMedia("(max-width: 767px)").matches) {
  //       totleScroll = headingOne + inventoryaccordion + salesFilter + 20;
  //     } else {
  //       totleScroll = headingOne + inventoryaccordion + 16;
  //     }

  //     const stickyHead: any = document.getElementById("sticky-head");
  //     const stickyShadow: any = document.getElementById("sticky-shadow");

  //     if (windScroll > totleScroll) {
  //       EventAccordion?.classList?.add("-mt-10");
  //       stickyHead?.classList.remove("opacity-0");
  //       stickyHead?.classList.remove("h-0");
  //       stickyHead?.classList.add("h-[2.5625rem]");
  //       stickyShadow.classList.add("!block");
  //     } else if (windScroll < totleScroll) {
  //       EventAccordion?.classList?.remove("-mt-10");
  //       stickyHead?.classList.add("opacity-0");
  //       stickyHead?.classList.add("h-0");
  //       stickyHead?.classList.remove("h-[2.5625rem]");
  //       stickyShadow.classList.remove("!block");
  //     }
  //   };
  //   document.addEventListener("scroll", handleScrollTable);
  //   document.addEventListener("resize", handleScrollTable);
  // }, []);

  useEffect(() => {
    const element = document.getElementById("tableId1");
    const stickyHead = document.getElementById("sticky-head");
    const scrollHandler = () => handleScroll(element, stickyHead);
    if (element) {
      element.removeEventListener("scroll", scrollHandler);
      element.addEventListener("scroll", scrollHandler);
      scrollHandler(); // Initial check
      return () => {
        element.removeEventListener("scroll", scrollHandler);
      };
    }
  }, []);

  useEffect(() => {
    const handleScrollTable = () => {
      const EventAccordion = document.getElementById(
        "EventAccordion"
      ) as HTMLElement;

      const topfilterTop: any = document.getElementById("topFilters");

      const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
      // if (topValue.includes("-")) {
      // Check if the value contains a negative sign
      const currentValue = parseInt(topValue); // Extract the numeric value
      const newValue = Math.abs(currentValue); // Make it positive

      if (topfilterTop?.style) {
        topfilterTop.style.top = `${newValue}px`; // Apply the new positive value back as a string
      }
      // }
      const windScroll: number = window.scrollY;

      const headingOne: any =
        document.getElementById("headingOne")?.clientHeight;
      const inventoryaccordion: any =
        document.getElementById("inventoryaccordion")?.clientHeight;

      const salesFilter =
        document.getElementById("salesFilter")?.offsetHeight || 0;
      // const totleScroll = headingOne + inventoryaccordion + 16;
      // const currentTop = parseInt(topfilterTop.style.top || "0");
      let totleScroll = 0;
      if (window.matchMedia("(max-width: 767px)").matches) {
        totleScroll = headingOne + inventoryaccordion + salesFilter + 20;
      } else {
        totleScroll = headingOne + inventoryaccordion + 16;
      }

      const stickyHead: any = document.getElementById("sticky-head");
      //code to adjust sticky header based on filter chip Area scrollbar
      const filterChipArea = document.querySelector(
        ".filterChipArea"
      ) as HTMLElement;
      let filterChipAreaWidth = 0;
      let width = 0;

      function getBodyScrollbarWidth() {
        const filterChipArea = document.querySelector(
          ".filterChipArea"
        ) as HTMLElement;
        // Creating a div element
        const scrollDiv = document.createElement("div");
        scrollDiv.style.width = "100px";
        scrollDiv.style.height = "100px";
        scrollDiv.style.overflow = "scroll";
        scrollDiv.style.position = "absolute";
        scrollDiv.style.top = "-9999px"; // Hide the div off-screen

        // Append the div to the document body
        filterChipArea?.appendChild(scrollDiv);
        // Calculate the body scrollbar width
        const scrollbarWidth =
          filterChipArea?.offsetHeight - filterChipArea?.clientHeight;
        // Remove the div from the document
        filterChipArea?.removeChild(scrollDiv);
        return scrollbarWidth;
      }
      const bodyScrollbarWidth = getBodyScrollbarWidth();

      if (
        filterChipArea &&
        filterChipArea.children.length > 0 &&
        bodyScrollbarWidth !== 0
      ) {
        width = filterChipArea.scrollWidth;
        filterChipAreaWidth = filterChipArea.offsetWidth;
      }
      if (width > filterChipAreaWidth && width != 0) {
        // stickyHead?.classList.remove("top-[14.625rem]");
        stickyHead?.classList.add("sticky-header-scroll");
        // stickyHead?.classList.remove("xl:top-[12.375rem]");
        // stickyHead?.classList.remove("xl:top-[12.625rem]");
      }

      const filters_block =
        document.getElementById("filters_block")?.offsetHeight || 0;
      // const salesFilter =
      //   document.getElementById("salesFilter")?.offsetHeight || 0;
      const HeaderHeight: any = document.querySelector("header")?.clientHeight;
      const topPosition = filters_block + salesFilter + HeaderHeight;
      const stickyShadow: any = document.getElementById("sticky-shadow");
      if (windScroll > totleScroll) {
        EventAccordion?.classList?.add("-mt-10");
        stickyHead?.classList.remove("opacity-0");
        stickyHead?.classList.remove("h-0");
        stickyHead?.classList.add("h-[2.5625rem]");
        stickyShadow.classList.add("!block");
      } else if (windScroll < totleScroll) {
        EventAccordion?.classList?.remove("-mt-10");
        stickyHead?.classList.add("opacity-0");
        stickyHead?.classList.add("h-0");
        stickyHead?.classList.remove("h-[2.5625rem]");
        stickyShadow.classList.remove("!block");
      }
    };
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);
  }, []);

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }

    if (!paginateData?.current_page && !paginateData?.last_page) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
  }, [paginateData]);

  /*On scroll virtuoso list scroll stickyhead*/
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };
  /*On scroll sticky head list scroll virtuoso*/
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };

  return (
    <div>
      {" "}
      <div
        className={`accordion font-medium transition-all relative md:px-5 max-lg:!mr-0
    
    `}
        id={`EventAccordion`}
      >
        <div
          id="sticky-head"
          className={`sticky sales-report-sticky-head z-10 opacity-0 h-0 overflow-auto scrollbar-none bg-white`}
          ref={stickyTableRef}
          onScroll={handleScrollHead}
        >
          <table className="text-xs text-left absolute left-0 top-0 w-full ">
            <thead className="text-xs text-gray-400 last-auto with-sticky">
              <tr className="border-b  bg-white ">
                {size(columns) > 0 &&
                  columns?.map((item: any) => {
                    return (
                      <HeaderCell
                        title={
                          item?.getTitle
                            ? item?.getTitle({ scroll: scroll })
                            : item?.title
                        }
                        width={item?.width}
                        arrow={item?.isArrow}
                        postKey={item?.postKey}
                        padding={item?.padding}
                        filters={filters}
                        setFilters={setFilters}
                      />
                    );
                  })}
                {isMobile && (
                  <th className="md:hidden sticky right-0 ml-auto p-0 max-md:min-w-[2.5rem] w-10 "></th>
                )}
              </tr>
            </thead>
          </table>
        </div>
        <span id="sticky-shadow" className="z-10 hidden"></span>
        <div className="accordion-item bg-white  !rounded overflow-hidden">
          <div
            id="EventCollapseBody"
            className="accordion-collapse collapse show"
            aria-labelledby="EventCollapseBody"
            data-te-collapse-show
          >
            <div className="accordion-body relative">
              <div
                className="inventory-table sales-table overflow-auto scrollbar-none scrollbar-thumb-violet-800
                 scrollbar-thumb-rounded-full hover-style2 last-auto
                
                 
                 "
                ref={tableRef}
                id={`tableId1`}
                onScroll={handleScrollVirtuoso}
                style={{ height: height > 41 ? `${height}px` : "auto" }}
              >
                <TableVirtuoso
                  data={data}
                  // endReached={() => {}}
                  endReached={() => {
                    if (onEndReach) {
                      onEndReach();
                      return;
                    } else if (
                      paginateData1?.current_page < paginateData1?.last_page
                    ) {
                      setFilters((current: any) => {
                        let nextPage: number = paginateData1?.current_page + 1;
                        handleOnApplyButtonClick("lazy", {
                          ...copyFilters,
                          page: nextPage,
                          per_page: perPage,
                        });
                        return {
                          ...current,
                          page: nextPage,
                          per_page: perPage,
                        };
                      });
                    }
                  }}
                  useWindowScroll
                  overscan={200}
                  totalCount={data?.length}
                  atBottomThreshold={100}
                  id={"sales-lazyload"}
                  components={{
                    Table: Table,
                    TableHead: TableHead,
                    TableRow: (props) => {
                      return <TableRow {...props} activeId={activeId} />;
                    },
                  }}
                  fixedHeaderContent={() => (
                    // <TableHeaderRow
                    // // stickyTableRef={stickyTableRef}
                    // // tableRef={tableRef}
                    // />
                    // <tr className="border-b  bg-white sticky top-0">
                    // {size(columns) > 0 &&
                    //   columns?.map((item: any) => {
                    //     return (
                    //       <HeaderCell
                    //         title={item?.title}
                    //         width={item?.width}
                    //         arrow={item?.arrow}
                    //         postKey={item?.postKey}
                    //         padding={item?.padding}
                    //       />
                    //     );
                    //   })}
                    // </tr>

                    <tr className="border-b bg-white">
                      {size(columns) > 0 &&
                        columns?.map((item: any) => {
                          return (
                            <HeaderCell
                              title={
                                item?.getTitle
                                  ? item?.getTitle({ scroll: scroll })
                                  : item?.title
                              }
                              width={item?.width}
                              arrow={item?.isArrow}
                              postKey={item?.postKey}
                              padding={item?.padding}
                              filters={filters}
                              setFilters={setFilters}
                            />
                          );
                        })}
                    </tr>
                  )}
                  itemContent={(sIndex: any, list: any) => {
                    return (
                      <React.Fragment key={sIndex}>
                        {size(columns) > 0 &&
                          columns?.map((item: any) => {
                            return (
                              <DataCell
                                value={item?.getValue({ list, sIndex })}
                                classname={item?.classname}
                                padding={item?.padding}
                                width={item?.width}
                                renderDirect={item?.renderDirect}
                                matchedView={true}
                              />
                            );
                          })}
                      </React.Fragment>
                    );
                  }}
                />
                {Popup}
              </div>
            </div>
            <div
              id="custom-loader"
              className={`accordion bg-white font-medium pt-2.5 border-t `}
            >
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "30px" }}
              ></div>
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "30px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicDataTable;
